import cls from 'classnames';
import { FC } from 'react';

import { EntryComponentProps } from '@/types/views/generic';
import { PageTeaserSectionEntry } from '@/types/views/sections';

import PageTeaser from '../../../page-teaser';

interface SectionWithPageTeasersProps {
  pageTeasers: EntryComponentProps<PageTeaserSectionEntry>[];
}

const SectionWithPageTeasers: FC<SectionWithPageTeasersProps> = ({
  pageTeasers,
}) =>
  pageTeasers.length ? (
    <div
      className={cls(
        'mt-10 grid grid-rows-auto',
        'grid-cols-1 md:grid-cols-2',
        'gap-x-16 md:gap-x-20 lg:gap-x-28',
        'gap-y-10 md:gap-y-14 lg:gap-y-20',
      )}
    >
      {pageTeasers.map(({ title, description, links }) => (
        <PageTeaser title={title} description={description} links={links} />
      ))}
    </div>
  ) : null;

export default SectionWithPageTeasers;
