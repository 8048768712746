import { FC } from 'react';

import { EntryComponentProps } from '@/types/views/generic';
import { PageTeaserSectionEntry } from '@/types/views/sections';

import PageTeaser from '../../page-teaser';

const PageTeaserSection: FC<EntryComponentProps<PageTeaserSectionEntry>> = ({
  title,
  description,
  links,
}) => (
  <div className="content-box">
    <PageTeaser title={title} description={description} links={links} />
  </div>
);

export default PageTeaserSection;
