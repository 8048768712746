import cls from 'classnames';

const commonTitleStyle = 'text-pink mb-4 md:mb-6';

const styles = {
  container: 'content-box',
  textCenter: 'text-center',
  titleH1: (hasCustomText = false) =>
    cls(
      `text-h1 ${commonTitleStyle}`,
      'tels:text-h4 tels:text-white',
      'tesn:text-h4 tesn:text-white',
      'teair:text-h4',
      'game:text-gray-1',
      hasCustomText ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
  titleH2: (hasCustomText = false) =>
    cls(
      `text-h2 ${commonTitleStyle} tels:normal-case md:teair:mt-18`,
      'game:text-gray-1',
      hasCustomText ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
  titleSmall: cls(
    'text-body2 text-gray-2 mb-4 md:mb-6',
    'tesn:text-esn-secondary-light',
    'tels:text-els-gold-light',
    'teair:text-air-lila',
    'game:text-gray-1',
  ),
  descriptionH1: (hasCustomText = false) =>
    cls(
      'text-body1 text-gray-3',
      'tesn:text-lead tesn:text-white',
      'tels:text-lead tels:text-white',
      'teair:text-lead',
      'game:text-black-extra',
      hasCustomText ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
  descriptionSmall: (hasCustomText = false) =>
    cls(
      'text-lead',
      'game:text-black-extra',
      hasCustomText ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
};

export default styles;
