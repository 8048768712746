import { FC } from 'react';

import TagChip from '@/components/tag-chip';
import { Tag } from '@/types/views/generic';

import styles from './styles';

interface HeaderTagListProps {
  tags: Tag[];
}

const HeaderTagList: FC<HeaderTagListProps> = ({ tags }) => (
  <div className={styles.creatorsContainer}>
    {tags.map((tag) => (
      <TagChip key={tag.name} tag={tag} className="mt-2 md:mt-4" />
    ))}
  </div>
);

export default HeaderTagList;
