import { FC } from 'react';

interface ContactRadioIconProps {
  className?: string;
  fill?: string;
}

const ContactRadioIcon: FC<ContactRadioIconProps> = ({
  fill = 'currentColor',
  className = '',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1932 5.46538C18.6985 6.41234 19.8623 7.75994 20.4879 9.33347C20.8209 10.1515 21 11.0169 21 11.9L20.9998 11.9498L21 12C21 16.4183 16.9706 20 12 20C10.3732 20 8.84729 19.6164 7.5304 18.9453L3.33941 19.921C3.18901 19.956 3.05736 19.8146 3.103 19.6671L4.20965 16.09C4.21655 16.0678 4.21939 16.0447 4.21837 16.0219C3.44375 14.8403 3 13.4662 3 12C3 7.58172 7.02944 4 12 4C13.8774 4 15.6837 4.50519 17.1932 5.46538Z"
      fill={fill}
    />
  </svg>
);

export default ContactRadioIcon;
