import { FC, useEffect, useRef, useState } from 'react';

import LoadingIcon from '@/components/icons/loading-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

const OneTrustCookieList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const cookieWrapperRef = useRef<HTMLDivElement>(null);
  const variables = useVariables();

  const hasLoadedCookieList = () => !!cookieWrapperRef.current?.innerHTML;

  useEffect(() => {
    if (hasLoadedCookieList()) {
      setIsLoading(false);

      return;
    }

    if (typeof window.OneTrust !== 'undefined') {
      try {
        window.OneTrust?.initializeCookiePolicyHtml();
      } catch (error) {
        if (
          // @ts-ignore Is of Error type
          error?.message?.includes(
            "Cannot read properties of undefined (reading 'querySelector')",
          )
        ) {
          // Doesn't affect rendering of cookie list
          console.warn(error);

          return;
        }

        console.error(error);

        setHasError(true);
      }

      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (hasLoadedCookieList()) {
      setIsLoading(false);

      // It may load later despite the error, so we hide error message
      setHasError(false);
    }
  }, [cookieWrapperRef.current?.innerHTML]);

  return (
    <div className="content-box">
      {isLoading && (
        <div className="w-full flex justify-center">
          <LoadingIcon id="loading-icon" className="w-24 h-24" />
        </div>
      )}

      {hasError && (
        <div className="text-air-coral-light">
          {getVariablePlainText(variables['cookie-list-error']) ||
            'An error occurred while generating cookie list. Please, reload page.'}
        </div>
      )}

      <div ref={cookieWrapperRef} id="ot-sdk-cookie-policy">
        {/* List is automatically appended by OneTrust lib */}
      </div>

      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        {/* The text below is overwritten by lib anyway */}
        {getVariablePlainText(variables['cookie-settings']) ||
          'Cookie Settings'}
      </button>
    </div>
  );
};

export default OneTrustCookieList;
