import { FC } from 'react';

import Image from '@/components/image';
import { ImageAsset } from '@/types/views/generic';
import { PageHeaderEntryTheme } from '@/types/views/sections';

import styles from './styles';

interface HeaderImageProps {
  theme: PageHeaderEntryTheme;
  image: ImageAsset;
  title: string;
}
const HeaderImage: FC<HeaderImageProps> = ({ theme, image, title }) => (
  <>
    {theme === 'category' && (
      <div className={styles.categoryImageContainer}>
        <div className="w-16 h-16">
          <Image src={image.src} alt={title} objectFit="fill" />
        </div>
      </div>
    )}

    {theme === 'profile' && (
      <div className={styles.profileImageContainer}>
        <Image src={image.src} alt={title} className={styles.profileImage} />
      </div>
    )}

    {theme === 'podcast' && (
      <div className={styles.podcastImageContainer}>
        <Image src={image.src} alt={title} className="aspect aspect-1-1" />
      </div>
    )}

    {theme === 'formate' && (
      <div className={styles.formatImageContainer}>
        <Image src={image?.src} alt={title} />
      </div>
    )}

    {theme === 'show' && (
      <div className={styles.showImageContainer}>
        <Image src={image.src} alt={title} />
      </div>
    )}

    {theme === 'event' && (
      <div className="w-36 mb-6 md:mb-4 md:w-40">
        <Image
          src={image.src}
          alt={title}
          className="aspect aspect-1-1 rounded-full overflow-hidden"
        />
      </div>
    )}

    {theme === 'playlist' && (
      <div className={styles.categoryImageContainer}>
        <div className="w-full h-full">
          <Image src={image.src} alt={title} objectFit="fill" />
        </div>
      </div>
    )}
  </>
);

export default HeaderImage;
