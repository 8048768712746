import { FC, useState } from 'react';

import useModal from '@/components/modal/use-modal';
import { notNull } from '@/lib/utils';
import {
  ContentFeedEntryContactItem,
  TeaserGroup,
} from '@/types/views/generic';
import { ContactFormSectionEntry } from '@/types/views/sections';

import Carousel from '../content-feed/components/carousel';
import renderEntryTeaser from '../content-feed/lib/render-entry-teaser';
import ContactForm from './contact-form';
import ContactFormModal from './form-modal';

export interface ContactItemProps extends ContentFeedEntryContactItem {
  isSelected: boolean;
  onContactClick: (email: string) => void;
}

const ContactFormSection: FC<ContactFormSectionEntry> = ({
  id,
  title,
  showTitle,
  contactItems,
  newsletterMobileImage,
  newsletterDesktopImage,
  newsletterSuccessImage,
  newsletterErrorImage,
}) => {
  const [selectedContact, setSelectedContact] = useState('');
  const [selectedOverlineText, setSelectedOverlineText] = useState('');

  function selectAndOpen(email: string, overlineText: string) {
    setSelectedContact(email);
    setSelectedOverlineText(overlineText);
    toggleModal();
  }

  function addOnClickFunction(item: ContentFeedEntryContactItem) {
    const hasSameEmailAndText =
      selectedContact === item.email && selectedOverlineText === item.text;

    return {
      ...item,
      isSelected: hasSameEmailAndText,
      onContactClick: () => selectAndOpen(item.email, item.text),
    };
  }

  const renderedContactItems = contactItems
    .map(addOnClickFunction)
    .map(renderEntryTeaser)
    .filter(notNull);

  const { isModalOpen, toggleModal, setIsModalOpen } = useModal();

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="content-box">
        {showTitle && (
          <h2 className="text-h2 text-pink mb-4 md:mb-5 tesn:text-white teair:text-air-deepBlue">
            {title}
          </h2>
        )}

        <div className="md:hidden overflow-hidden">
          <Carousel
            id={`contact_mobile_${id}`}
            teaserGroup={TeaserGroup.Contact}
            teasers={renderedContactItems}
            isMobile
          />
        </div>
        <div className="hidden md:block">
          <Carousel
            id={`contact_desktop_${id}`}
            teaserGroup={TeaserGroup.Contact}
            teasers={renderedContactItems}
            isMobile={false}
          />
        </div>
      </div>

      {isModalOpen && (
        <ContactFormModal onClose={onCloseModal}>
          <ContactForm
            overlineText={selectedOverlineText}
            recipient={selectedContact}
            contactMobileImage={newsletterMobileImage}
            contactDesktopImage={newsletterDesktopImage}
            contactSuccessImage={newsletterSuccessImage}
            contactErrorImage={newsletterErrorImage}
            onClose={onCloseModal}
          />
        </ContactFormModal>
      )}
    </>
  );
};

export default ContactFormSection;
