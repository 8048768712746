import { ChangeEvent, FC } from 'react';

import styles from './styles';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  switchText?: string;
}

const Switch: FC<SwitchProps> = ({
  checked = false,
  onChange,
  switchText = '',
}) => (
  <div className={styles.wrapper}>
    <div className={styles.checkCross}>
      {switchText && <div className={styles.switchText}>{switchText}</div>}

      <input
        id="checkCross"
        type="checkbox"
        className={styles.checkbox}
        checked={checked}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.checked);
        }}
      />

      <label className={styles.toggleItem} htmlFor="checkCross">
        <div className={styles.check(checked)} />
      </label>
    </div>
  </div>
);

export default Switch;
