const styles = {
  contentWrapper: 'content-box',
  header: 'flex flex-col md:flex-row items-baseline mb-5',
  titleWrapper:
    'text-h2 text-pink mb-4 md:mb-5 tesn:text-white teair:text-air-deepBlue',
  articlesWrapper:
    'grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-5 md:gap-y-8',
};

export default styles;
