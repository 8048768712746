const styles = {
  wrapper: 'content-box',
  header: 'text-h2 text-pink mb-4 md:mb-6',
  channelsWrapper:
    'grid auto-cols-max gap-x-6 gap-y-2 md:grid-rows-2 md:grid-flow-col mb-2',
  channel:
    'inline-flex items-center text-body2 underline font-semibold text-red-medium hover:text-red',
};

export default styles;
