import React, { FC } from 'react';

interface PdfIconProps {
  fill?: string;
  className?: string;
}

export const PdfIcon: FC<PdfIconProps> = ({
  fill = '#121113',
  className = '',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M18 22H6C4.89543 22 4 21.1046 4 20.0001V4.00078C4 2.89626 4.89543 2.00087 6 2.00087H13C13.009 1.99971 13.018 1.99971 13.027 2.00087H13.033C13.0424 2.00383 13.0522 2.00584 13.062 2.00687C13.1502 2.01252 13.2373 2.02967 13.321 2.05787H13.336H13.351H13.363C13.3815 2.07079 13.3988 2.08517 13.415 2.10087C13.5239 2.14929 13.6232 2.21705 13.708 2.30086L19.708 8.3006C19.7918 8.38538 19.8596 8.48464 19.908 8.59358C19.917 8.61558 19.924 8.63658 19.931 8.65958L19.941 8.68758C19.9689 8.77097 19.9854 8.85775 19.99 8.94557C19.9909 8.95553 19.9932 8.96531 19.997 8.97457V8.98057C19.9986 8.98712 19.9996 8.99382 20 9.00057V20.0001C20 20.5305 19.7893 21.0392 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22ZM14.424 14.0003V19.0001H15.364V16.9602H16.824V16.1223H15.364V14.8413H17V14.0003H14.424ZM10.724 14.0003V19.0001H11.93C12.4359 19.025 12.9258 18.819 13.262 18.4402C13.6069 18.0001 13.7797 17.4494 13.748 16.8912V16.0813C13.7712 15.5289 13.5936 14.9869 13.248 14.5553C12.9226 14.185 12.4476 13.9811 11.955 14.0003H10.724ZM7 14.0003V19.0001H7.94V17.2412H8.566C8.98402 17.2643 9.39232 17.1096 9.69 16.8152C9.97408 16.4976 10.1214 16.0808 10.1 15.6553C10.1186 15.2195 9.97135 14.7929 9.688 14.4613C9.40772 14.1505 9.00309 13.9814 8.585 14.0003H7ZM13 4.00078V9.00057H18L13 4.00078ZM11.946 18.1622H11.664V14.8413H12.006C12.2489 14.827 12.4824 14.937 12.626 15.1333C12.7726 15.4366 12.8354 15.7735 12.808 16.1093V16.9782C12.83 17.298 12.7606 17.6173 12.608 17.8992C12.4441 18.0905 12.1965 18.1889 11.946 18.1622ZM8.585 16.4002H7.939V14.8413H8.594C8.75752 14.8431 8.90863 14.9288 8.994 15.0683C9.10222 15.2469 9.15447 15.4537 9.144 15.6623C9.15654 15.8568 9.1049 16.05 8.997 16.2123C8.89754 16.3371 8.74447 16.4069 8.585 16.4002Z"
      fill={fill}
    />
  </svg>
);

export default PdfIcon;
