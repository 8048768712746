const styles = {
  contentContainer:
    'md:content-box relative flex flex-col items-center justify-center',
  profileHeaderContainer: 'content-box container py-16 relative',
  title:
    'relative mb-2 text-h4 text-center max-w-xl teair:text-air-deepBlue tesn:text-white tels:text-white',
  description:
    'relative text-body1 text-gray-3 text-center max-w-xl teair:text-air-deepBlue tesn:text-esn-secondary-superLight',
  iconActionsBar: 'md:content-box relative flex justify-center mt-6',
  shortInfo: 'text-body1 mt-12 tels:text-gray-3',
};

export default styles;
