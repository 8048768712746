import NormalButton from '@/components/button/normal';
import Modal from '@/components/modal';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface ActVotingModalProps {
  act: string;
  closeModal: () => void;
  onVoting: () => void;
}

const ActVotingModal = ({ act, closeModal, onVoting }: ActVotingModalProps) => {
  const variables = useVariables();

  return (
    <Modal showCloseIcon={true} onClose={closeModal}>
      <div className="px-4 pt-20 pb-5 md:p-20 flex flex-col items-center h-full overflow-hidden">
        <h3 className="text-h3 text-center md:mb-4">
          {getVariablePlainText(variables['music-award-modal-title']) ||
            'Deine Stimme für'}
          {` ${act}`}
        </h3>
        <p className="text-center p-6 flex-shrink flex-grow overflow-auto min-h-0">
          {getVariablePlainText(variables['music-award-modal-text']) ||
            'Beachte, dass du nur einem Act deine Stimme geben kannst. Das Vote kann nicht rückgängig gemacht werden.'}
        </p>
        <NormalButton
          className=""
          size="XL"
          text={
            getVariablePlainText(variables['music-award-modal-button-text']) ||
            'Jetzt voten'
          }
          onClick={onVoting}
        />
      </div>
    </Modal>
  );
};

export default ActVotingModal;
