import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import fetchWithCatch from '@/lib/fetch-with-catch';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { FetchedRelatedArticles } from '@/pages/api/related-articles';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { PageThemeCms } from '@/types/cms';
import { RelatedArticleEntry } from '@/types/views/generic';
import { RelatedArticlesEntry } from '@/types/views/sections';

import RelatedArticle from './related-article';

const getArticlesSlugs = (articles: RelatedArticleEntry[]): string[] =>
  articles.map((article) => article.slug);

const useFetchArticles = ({
  toExclude,
  hasMore,
  theme,
}: {
  toExclude: string[];
  hasMore: boolean;
  theme?: PageThemeCms;
}) => {
  const [slugsToExclude, setSlugsToExclude] = useState<string[]>(toExclude);
  const [fetchedArticles, setFetchedArticles] = useState<RelatedArticleEntry[]>(
    [],
  );
  const [hasMoreArticles, setHasMoreArticles] = useState<boolean>(hasMore);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMoreArticles = async () => {
    setIsLoading(true);

    const toExcludeParam = `?slugsToExclude=${slugsToExclude.join(',')}`;
    const themeParam = theme ? `&theme=${theme}` : '';
    const { data, error } = await fetchWithCatch<FetchedRelatedArticles>(() =>
      fetch(`/api/related-articles${toExcludeParam}${themeParam}`),
    );

    setIsLoading(false);

    // TODO. Maybe we can add some notification about the error
    if (!data || error) return;

    const { articles: relatedArticles, hasMore: hasMoreToFetch } = data;

    setSlugsToExclude([
      ...slugsToExclude,
      ...getArticlesSlugs(relatedArticles),
    ]);
    setFetchedArticles([...fetchedArticles, ...relatedArticles]);
    setHasMoreArticles(hasMoreToFetch);
  };

  return { fetchMoreArticles, fetchedArticles, hasMoreArticles, isLoading };
};

const RelatedArticles: FC<RelatedArticlesEntry> = ({
  articles,
  hasMore,
  theme,
}) => {
  const router = useRouter();
  const currentSlug = router.query.slug as string;
  const { fetchMoreArticles, fetchedArticles, hasMoreArticles, isLoading } =
    useFetchArticles({
      toExclude: [currentSlug, ...getArticlesSlugs(articles)],
      hasMore,
      theme,
    });

  const variables = useVariables();
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);

  return (
    <div className="content-box teair:bg-air-deepBlue">
      <h2 className="text-h2 uppercase mb-10 md:mb-20 md:text-center text-pink teair:text-white tesn:text-white">
        {getVariablePlainText(
          variables[theme ? 'more-articles-event-text' : 'more-articles-text'],
        ) || 'Auch Interessant'}
      </h2>

      <div>
        {articles.map((article) => (
          <RelatedArticle key={article.slug} {...article} />
        ))}

        {!!fetchedArticles.length &&
          fetchedArticles.map((article) => (
            <RelatedArticle key={article.slug} {...article} />
          ))}
      </div>

      {hasMoreArticles && (
        <div className="text-center mt-10 md:mt-28">
          <NormalButton
            {...buttonProps}
            size="XL"
            disabled={isLoading}
            onClick={fetchMoreArticles}
            text={getVariablePlainText(
              variables['more-articles-load-more-cta-text'],
            )}
          />
        </div>
      )}
    </div>
  );
};

export default RelatedArticles;
