import * as R from 'ramda';
import useSWRInfinite from 'swr/infinite';
import {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from 'swr/infinite/dist/infinite/types';

import { pluralNameByContentType } from '@/lib/constants';
import {
  ContentFeedEntryCmsEntryType,
  ContentFeedEntryCmsEntryTypeGroup,
} from '@/types/cms';
import {
  ComponentEntry,
  OrderType,
  TaggingToolContentType,
} from '@/types/views/generic';

import fetcher from '../fetcher';

interface ContentProps {
  pageSize: string;
  itemsType?: TaggingToolContentType;
  idsToExclude?: string[];
  allowedEntryTypes?: ContentFeedEntryCmsEntryType[];
  entryTypeGroup?: ContentFeedEntryCmsEntryTypeGroup;
  configuration?: Partial<SWRInfiniteConfiguration>;
  idsToInclude?: string[];
  tagIdsToInclude?: string[];
  includeChannel?: string;
  publishDateOrderType?: OrderType;
}

const useLoadContentInfinitely = ({
  itemsType,
  pageSize,
  idsToExclude,
  allowedEntryTypes,
  entryTypeGroup,
  configuration = {},
  idsToInclude,
  tagIdsToInclude,
  includeChannel,
  publishDateOrderType,
}: ContentProps) => {
  const getKey: SWRInfiniteKeyLoader = (pageIndex, previousPageData) => {
    const queryParams = new URLSearchParams({
      ...(allowedEntryTypes &&
        !R.isEmpty(allowedEntryTypes) && {
          allowedEntryTypes: allowedEntryTypes.join(','),
        }),
      ...(idsToExclude &&
        !R.isEmpty(idsToExclude) && {
          idsToExclude: idsToExclude.join(','),
        }),
      ...(tagIdsToInclude &&
        !R.isEmpty(tagIdsToInclude) && {
          tagIdsToInclude: tagIdsToInclude.join(','),
        }),
      ...(idsToInclude &&
        !R.isEmpty(idsToInclude) && {
          idsToInclude: idsToInclude.join(','),
        }),
      ...(includeChannel && {
        includeChannel,
      }),
      ...(entryTypeGroup && {
        entryTypeGroup: entryTypeGroup.toString(),
      }),
      ...(publishDateOrderType && { publishDateOrderType }),
      page: String(pageIndex + 1),
      perPage: pageSize,
    });

    const url = itemsType
      ? `/api/taggingtool/${
          pluralNameByContentType[itemsType]
        }?${queryParams.toString()}`
      : `/api/content-feed-entries?${queryParams.toString()}`;

    return previousPageData && !previousPageData.length ? null : url;
  };

  const {
    data,
    size,
    setSize,
    isValidating: isLoading,
    error,
  } = useSWRInfinite<ComponentEntry[]>(getKey, fetcher, configuration);

  const loadMore = () => {
    setSize((prevState) => prevState + 1);
  };

  const isEmpty = !data?.[0]?.length;
  const lastTeaserArray = data?.[data.length - 1] || [];
  const isAllDataLoaded =
    typeof data?.[size - 1] !== 'undefined' &&
    (isEmpty || lastTeaserArray.length < Number(pageSize));

  return { isLoading, data, error, size, setSize, loadMore, isAllDataLoaded };
};

export default useLoadContentInfinitely;
