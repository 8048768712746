import React, { FC } from 'react';

import { LotteryFormButton as LotteryFormButtonType } from '@/types/views/generic';

import LotteryFormButton from './index';

type LotteryFormButtonProps = Omit<LotteryFormButtonType, 'componentType'>;

const PageLotteryFormButton: FC<LotteryFormButtonProps> = ({
  ctaText,
  lotteryTitle,
  lotteryPot,
  initialLotteryState,
}) => (
  <LotteryFormButton
    ctaText={ctaText}
    lotteryTitle={lotteryTitle}
    lotteryPot={lotteryPot}
    initialLotteryState={initialLotteryState}
    isOversize
  />
);

export default PageLotteryFormButton;
