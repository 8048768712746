import { WatchComponentEntry } from '@/components/sections/watch-overview/infinite-scroll-teasers-grid';
import { QuizSectionEntry, Section } from '@/types/views/sections';

export type AdComponentProps = {
  componentType: string;
  id: string;
  slug: string;
  customPadding?: boolean;
  position?: number;
  targetingPos?: number;
};

const wideBoardAd1 = {
  id: 'wideboard-ad-1',
  slug: 'wideboard-ad-1',
  componentType: 'wideBoardAd',
  position: 1,
  customPadding: true,
};

const wideBoardAd2 = (targetingPos: number) => ({
  id: 'wideboard-ad-2',
  componentType: 'wideBoardAd',
  position: 2,
  customPadding: true,
  targetingPos,
});

const wideBoardAd3 = {
  id: 'wideboard-ad-3',
  componentType: 'wideBoardAd',
  position: 3,
  customPadding: true,
};

const mobileHalfPageAd = (
  slot: 'MHPA_2' | 'MHPA_3',
  targetingPos?: number,
) => ({
  id: 'mobile-half-page-ad',
  componentType: 'mobileHalfPageAd',
  customPadding: true,
  ...(targetingPos && { targetingPos }),
  slot,
});

const mobileMediumRectangleAd1 = (isWatchExperienceAd = false) => ({
  id: 'mobile-medium-rectangle-ad-1',
  slug: 'mobile-medium-rectangle-ad-1',
  componentType: 'mobileMediumRectangleAd',
  position: 1,
  customPadding: true,
  isWatchExperienceAd,
});

// Watch Experience Ad
const mediumRectangleAd1 = {
  id: 'medium-rectangle-ad-1',
  slug: 'medium-rectangle-ad-1',
  componentType: 'mediumRectangleAd',
  position: 1,
  customPadding: true,
  isWatchExperienceAd: true,
};

// Watch Experience Ad
const mediumRectangleAd2 = (targetingPos?: number) => ({
  id: `medium-rectangle-ad-2-${targetingPos}`,
  slug: 'medium-rectangle-ad-2',
  componentType: 'mediumRectangleAd',
  position: 2,
  customPadding: true,
  targetingPos,
  isWatchExperienceAd: true,
});

// Watch Experience Ad
const mobileMediumRectangleAd2 = (
  targetingPos: number,
  isWatchExperienceAd = false,
) => ({
  id: `mobile-medium-rectangle-ad-2-${targetingPos}`,
  slug: 'mobile-medium-rectangle-ad-2',
  componentType: 'mobileMediumRectangleAd',
  position: 2,
  customPadding: true,
  targetingPos,
  isWatchExperienceAd,
});

const adsTypeMap = (targetingPos: number) => ({
  1: [wideBoardAd1, mobileMediumRectangleAd1()],
  2: [wideBoardAd2(targetingPos), mobileHalfPageAd('MHPA_2', targetingPos)],
  3: [wideBoardAd3, mobileHalfPageAd('MHPA_3')],
});

const hardcodedAdsPositioningMap = [
  '',
  'C1',
  'C1C',
  'C1CC2',
  'C1CCC2',
  'C1CCC2C',
  'C1CCC2CC3',
  'C1CCC2CCC3',
  'C1CCC2CCCC3',
  'C1CCC2CCCC3C',
  'C1CCC2CCCC2CC3', // 10 x C
  'C1CCC2CCCC2CCC3',
  'C1CCC2CCCC2CCC3C',
  'C1CCC2CCCC2CCC2CC3',
  'C1CCC2CCCC2CCC2CCC3',
  'C1CCC2CCCC2CCC2CCCC3',
  'C1CCC2CCCC2CCC2CCCC3C',
  'C1CCC2CCCC2CCC2CCCC2CC3',
  'C1CCC2CCCC2CCC2CCCC2CCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC3C',
  'C1CCC2CCCC2CCC2CCCC2CCC2CC3', // 20 x C
  'C1CCC2CCCC2CCC2CCCC2CCC2CCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC3C',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC3C',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CCCC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CCCC3C', // 30 x C
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CCCC2CC3',
  'C1CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC2CCCC2CCC3',
];

const hardcodedQuizAdsPositioningMap = [
  '',
  'C1',
  'C1C',
  'CCC2',
  'C1CC2C',
  'C1C2CC3C',
  'C1C2CC2C3C',
];

const hardcodedQuizAdsWithoutAdditionalWideBoardsPositioningMap = [
  '',
  'C1',
  'C1C',
  'C1CC',
  'C1CCC',
  'C1CCCC',
  'C1CCCCC',
];

// TODO (_): Will be refactored into real algo and typed properly
const enrichPageWithAdsHardcodedApproach = (
  mappedPage: Section[],
  adsPositioningMap = hardcodedAdsPositioningMap,
) => {
  // Render with no ads if no pattern in adsPositioningMap available
  if (adsPositioningMap[mappedPage.length] === undefined) return mappedPage;

  const sectionsCount = mappedPage.length;
  const enrichedComponentWithAds = [];

  for (
    let i = 0, sectionsIndex = 0, adsIndex = 0;
    i < adsPositioningMap[sectionsCount].length;
    i++
  ) {
    if (adsPositioningMap[sectionsCount].charAt(i) === 'C') {
      enrichedComponentWithAds.push(mappedPage[sectionsIndex]);
      sectionsIndex++;
    } else {
      enrichedComponentWithAds.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...adsTypeMap(adsIndex)[
          parseInt(adsPositioningMap[sectionsCount].charAt(i), 10)
        ],
      );
      adsIndex++;
    }
  }

  return enrichedComponentWithAds;
};

const watchExperienceAdsType = (
  targetingPos: number,
): Record<number, AdComponentProps[]> => ({
  1: [mediumRectangleAd1, mobileMediumRectangleAd1(true)],
  2: [
    mediumRectangleAd2(targetingPos),
    mobileMediumRectangleAd2(targetingPos, true),
  ],
});

export const enrichMappedWatchExperienceWithAds = (
  entries: WatchComponentEntry[],
  adsPositioningMap: string,
) => {
  if (adsPositioningMap.length === 0) return entries;

  const enrichedComponentWithAds = [];

  for (
    let i = 0, sectionsIndex = 0, positionCounter = 0;
    i < adsPositioningMap.length;
    i++
  ) {
    if (adsPositioningMap.charAt(i) === 'C') {
      enrichedComponentWithAds.push(entries[sectionsIndex]);
      sectionsIndex++;
    } else {
      enrichedComponentWithAds.push(
        ...watchExperienceAdsType(positionCounter)[
          parseInt(adsPositioningMap.charAt(i), 10)
        ],
      );
      positionCounter++;
    }
  }

  return enrichedComponentWithAds;
};

const enrichMappedPageWithAds = (mappedPage: Section[]) =>
  enrichPageWithAdsHardcodedApproach(mappedPage);

export const enrichQuizPageWithAds = (mappedPage: Section[]) => {
  const quizSection = mappedPage.find(
    (section) => section.componentType === 'quiz',
  ) as QuizSectionEntry;

  const areAdditionalWideboardAdsHidden =
    quizSection?.hideAdditionalWideboardAds;

  return enrichPageWithAdsHardcodedApproach(
    mappedPage,
    areAdditionalWideboardAdsHidden
      ? hardcodedQuizAdsWithoutAdditionalWideBoardsPositioningMap
      : hardcodedQuizAdsPositioningMap,
  );
};

export default enrichMappedPageWithAds;
