import { css } from '@emotion/css';
import { screens } from 'tailwind/theme';

const sectionBg = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    z-index: -1;
    background-color: inherit;

    @media (min-width: ${screens['2xl']}) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default sectionBg;
