import cls from 'classnames';

const commonImageContainer = 'overflow-hidden rounded-2 mx-auto mb-5';

const styles = {
  profileImageContainer: 'w-38 md:w-40 mb-4',
  categoryImageContainer:
    'mb-4 rounded-full bg-black-light flex items-center justify-center shadow-lg w-40 h-40 overflow-hidden',
  profileImage:
    'block object-cover object-center drop-shadow-lg rounded-full overflow-hidden aspect aspect-1-1',
  podcastImageContainer: cls('w-40 h-40', commonImageContainer),
  formatImageContainer: cls('w-64 h-36', commonImageContainer),
  showImageContainer: cls('w-64 h-36', commonImageContainer),
};

export default styles;
