import cls from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';

import Image from '@/components/image';
import { isImageAsset } from '@/lib/utils';
import { MediaAsset } from '@/types/views/generic';

import SocialButtons from '../../social-buttons';

interface MainSwiperSlideProps {
  isActive: boolean;
  urlToShare: string;
  mediaItem: MediaAsset;
}

const renderActiveItemGradient = () => (
  <div className="absolute z-10 inset-x-0 -bottom-2 top-1/2 bg-gradient-to-t from-black teair:from-air-deepBlue" />
);

const MainSwiperSlide: FC<MainSwiperSlideProps> = ({
  mediaItem,
  urlToShare,
  isActive,
}) => {
  const { asPath } = useRouter();
  const isArticlePage = asPath.includes('/artikel');

  const renderCaption = () => (
    <div className="flex justify-between items-end px-8 pb-10">
      <div className="w-4/5">
        <span className="text-body1 teair:text-white">
          {mediaItem.description}
        </span>
      </div>

      {!isArticlePage && (
        <div className="transform translate-y-1/2">
          <SocialButtons urlToShare={urlToShare} placement="topLeft" />
        </div>
      )}
    </div>
  );

  return (
    <div className="relative">
      {isImageAsset(mediaItem) && (
        <Image
          src={mediaItem.src}
          alt={mediaItem.description}
          objectFit="cover"
          className="aspect aspect-16-9 rounded-2 overflow-hidden"
        />
      )}

      <div
        className={cls(
          'absolute inset-0 hidden md:block transition-all duration-300',
          isActive ? 'opacity-100' : 'opacity-0',
        )}
      >
        <div className="absolute z-20 inset-x-0 bottom-0">
          {renderCaption()}
        </div>

        {renderActiveItemGradient()}
      </div>
    </div>
  );
};

export default MainSwiperSlide;
