import cls from 'classnames';
import { FC, useEffect, useState } from 'react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { Filter } from '@/types/views/generic';

import { ArrowIcon } from '../icons/arrow-icon';
import FilterChip from './filter-chip';
import styles from './styles';

export type IsFilterSelectedById = Record<string, boolean>;

interface FilterChipsProps {
  filters: Filter[];
  isAllButtonActive: boolean;
  isFilterSelectedById: IsFilterSelectedById;
  onFilterToggle: (filter: Filter) => void;
  onFiltersReset: () => void;
  className?: string;
}

SwiperCore.use([Navigation, A11y]);

const ALL_TEXT = 'Alle';

const CAROUSEL_NAV_PREV_BUTTON_ID =
  'carousel-navigation-prev-watch-overview-filters';
const CAROUSEL_NAV_NEXT_BUTTON_ID =
  'carousel-navigation-next-watch-overview-filters';

const FilterChips: FC<FilterChipsProps> = ({
  filters,
  isAllButtonActive,
  isFilterSelectedById,
  onFilterToggle,
  onFiltersReset,
  className = '',
}) => {
  const variables = useVariables();

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [reachedSwiperBeginning, setReachedSwiperBeginning] = useState(true);
  const [reachedSwiperEnd, setReachedSwiperEnd] = useState(false);

  const handleSlideChange = (swiper: SwiperCore) => {
    setReachedSwiperEnd(swiper.isEnd);
    setReachedSwiperBeginning(swiper.isBeginning);
  };

  const handleFilterClick = (id?: string) => {
    const clickedFilter = filters.find((f) => f.id === id);

    if (clickedFilter) {
      onFilterToggle(clickedFilter);
    }
  };

  const reachedBeginningAndEnd = reachedSwiperBeginning && reachedSwiperEnd;

  useEffect(() => {
    const handleResize = () => {
      if (swiperInstance) {
        handleSlideChange(swiperInstance);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [swiperInstance]);

  return (
    <div className={cls('overflow-hidden w-full flex', className)}>
      <FilterChip isActive={isAllButtonActive} onClick={onFiltersReset}>
        {getVariablePlainText(variables['filter-chip-all-text']) || ALL_TEXT}
      </FilterChip>

      <div className={styles.divider} />

      <div className={styles.carouselWrapper}>
        {!reachedSwiperBeginning && (
          <div className={styles.carouselLeftShadow} />
        )}

        {!reachedSwiperEnd && <div className={styles.carouselRightShadow} />}

        <Swiper
          wrapperTag="ul"
          slidesPerView="auto"
          spaceBetween={8}
          navigation={{
            prevEl: `#${CAROUSEL_NAV_PREV_BUTTON_ID}`,
            nextEl: `#${CAROUSEL_NAV_NEXT_BUTTON_ID}`,
          }}
          noSwiping={reachedBeginningAndEnd}
          observeParents
          observer
          onInit={setSwiperInstance}
          onReachBeginning={handleSlideChange}
          onReachEnd={handleSlideChange}
          onSlideChange={handleSlideChange}
          className="swiper-no-swiping"
          // Workaround for android swiping issue
          focusableElements=".does-not-exist"
        >
          {filters.map((filter, index) => (
            <SwiperSlide
              tag="li"
              key={index}
              className={cls('swiper-no-swiping', styles.swiperSlide)}
            >
              <FilterChip
                id={filter.id}
                isSelected={isFilterSelectedById[filter.id]}
                onClick={handleFilterClick}
              >
                {filter.text}
              </FilterChip>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {!reachedBeginningAndEnd && (
        <>
          <FilterChip
            isDisabled={reachedSwiperBeginning}
            className={styles.previousFilterBtn}
            id={CAROUSEL_NAV_PREV_BUTTON_ID}
          >
            <ArrowIcon className="transform rotate-180" />
          </FilterChip>

          <FilterChip
            isDisabled={reachedSwiperEnd}
            id={CAROUSEL_NAV_NEXT_BUTTON_ID}
            className={styles.nextFilterBtn}
          >
            <ArrowIcon />
          </FilterChip>
        </>
      )}
    </div>
  );
};

export default FilterChips;
