import cls from 'classnames';
import * as R from 'ramda';
import React, { FC, useState } from 'react';

import FilterChips from '@/components/filter-chips';
import { useIsomorphicLayoutEffect } from '@/lib/utils';
import { TeamMember } from '@/types/views/generic';
import { TeamSectionEntry } from '@/types/views/sections';

import useFilters from '../../filter-chips/use-filters';
import Member from './member';

const Team: FC<TeamSectionEntry> = ({ members, departments }) => {
  const {
    isFilterSelectedById,
    isAllButtonActive,
    selectedFilterIds,
    resetSelectedFilters,
    toggleFilterSelection,
  } = useFilters();

  const [membersShuffled, setMembersShuffled] = useState<TeamMember[] | null>(
    null,
  );

  useIsomorphicLayoutEffect(() => {
    const membersCopy = R.clone(members);
    membersCopy.sort(() => Math.random() - 0.5);
    setMembersShuffled(membersCopy);
  }, []);

  const noFilterSelected = !selectedFilterIds.length;

  return (
    <div className="content-box">
      <div
        className={cls(
          'pb-4 mb-6 border-b-2 border-gray-1',
          'xl:pb-12 xl:mb-12',
        )}
      >
        <FilterChips
          isFilterSelectedById={isFilterSelectedById}
          isAllButtonActive={isAllButtonActive}
          onFiltersReset={resetSelectedFilters}
          onFilterToggle={toggleFilterSelection}
          filters={departments}
        />
      </div>

      <div
        className={cls(
          'grid grid-cols-2 gap-x-4 gap-y-8',
          'sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12',
          'md:grid-cols-4 md:gap-x-6 md:gap-y-10',
          'xl:grid-cols-5 xl:gap-x-6 xl:gap-y-10',
          '3xl:grid-cols-6 3xl:gap-x-8 3xl:gap-y-12',
        )}
      >
        {membersShuffled?.map((member) => {
          const departmentIncludedByFilter = selectedFilterIds.includes(
            member.department,
          );

          return noFilterSelected || departmentIncludedByFilter ? (
            <Member key={member.name} {...member} />
          ) : null;
        })}
      </div>
    </div>
  );
};

export default Team;
