import { FC, ReactElement, ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
}

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}): ReactElement => <>{condition ? wrapper(children) : children}</>;

export default ConditionalWrapper;
