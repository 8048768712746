import cls from 'classnames';

import CustomLink from '@/components/custom-link';
import AttentionIcon from '@/components/icons/attention-icon';
import LockIcon from '@/components/icons/lock-icon';
import Image from '@/components/image';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { NavItemEntryWithIcon } from '@/types/views/generic';

import DesktopBaseTile from '../desktop-base-tile';

interface DesktopTileProps {
  navItem: NavItemEntryWithIcon;
  incompleteProfileData: string[];
  incompleteProfileText: string;
  showIcon: boolean;
}

const DesktopTile = ({
  navItem,
  incompleteProfileData,
  incompleteProfileText,
  showIcon,
}: DesktopTileProps) => {
  const variables = useVariables();

  const hasIncompleteProfile = !!incompleteProfileData.length;
  const isTicketTile = navItem.link.href === '/account/tickets';

  if (isTicketTile && hasIncompleteProfile) {
    return (
      <DesktopBaseTile
        className="bg-gradient-to-br from-gray-2  to-gray-1"
        navItem={navItem}
      >
        <div className="pt-[18px] lg:pt-5 p-5 flex flex-col h-full w-full">
          <div className="flex items-center justify-center text-eds-gray-light mb-4">
            <LockIcon className="w-8 h-8 lg:w-10 lg:h-10" />
          </div>

          <p className="text-[12px] font-inter lg:text-overline">
            {incompleteProfileText}
          </p>

          <ul className="mt-2 text-[12px] font-inter lg:text-overline">
            {incompleteProfileData.map((field) => (
              <li key={field} className="list-disc ml-4">
                {getVariablePlainText(variables[`account-${field}`]) || field}
              </li>
            ))}
          </ul>
        </div>
      </DesktopBaseTile>
    );
  }

  return (
    <CustomLink {...navItem.link} key={navItem.link.id}>
      <DesktopBaseTile
        className={cls(
          'bg-gradient-to-br from-red-medium to-red',
          'before:absolute before:h-full before:w-full before:bg-red before:rounded-4 before:opacity-0 before:transition-opacity before:ease-in-out before:duration-200 before:hover:opacity-100',
        )}
        navItem={navItem}
      >
        {showIcon && (
          <AttentionIcon className="absolute top-5 right-5 w-10 h-10 text-red-medium fill-white" />
        )}
        {navItem?.icon && (
          <Image
            src={navItem.icon.src}
            alt={navItem.link.text}
            useIntrinsicSize
            width={80}
            height={80}
          />
        )}
      </DesktopBaseTile>
    </CustomLink>
  );
};

export default DesktopTile;
