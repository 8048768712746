import cls from 'classnames';
import { FC } from 'react';

import Image from '@/components/image';
import { ImageAsset } from '@/types/views/generic';

import styles from './styles';

interface CardWithSideBlockDesktopProps {
  background: ImageAsset;
  className?: string;
  hoverEnabled?: boolean;
}

const CardWithSideBlockDesktop: FC<CardWithSideBlockDesktopProps> = ({
  background,
  children,
  className = '',
  hoverEnabled = false,
}) => (
  <article className={cls(styles.wrapper, hoverEnabled && 'group', className)}>
    <div className={styles.infoWrapper}>{children}</div>

    <div className={styles.cardImgContainer}>
      <Image
        src={background.src}
        alt={background.description}
        className="aspect aspect-16-9"
      />

      <div
        className={cls(styles.gradient, hoverEnabled && styles.gradientHover)}
      />
    </div>
  </article>
);

export default CardWithSideBlockDesktop;
