import cls from 'classnames';
import { FC } from 'react';

import RichTextRenderer from '@/components/rich-text-renderer';
import { EntryComponentProps } from '@/types/views/generic';
import {
  TitleWithTextEntryCmsPosition,
  TitleWithTextEntryCmsTitleTheme,
  TitleWithTextSectionEntry,
} from '@/types/views/sections';

import styles from './styles';

const TitleWithText: FC<EntryComponentProps<TitleWithTextSectionEntry>> = ({
  title,
  text,
  theme,
  position,
  hasInvertedThemeColor,
  showTitle,
  deactivateAdSlots,
}) => {
  const hasCustomText = hasInvertedThemeColor;
  const isBiggerTheme = theme === TitleWithTextEntryCmsTitleTheme.Bigger;
  const isMediumTheme = theme === TitleWithTextEntryCmsTitleTheme.Medium;
  const isSmallTheme = theme === TitleWithTextEntryCmsTitleTheme.Smaller;
  const isWideTheme = theme === TitleWithTextEntryCmsTitleTheme.WideContent;

  return (
    <div
      className={cls(
        styles.container,
        deactivateAdSlots && 'ml-auto',
        position === TitleWithTextEntryCmsPosition.Center && styles.textCenter,
      )}
    >
      <div
        className={cls(
          position === TitleWithTextEntryCmsPosition.Left &&
            !isWideTheme &&
            'md:w-1/2 lg:mx-0',
          isWideTheme && 'md:w-2/3 lg:mx-0',
        )}
      >
        {showTitle && (
          <>
            {isBiggerTheme && (
              <h1 className={styles.titleH1(hasCustomText)}>{title}</h1>
            )}
            {/* isMediumTheme is not implemented in contentful production environment */}
            {(isMediumTheme || isWideTheme) && (
              <h2 className={styles.titleH2(hasCustomText)}>{title}</h2>
            )}
            {isSmallTheme && <h2 className={styles.titleSmall}>{title}</h2>}
          </>
        )}

        {text && (
          <div
            className={cls(
              isBiggerTheme || isMediumTheme || isWideTheme
                ? styles.descriptionH1(hasCustomText)
                : styles.descriptionSmall(hasCustomText),
            )}
          >
            {typeof text === 'string' ? text : <RichTextRenderer body={text} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleWithText;
