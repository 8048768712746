import cls from 'classnames';
import { FC, ReactNode, useEffect } from 'react';

const SWITCH_STATE_TIME = 7000;
const ANIMATION_DELAY = 0.05;

interface QuizMessageProps {
  message?: string;
  title?: string;
  switchState?: boolean;
  onSwitchState?: () => void;
  children?: ReactNode;
  hasWon?: boolean;
}

const QuizMessage: FC<QuizMessageProps> = ({
  title = '',
  message = '',
  children,
  switchState = false,
  onSwitchState = () => undefined,
  hasWon = false,
}) => {
  const splittedTitle = title?.split(' ') || [];

  useEffect(() => {
    if (!switchState) return () => undefined;

    const timeoutId = setTimeout(() => {
      onSwitchState();
    }, SWITCH_STATE_TIME);

    return () => clearTimeout(timeoutId);
  }, [onSwitchState, switchState]);

  return (
    <div>
      {title && (
        <div
          className={cls(
            'text-[3.5rem] md:text-[6rem] font-inter font-extraBold text-white text-border drop-shadow-shifted',
            'flex flex-row flex-wrap justify-center mt-4',
          )}
        >
          {splittedTitle.map((word, wordIndex) => {
            const previousWordLength =
              splittedTitle[wordIndex - 1]?.length || 0;
            const currentWordIndex = previousWordLength * wordIndex;
            const isLastWord = splittedTitle.length - 1 === wordIndex;

            return (
              <>
                <div key={`${word}-${wordIndex}`} className="flex flex-row">
                  {word.split('').map((letter, letterIndex) => {
                    const currentLetterIndex =
                      currentWordIndex + letterIndex + 1;
                    const animationDelay = (
                      currentLetterIndex * ANIMATION_DELAY
                    ).toFixed(2);

                    return (
                      <span
                        key={`${letter}-${letterIndex}`}
                        className={cls(
                          'opacity-0 inline-block',
                          hasWon
                            ? 'animate-title-reveal'
                            : 'animate-title-reveal-no-hop',
                        )}
                        style={{ animationDelay: `${animationDelay}s` }}
                      >
                        {letter}
                      </span>
                    );
                  })}
                </div>

                {!isLastWord && <>&nbsp;</>}
              </>
            );
          })}
        </div>
      )}

      {message && (
        <h4
          className={cls(
            'text-h4 text-white text-border drop-shadow-small my-8 md:mb-14 md:mt-8 text-center font-semibold',
            'animate-fade-in opacity-0',
          )}
        >
          {message}
        </h4>
      )}

      {children}
    </div>
  );
};

export default QuizMessage;
