const isBrowser = () => typeof window !== 'undefined';

interface Options {
  includeHash: boolean;
}

const getCurrentPageShareUrl = (options?: Options) => {
  if (!isBrowser()) {
    return '';
  }

  const baseUrlWithPathname = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  if (options?.includeHash) {
    return `${baseUrlWithPathname}${window.location.hash}`;
  }

  return baseUrlWithPathname;
};

export default getCurrentPageShareUrl;
