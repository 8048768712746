import { notNull } from '@/lib/utils';
import {
  ContentFeedEntryMeme,
  ContentFeedEntryVideo,
  WatchDetailMemesEntry,
  WatchDetailVideosEntry,
} from '@/types/views/generic';
import {
  WatchDetailSectionEntry,
  WatchOverviewItemsType,
} from '@/types/views/sections';

interface WatchDetailEntry extends WatchDetailSectionEntry {
  entries: WatchDetailMemesEntry[] | WatchDetailVideosEntry[];
}

export const mapToMemeSection = (
  memes: ContentFeedEntryMeme[],
): WatchDetailMemesEntry[] =>
  memes
    .map((meme) => {
      if (!meme.publishDate) {
        return null;
      }

      if (!meme.appearInWatchExperience) {
        return null;
      }

      return {
        meme: {
          componentType: meme.componentType,
          id: meme.id,
          image: meme.image,
          description: meme.description,
          tags: meme?.tags || [],
          publishDate: meme.publishDate,
          contentCategories: meme.contentCategories,
        },
        teaser: meme?.teaser || null,
      };
    })
    .filter(notNull);

export const mapToVideoSection = (
  videos: ContentFeedEntryVideo[],
): WatchDetailVideosEntry[] =>
  videos
    ?.map((video) => {
      if (!video.publishDate) {
        return null;
      }

      if (!video.appearInWatchExperience) {
        return null;
      }

      return {
        video: {
          componentType: video?.componentType || 'video',
          taggingToolId: video.taggingToolId,
          url: video.videoUrl,
          title: video.title,
          description: video.description,
          posterUrl: video.videoPreviewImage,
          tags: video.tags,
          publishDate: video.publishDate,
          contentCategories: video.contentCategories,
          isSponsored: video.isSponsored,
        },
        teaser: video.teaser,
      };
    })
    .filter(notNull) || [];

const mapToWatchDetailSection = async (
  id: string,
  entries: ContentFeedEntryMeme[] | ContentFeedEntryVideo[],
  contentItemsType: WatchOverviewItemsType,
): Promise<WatchDetailEntry> => {
  const isMemeEntry = contentItemsType === 'meme';

  return {
    contentItemsType,
    id,
    componentType: 'watchDetail',
    layout: 'page',
    entries: isMemeEntry
      ? mapToMemeSection(entries as ContentFeedEntryMeme[])
      : mapToVideoSection(entries as ContentFeedEntryVideo[]),
  };
};

export default mapToWatchDetailSection;
