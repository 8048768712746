import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import getLocalStationPlayer from '@/components/station-player/get-local-station-player';
import { Metadata, selectPlayerLibHasLoaded } from '@/store/slices/player';
import { Channel } from '@/types/views/generic';

const useChannelPlayer = (currentChannel: Channel) => {
  const isPlayerLibLoaded = useSelector(selectPlayerLibHasLoaded);
  const [songMetadata, setSongMetadata] = useState<Metadata | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onMetadata = useCallback((metadata: Metadata) => {
    if (!metadata) return;

    setSongMetadata(metadata);
    setIsLoading(false);
  }, []);

  const localPlayer = useMemo(
    () => getLocalStationPlayer(isPlayerLibLoaded, currentChannel, onMetadata),
    [isPlayerLibLoaded, currentChannel, onMetadata],
  );

  useEffect(() => {
    if (localPlayer) {
      localPlayer.audioElement.volume = 0;
      localPlayer.audioElement.muted = true;
      localPlayer.audioElement.autoplay = true;
      localPlayer.play();
    }

    return () => {
      if (localPlayer) {
        localPlayer.stop();
        localPlayer.detachAudioElement();
      }
      setIsLoading(true);
    };
  }, [localPlayer]);

  return { songMetadata, isLoading };
};

export default useChannelPlayer;
