import { FC } from 'react';

import CustomLink from '@/components/custom-link';
import MessageIcon from '@/components/icons/message-icon';
import Image from '@/components/image';
import { TeamMember } from '@/types/views/generic';

type MemberProps = Omit<TeamMember, 'department'>;

const Member: FC<MemberProps> = ({ image, name, job, link }) => (
  <div className="flex flex-col items-center">
    <div className="w-full relative">
      <Image
        className="aspect aspect-1-1 w-full h-full rounded-full overflow-hidden"
        src={image}
        basicLoader
        objectFit="cover"
      />

      {link && (
        <CustomLink
          className="block absolute top-0 right-0 p-3 bg-black-light bg-opacity-90 hover:bg-opacity-100 rounded-full"
          {...link}
        >
          <MessageIcon className="w-6" />
        </CustomLink>
      )}
    </div>

    <h2 className="mt-4 text-body1 text-center">{name}</h2>
    <h3 className="mt-1 text-overline text-red-medium text-center">{job}</h3>
  </div>
);

export default Member;
