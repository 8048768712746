import { css } from '@emotion/css';
import cls from 'classnames';

const checkCrossStyles = css`
  label::before {
    content: none;
  }
`;

const toggleItemStyles = css`
  transform-origin: 20% center;

  &:before {
    display: block;
    transition: all 0.2s ease;
    width: 2.3em;
    height: 2.3em;
    top: 0.25em;
    left: 0.25em;
    border-radius: 2em;
    border: 2px solid #88cf8f;
  }
`;

const uncheckedStyles = css`
  transform: translateY(-50%);

  &:before {
    width: 16px;
    transform: rotate(-45deg) translate(-5px, 11px);
  }

  &:after {
    width: 16px;
    transform: rotate(45deg) translate(11px, 5px);
  }
`;

const checkedStyles = css`
  transform: rotate(360deg) translateY(-50%);

  &::before {
    width: 14px;
    transform: rotate(-45deg) translate(-3px, 14px);
  }

  &::after {
    width: 8px;
    transform: rotate(45deg) translate(13px, 7px);
  }
`;

const checkStyles = (checked: boolean) => css`
  ${checked ? checkedStyles : uncheckedStyles};

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    border-radius: 10px;
    background: #fff;
    transition: 0.3s ease;
  }
`;

const styles = {
  wrapper: 'flex items-center',
  checkCross: cls(checkCrossStyles, 'md:flex'),
  checkbox: 'invisible left-0 top-0 absolute opacity-0',
  toggleItem: cls(
    toggleItemStyles,
    'bg-black-light h-8 w-14 inline-block rounded-full relative cursor-pointer transition-all duration-300',
  ),
  check: (checked: boolean) =>
    cls(
      checkStyles(checked),
      'bg-black h-6 w-6 rounded-full absolute top-1/2 transition-all duration-300 ease',
      checked ? 'left-6' : 'left-1',
    ),
  switchText:
    'text-small text-gray-2 mb-1 md:mb-0 ml-2 md:ml-0 md:mr-2 md:flex md:items-center',
};

export default styles;
