import cls from 'classnames';
import { createElement, FC } from 'react';

import Accordion from '@/components/accordion';
import CustomLink from '@/components/custom-link';
import { FaqEntry } from '@/types/views/sections';

import FaqItem from './components/faq-item';

const classesByTag: Record<string, string> = {
  h1: 'text-h1',
  h2: 'text-h2',
  h3: 'text-h3',
  h4: 'text-h4',
};

const Faq: FC<FaqEntry> = ({
  title,
  slug,
  faqItems,
  link,
  titleTagComponent = 'h2',
  deactivateAdSlots,
}) => (
  <div className={cls('content-box', deactivateAdSlots && 'ml-auto')}>
    <div
      className="flex items-center flex-wrap mt-2 md:mt-0 mb-4 md:mb-6"
      id={slug}
    >
      {createElement(
        titleTagComponent,
        {
          className: cls(
            'teair:text-air-deepBlue mr-4 uppercase',
            classesByTag[titleTagComponent],
          ),
        },
        title,
      )}

      {link && (
        <CustomLink
          className="text-body1 underline font-semibold teair:text-air-coral teair:hover:text-air-coral-light tesn:text-esn-primary tesn:hover:text-esn-primary-light tels:text-els-blue-light"
          {...link}
        >
          {link.text}
        </CustomLink>
      )}
    </div>

    <Accordion
      itemsToRender={faqItems.map((faqItem) => ({
        slug: faqItem.slug,
        renderItem: (isActive, onItemClick) => (
          <FaqItem isActive={isActive} onItemClick={onItemClick} {...faqItem} />
        ),
      }))}
    />
  </div>
);

export default Faq;
