import { FC } from 'react';

import MediaBackground from '@/components/media-background';
import { LotteryHeaderSectionEntry } from '@/types/views/sections';

import LotteryRegistrationBox from './box';

const LotteryHeaderSection: FC<LotteryHeaderSectionEntry> = ({
  id,
  poolId,
  webRaffleStart,
  webRaffleEnd,
  raffleEndUrl,
  raffleEndLink,
  background,
  backgroundMobile,
  textData,
  profileEndpointLink,
}) => (
  <div className="relative h-[85vh] md:h-[70vh] teair:bg-gradient-to-br teair:from-air-gradientPink teair:to-air-gradientBlue">
    <MediaBackground
      background={background}
      backgroundMobile={backgroundMobile}
    />

    <div className="absolute z-10 inset-0 flex justify-center items-center">
      <LotteryRegistrationBox
        id={id}
        poolId={poolId}
        webRaffleStart={webRaffleStart}
        webRaffleEnd={webRaffleEnd}
        raffleEndUrl={raffleEndUrl}
        raffleEndLink={raffleEndLink}
        textData={textData}
        profileEndpointLink={profileEndpointLink}
      />
    </div>
  </div>
);

export default LotteryHeaderSection;
