import cls from 'classnames';
import React, { FC } from 'react';

interface LotteryBoxFooterProps {
  className?: string;
}

const LotteryBoxFooter: FC<LotteryBoxFooterProps> = ({
  children,
  className = '',
}) => (
  <div
    className={cls(
      'grid md:grid-flow-col items-center justify-items-center gap-2 mt-6',
      className,
    )}
  >
    {children}
  </div>
);

export default LotteryBoxFooter;
