const useIntersect = (
  observeCallback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
): IntersectionObserver | null => {
  if (typeof window !== 'undefined') {
    return new IntersectionObserver(observeCallback, options);
  }

  return null;
};

export default useIntersect;
