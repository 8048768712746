import HomeTiles from '@/components/account/home-tiles';
import SectionAds from '@/components/advertisement/components/section-ads';
import ArticleImageHeader from '@/components/article/article-image-header';
import ArticleTextIntro from '@/components/article/article-text-intro';
import RelatedArticles from '@/components/article/related-articles';
import ChannelCarousel from '@/components/channel-carousel';
import Embed from '@/components/embed';
import Grid from '@/components/grid';
import CreatorTags from '@/components/header-tag-list';
import InfoPageContent from '@/components/info/info-page-content/info-page-content';
import InfoPageHeader from '@/components/info/info-page-header/info-page-header';
import LotteryFormButton from '@/components/lottery-form/button/page';
import PageHeader from '@/components/page-header';
import Playlist from '@/components/playlist';
import ArticleTeaserContainer from '@/components/sections/article-teaser-container';
import AudioDetail from '@/components/sections/audio-detail';
import Box from '@/components/sections/box';
import CategoriesAZ from '@/components/sections/categories-a-z';
import ContactFormSection from '@/components/sections/contact-component';
import ContentFeed from '@/components/sections/content-feed';
import DualHeroSection from '@/components/sections/dual-hero';
import Faq from '@/components/sections/faq';
import GalleriesContainerSection from '@/components/sections/galleries-container';
import GallerySection from '@/components/sections/gallery';
import HeroSection from '@/components/sections/hero';
import HeroLivestreamSection from '@/components/sections/hero-livestream';
import Image from '@/components/sections/image';
import Jobs from '@/components/sections/jobs';
import Link from '@/components/sections/link';
import LotteryFormTeaserSection from '@/components/sections/lottery-form-teaser';
import LotteryHeaderSection from '@/components/sections/lottery-header';
import MediaOverview from '@/components/sections/media-overview';
import MusicAwardSection from '@/components/sections/music-award';
import NewsletterComponent from '@/components/sections/newsletter-component';
import PageTeaser from '@/components/sections/page-teaser';
import PodcastEmbed from '@/components/sections/podcast-embed';
import Quiz from '@/components/sections/quiz';
import RichTextBlock from '@/components/sections/rich-text-block';
import SectionSection from '@/components/sections/sections';
import Sessions from '@/components/sections/sessions';
import Slider from '@/components/sections/slider';
import SoundpiecePlaylist from '@/components/sections/soundpiece-playlist';
import SoundpiecesOverview from '@/components/sections/soundpieces-overview';
import StationHeaderSection from '@/components/sections/station-header';
import Team from '@/components/sections/team';
import Teaser from '@/components/sections/teaser';
import TitleWithText from '@/components/sections/title-with-text';
import VideoSection from '@/components/sections/video';
import WatchDetail from '@/components/sections/watch-detail';
import WatchOverview from '@/components/sections/watch-overview';
import WeitereChannels from '@/components/sections/weitere-channels';
import TextWithHeader from '@/components/text-with-header';

import renderComponent from './render-component';

const Sections = {
  titleWithText: TitleWithText,
  link: Link,
  slider: Slider,
  teaser: Teaser,
  image: Image,
  contentFeed: ContentFeed,
  box: Box,
  embed: Embed,
  pageHeader: PageHeader,
  articleImageHeader: ArticleImageHeader,
  articleTextIntro: ArticleTextIntro,
  section: SectionSection,
  richText: RichTextBlock,
  watchOverview: WatchOverview,
  articleTeaserContainer: ArticleTeaserContainer,
  podcastEmbed: PodcastEmbed,
  video: VideoSection,
  stationHeader: StationHeaderSection,
  mediaOverview: MediaOverview,
  galleryContainer: GalleriesContainerSection,
  gallery: GallerySection,
  hero: HeroSection,
  dualHero: DualHeroSection,
  faq: Faq,
  lotteryHeader: LotteryHeaderSection,
  grid: Grid,
  soundpiecePlaylist: SoundpiecePlaylist,
  act: Teaser,
  relatedArticles: RelatedArticles,
  channelCarousel: ChannelCarousel,
  textWithHeader: TextWithHeader,
  wideBoardAd: SectionAds.WideBoardAd,
  mediumRectangleAd: SectionAds.MediumRectangleAd,
  mobileMediumRectangleAd: SectionAds.MobileMediumRectangleAd,
  mobileHalfPageAd: SectionAds.MobileHalfPageAd,
  heroLivestream: HeroLivestreamSection,
  jobs: Jobs,
  weitereChannels: WeitereChannels,
  newsletter: NewsletterComponent,
  team: Team,
  categoriesAZ: CategoriesAZ,
  creatorTags: CreatorTags,
  playlist: Playlist,
  watchDetail: WatchDetail,
  forms: LotteryFormTeaserSection,
  lotteryFormButton: LotteryFormButton,
  soundpiecesOverview: SoundpiecesOverview,
  audioDetail: AudioDetail,
  sessions: Sessions,
  quiz: Quiz,
  homeTiles: HomeTiles,
  pageTeaser: PageTeaser,
  musicAward: MusicAwardSection,
  infoPageContent: InfoPageContent,
  infoPageHeader: InfoPageHeader,
  contactForm: ContactFormSection,
};

const renderSectionEntry = renderComponent(Sections);

export default renderSectionEntry;
