import cls from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';

import Switch from '@/components/switch';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface SoundpiecePlaylistContainerProps {
  title?: ReactNode;
  filters?: JSX.Element | null;
  channelFilters?: JSX.Element | null;
  setAudioPlayerIsAutoPlay: (isAutoPlay: boolean) => void;
  hasSeperator?: boolean;
  deactivateAdSlots?: boolean;
}

const SoundpiecePlaylistContainer: FC<SoundpiecePlaylistContainerProps> = ({
  title,
  children,
  filters,
  channelFilters,
  setAudioPlayerIsAutoPlay,
  deactivateAdSlots,
}) => {
  const variables = useVariables();
  const hasSeperator = !!setAudioPlayerIsAutoPlay || !!filters;

  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);

  const setIsAutoPlayWithLocalStorage = (value: boolean) => {
    localStorage.setItem('soundpiecesPlaylist::isAutoPlay', value.toString());
    setIsAutoPlay(value);
    setAudioPlayerIsAutoPlay(value);
  };

  useEffect(() => {
    if (localStorage) {
      const tempIsAutoPlay =
        localStorage.getItem('soundpiecesPlaylist::isAutoPlay') === 'true';
      setIsAutoPlay(tempIsAutoPlay);
      setAudioPlayerIsAutoPlay(tempIsAutoPlay);
    }
  }, [setAudioPlayerIsAutoPlay]);

  return (
    <div
      className={cls(
        'overflow-hidded content-box',
        deactivateAdSlots && 'ml-auto',
      )}
    >
      {title}
      <div className="flex flex-col xl:flex-row-reverse items-start xl:items-center">
        <div
          className={`w-full xl:w-auto flex justify-between xl:block xl:mt-0 xl:pt-1 mb-8 xl:mb-0 ${
            filters ? 'xl:mt-auto xl:ml-20' : ''
          }`}
        >
          <div className="block xl:hidden">{channelFilters}</div>
          <div className="flex items-end md:items-center xl:pb-1">
            <Switch
              switchText={
                getVariablePlainText(variables['switch-text-autoplay']) ||
                'Autoplay'
              }
              checked={isAutoPlay}
              onChange={setIsAutoPlayWithLocalStorage}
            />
          </div>
        </div>
        <div className="w-full xl:min-w-0">
          <div className="hidden xl:block mb-8">{channelFilters}</div>
          {filters}
        </div>
      </div>

      {hasSeperator && (
        <div className="flex min-h-1px h-2px my-6 xl:my-8 bg-black-light" />
      )}
      {children}
    </div>
  );
};

export default SoundpiecePlaylistContainer;
