const styles = {
  contentWrapper:
    'bg-black-light rounded-2 md:rounded-4 flex overflow-hidden cursor-pointer hover:bg-gray-1 tesn:bg-esn-secondary-dark tesn:hover:bg-esn-secondary teair:bg-air-deepBlue teair:hover:bg-air-mediumBlue',
  imageContainer: 'w-24 h-24 flex-shrink-0',
  contentContainer: 'px-4 md:px-5 flex-1 self-center',
  categories:
    'text-overline text-light-red md:text-gray-3 tesn:text-esn-secondary-light teair:text-air-lila',
  title:
    'text-body1 text-pink line-clamp-2 tesn:text-white teair:text-white font-semibold',
  creators: 'text-overline text-gray-3 md:text-white line-clamp-1',
  brandedContent: 'text-overline text-gray-2 mt-1 md:mt-2 cursor-default',
};

export default styles;
