import { css } from '@emotion/css';
import cls from 'classnames';

// FIXME: Change font to Tailwind after update package
const circleFont = css`
  font-family: 'Syne', sans-serif !important;
  line-height: 0.75 !important;
`;

const styles = {
  gradient: cls(
    'absolute inset-0 top-auto aspect aspect-1-1 bg-gradient-to-t from-black from-1/6 blend-multiply',
    'xl:w-2/3 lg:w-10/12 md:top-0 md:w-3/4 md:h-full md:pb-0 md:blend-normal md:bg-gradient-to-r',
    'teair:from-air-deepBlue teair:blend-normal',
  ),
  gradientHover: 'group-hover:w-full transition-all',
  imageContainer: 'overflow-hidden object-cover md:rounded-4',
  image: 'aspect aspect-2-3 md:aspect-2-1',
  imageMobile: 'md:hidden',
  imageDesktop: 'hidden md:block',
  content: (isEventTheme: boolean) =>
    cls(
      'absolute inset-0 top-auto md:w-3/5 lg:w-3/4 xl:w-1/2 my-8 md:mx-0 md:my-6 2xl:my-12',
      isEventTheme && 'xl:mb-12 2xl:mb-14 3xl:mb-18',
    ),
  overlineText: cls(
    'inline-block text-overline mb-2',
    'tesn:text-esn-secondary-light',
    'teds:text-red-medium',
    'tels:text-els-gold-light',
    'teair:text-air-lila',
  ),
  description: 'mt-2 md:mt-4 text-body2 md:text-body1 teair:text-white',
  buttonContainer: 'mt-4 md:mt-6',
  circleContent: circleFont,
};

export default styles;
