import cls from 'classnames';
import React, { FC } from 'react';

import sectionBg from '@/components/common-styles';
import { InfoPageHeaderEntry } from '@/types/views/sections';

const InfoPageHeader: FC<InfoPageHeaderEntry> = ({ title }) => (
  <div className={cls('bg-black', sectionBg)}>
    <div className="content-box -mb-4 md:-mb-2">
      <h1 className="text-h1 text-pink mb-0">{title}</h1>
    </div>
  </div>
);

export default InfoPageHeader;
