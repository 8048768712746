import { FC } from 'react';

interface LocationIconProps {
  fill?: string;
  className?: string;
}

const LocationIcon: FC<LocationIconProps> = ({
  className = '',
  fill = 'currentColor',
}) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    className={className}
  >
    <path
      d="M6.99997 18C5.73691 16.9229 4.56616 15.742 3.49998 14.4697C1.89999 12.5591 8.83658e-07 9.71371 8.83658e-07 7.00227C-0.00141727 4.1704 1.70424 1.61677 4.32105 0.533C6.93786 -0.55077 9.95002 0.0489427 11.9519 2.05229C13.2684 3.36263 14.0058 5.14504 14 7.00227C14 9.71371 12.0999 12.5591 10.4999 14.4697C9.43377 15.742 8.26303 16.9229 6.99997 18ZM6.99997 2.0033C4.23993 2.0066 2.0033 4.24278 1.99999 7.00227C1.99999 8.16803 2.52699 10.1866 5.03498 13.187C5.65311 13.9249 6.30898 14.6304 6.99997 15.3006C7.69101 14.6312 8.3472 13.9267 8.96596 13.19C11.4729 10.1856 11.9999 8.16703 11.9999 7.00227C11.9966 4.24278 9.76 2.0066 6.99997 2.0033ZM6.99997 10.0016C5.34312 10.0016 3.99998 8.65878 3.99998 7.00227C3.99998 5.34576 5.34312 4.00289 6.99997 4.00289C8.65681 4.00289 9.99995 5.34576 9.99995 7.00227C9.99995 7.79775 9.68388 8.56066 9.12128 9.12315C8.55867 9.68565 7.79561 10.0016 6.99997 10.0016Z"
      fill={fill}
    />
  </svg>
);

export default LocationIcon;
