import { FC } from 'react';

interface LockIconProps {
  fill?: string;
  className?: string;
}

const LockIcon: FC<LockIconProps> = ({
  className = '',
  fill = 'currentColor',
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 1.66797C11.7157 1.66797 5 8.3837 5 16.668V18.3346H35V16.668C35 8.3837 28.2843 1.66797 20 1.66797ZM19.1667 4.16797C13.1836 4.16797 8.33333 9.01822 8.33333 15.0013H31.6667C31.6667 9.01822 26.8164 4.16797 20.8333 4.16797H19.1667Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 15.0013C5.74619 15.0013 5 15.7475 5 16.668V35.0013C5 36.8422 6.49238 38.3346 8.33333 38.3346H31.6667C33.5076 38.3346 35 36.8422 35 35.0013V16.668C35 15.7475 34.2538 15.0013 33.3333 15.0013H6.66667ZM21.6524 27.8969C22.6566 27.3226 23.3333 26.241 23.3333 25.0013C23.3333 23.1604 21.8409 21.668 20 21.668C18.1591 21.668 16.6667 23.1604 16.6667 25.0013C16.6667 26.241 17.3434 27.3226 18.3476 27.8969C18.3382 28.0401 18.3333 28.1863 18.3333 28.3346C18.3333 30.1756 19.0795 31.668 20 31.668C20.9205 31.668 21.6667 30.1756 21.6667 28.3346C21.6667 28.1863 21.6618 28.0401 21.6524 27.8969Z"
      fill={fill}
    />
  </svg>
);

export default LockIcon;
