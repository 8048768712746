import cls from 'classnames';
import { FC } from 'react';

import RichTextRenderer from '@/components/rich-text-renderer';
import { EntryComponentProps } from '@/types/views/generic';
import { PageTeaserSectionEntry } from '@/types/views/sections';

import TextButton from '../button/text';
import CustomLink from '../custom-link';

const PageTeaser: FC<EntryComponentProps<PageTeaserSectionEntry>> = ({
  title,
  description,
  links,
  hasInvertedThemeColor,
}) => (
  <div>
    <h3
      className={cls(
        'text-h2 text-pink tesn:text-white tels:text-white tlegal:text-black',
        hasInvertedThemeColor ? 'teair:text-white' : 'teair:text-air-deepBlue',
      )}
    >
      {title}
    </h3>
    {description && (
      <RichTextRenderer
        body={description}
        className="text-body2 text-red-light mt-2 md:mt-4 tlegal:text-black"
      />
    )}
    {links &&
      links.map((link) => (
        <CustomLink
          key={link.text}
          {...link}
          className={cls(
            'text-body1 text-red-medium underline hover:text-red font-semibold',
          )}
        >
          <div className="mt-4">
            <TextButton
              className="transition-all"
              text={link.text}
              theme="light"
            />
          </div>
        </CustomLink>
      ))}
  </div>
);

export default PageTeaser;
