import React, { FC } from 'react';

import Box from '@/components/box';
import BoxContent from '@/components/box/components/box-content';
import { EntryComponentProps } from '@/types/views/generic';
import { BoxSectionEntry } from '@/types/views/sections';

import styles from './styles';

interface SectionWithBoxesProps {
  elements: EntryComponentProps<BoxSectionEntry>[];
  textClassNames?: string;
}

const SectionWithBoxes: FC<SectionWithBoxesProps> = ({
  elements,
  textClassNames,
}) =>
  elements.length ? (
    <div className={styles.wrapper(elements[0].type)}>
      {elements.map(
        (
          { title, image, description, overlineText, links, type, theme },
          index,
        ) => (
          <Box
            key={`box-${index}`}
            image={image}
            type={type}
            theme={theme}
            className="w-full teair:bg-air-lila-light"
          >
            <BoxContent
              title={title}
              description={description}
              overlineText={overlineText}
              links={links}
              theme={theme}
              type={type}
              textClassNames={textClassNames}
            />
          </Box>
        ),
      )}
    </div>
  ) : null;

export default SectionWithBoxes;
