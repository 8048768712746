import cls from 'classnames';

import { TeaserGroup } from '@/types/views/generic';

/**
 * Should match backend
 * @see @/middleware/mappers/sections/content-feed
 */
const gridClasses: Record<TeaserGroup, string> = {
  [TeaserGroup.Default]: 'grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4',
  [TeaserGroup.Categories]: 'grid-cols-3 md:grid-cols-5 xl:grid-cols-8 gap-4',
  [TeaserGroup.Memes]:
    'grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-4 gap-y-12 grid-flow-row-dense',
  [TeaserGroup.PodcastsCreatorsChannels]:
    'grid-cols-2 gap-3 md:grid-cols-5 md:gap-4 xl:gap-y-12',
  [TeaserGroup.Videos]:
    'grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-x-2 md:gap-x-4 gap-y-12 grid-flow-row-dense',
  [TeaserGroup.Stations]:
    'grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-12',
  [TeaserGroup.Prizes]:
    'grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-x-2 md:gap-x-4 gap-y-12 grid-flow-row-dense',
  [TeaserGroup.Contact]: 'grid-cols-2 gap-3 md:grid-cols-6 md:gap-x-5',
};

const styles = {
  wrapper: (teaserGroup: TeaserGroup) => `grid ${gridClasses[teaserGroup]}`,
  loadingIcon: (isEmpty: boolean) =>
    cls(!isEmpty && 'mt-4', 'w-12 h-12 text-center mx-auto'),
  buttonContainer: 'flex justify-center mt-4 mb-8',
};

export default styles;
