import cls from 'classnames';
import { FC } from 'react';

import TitleLink from '@/components/title-link';
import {
  ComponentEntry,
  EntryComponentProps,
  IconLinkEntry,
} from '@/types/views/generic';
import {
  BoxSectionEntry,
  PageTeaserSectionEntry,
  SectionSectionEntry,
} from '@/types/views/sections';

import SectionWithBoxes from './boxes';
import IconLinks from './icon-links';
import SectionWithPageTeasers from './page-teasers';

const sectionElementsMap: Record<
  ComponentEntry['componentType'],
  (elements: ComponentEntry[], textClassNames?: string) => JSX.Element
> = {
  box: (elements, textClassNames) => (
    <SectionWithBoxes
      elements={elements as BoxSectionEntry[]}
      textClassNames={textClassNames}
    />
  ),
  iconLink: (elements) => <IconLinks iconLinks={elements as IconLinkEntry[]} />,
  pageTeaser: (elements) => (
    <SectionWithPageTeasers
      pageTeasers={elements as PageTeaserSectionEntry[]}
    />
  ),
};

const renderElements = (
  elements: ComponentEntry[],
  textClassNames?: string,
) => {
  if (!elements.length) {
    return null;
  }

  const { componentType } = elements[0];

  return sectionElementsMap[componentType]?.(elements, textClassNames);
};

// TODO: change name after other Sections naming update
const SectionSection: FC<EntryComponentProps<SectionSectionEntry>> = ({
  title,
  showTitle,
  link,
  elements,
  textClassNames,
  hasInvertedThemeColor,
  deactivateAdSlots,
}) => (
  <>
    {elements.length ? (
      <div
        className={cls(
          'content-box teair:pt-14',
          deactivateAdSlots && 'ml-auto',
        )}
      >
        {showTitle && (
          <div className="flex flex-row flex-wrap items-baseline gap-2 mb-4 md:mb-6">
            <h2
              className={cls(
                'text-pink tesn:text-white tels:text-white tlegal:text-black text-h1',
                hasInvertedThemeColor
                  ? 'teair:text-white'
                  : 'teair:text-air-deepBlue',
              )}
            >
              {title}
            </h2>

            {link && <TitleLink link={link} />}
          </div>
        )}

        {renderElements(elements, textClassNames)}
      </div>
    ) : null}
  </>
);

export default SectionSection;
