import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import Soundpiece from '@/components/sections/soundpiece-playlist/components/soundpiece';
import Switch from '@/components/switch';
import TitleLink from '@/components/title-link';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import useLoadAudios from '@/lib/hooks/use-load-audios';
import useVariables from '@/lib/hooks/use-variables';
import {
  selectAudioPlayerIsPlaying,
  selectPlayerCurrentAudio,
} from '@/store/slices/player';
import { ContentFeedEntrySoundpiece } from '@/types/views/generic';
import { AudioDetailEntry } from '@/types/views/sections';

const PAGE_SIZE = '10';

const AudioDetail: FC<AudioDetailEntry> = ({ currentEntry, entries, link }) => {
  const variables = useVariables();
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);
  const [idsToExclude, setIdsToExclude] = useState(() => [
    currentEntry.id,
    ...entries.map(({ id }) => id),
  ]);
  const {
    onPlay,
    onPause,
    changeEvent,
    setIsAutoPlay: setAudioPlayerIsAutoPlay,
  } = useAudioPlayer();

  const { isLoading, data, loadMore, isAllDataLoaded } = useLoadAudios(
    PAGE_SIZE,
    idsToExclude,
  );

  const currentAudio = useSelector(selectPlayerCurrentAudio);
  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);

  const hasReachedEnd = () =>
    isAllDataLoaded ? null : (
      <div className="flex justify-end mb-4">
        <NormalButton
          text={
            getVariablePlainText(variables['audio-detail-load-more']) ||
            'Mehr laden'
          }
          onClick={loadMore}
        />
      </div>
    );

  const mapAudioEntry =
    (indexLength = 0) =>
    (entry: ContentFeedEntrySoundpiece, index: number) => {
      const soundpieceIndex = index + indexLength + 1;

      return (
        <Soundpiece
          key={entry.id}
          {...entry}
          isActive={getIsActive(entry)}
          onPlay={() => onPlay(soundpieceIndex, entry)}
          onPause={onPause}
          audioTimeUpdateEvent={changeEvent}
          duration={entry.duration}
        />
      );
    };

  const handlOnChange = (checked: boolean) => {
    setIsAutoPlay(checked);
    setAudioPlayerIsAutoPlay(checked);
  };

  const getIsActive = (entry: ContentFeedEntrySoundpiece) =>
    !!currentAudio &&
    'id' in currentAudio &&
    currentAudio.id === entry.id &&
    isAudioPlaying;

  useEffect(() => {
    if (data?.length) {
      const initialIdsToExclude = entries.map(({ id }) => id);
      const mappedIdsToExclude = data.map((dataEntry) => dataEntry.id);
      setIdsToExclude([
        currentEntry.id,
        ...initialIdsToExclude,
        ...mappedIdsToExclude,
      ]);
    }
  }, [currentEntry.id, data, entries]);

  return (
    <div className="content-box">
      <div>
        <h1 className="text-h1 text-pink mb-4 md:mb-8">
          {getVariablePlainText(variables['audio-detail-audio-snippet']) ||
            'Audio snippet'}
        </h1>

        <Soundpiece
          {...currentEntry}
          isActive={getIsActive(currentEntry)}
          onPlay={() => onPlay(0, currentEntry)}
          onPause={onPause}
          audioTimeUpdateEvent={changeEvent}
          duration={currentEntry.duration}
        />
      </div>

      <div className="mt-16 md:mt-22">
        <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center">
          <div className="flex flex-col md:flex-row items-baseline">
            <h2 className="text-h2 text-pink">
              {getVariablePlainText(
                variables['audio-detail-also-interesting'],
              ) || 'Auch interessant'}
            </h2>

            <TitleLink link={link} className="md:ml-4" />
          </div>

          <div className="mb-0 md:pt-1 mt-4 md:mt-0">
            <Switch
              checked={isAutoPlay}
              onChange={handlOnChange}
              switchText={
                getVariablePlainText(variables['switch-text-autoplay']) ||
                'Autoplay'
              }
            />
          </div>
        </div>
      </div>

      <div className="mt-4 md:mt-6">
        <ul>
          {entries.map(mapAudioEntry())}
          {data?.map(mapAudioEntry(5))}
        </ul>
      </div>

      {isLoading ? (
        <div className="flex justify-center">
          <LoadingIcon id={'loading-soundpices-icon'} className="h-12 w-12" />
        </div>
      ) : (
        hasReachedEnd()
      )}
    </div>
  );
};

export default AudioDetail;
