import { FC } from 'react';

import getVariablePlainText from '@/lib/get-variable-plain-text';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useVariables from '@/lib/hooks/use-variables';
import { LinkActionTypeCms } from '@/types/cms';
import { NavItemEntryWithIcon } from '@/types/views/generic';

import DesktopTile from './desktop-tile';
import MobileTile from './mobile-tile';

interface HomeTilesProps {
  navigation: NavItemEntryWithIcon[];
  incompleteProfileData: string[];
}

const HomeTiles: FC<HomeTilesProps> = ({
  navigation,
  incompleteProfileData,
}) => {
  const { isMobile } = useBreakpoint();

  const variables = useVariables();

  const profileNotCompleteText =
    getVariablePlainText(variables[`account-profile-not-complete-text`]) ||
    'Vervollständige dein Profil mit diesen Angaben, um deine Tickets & Preise zu sehen:';

  return (
    <div className="flex flex-col md:flex-row md:flex-wrap md:justify-center gap-x-8">
      {navigation.map((navItem) => {
        const hasIncompleteProfile = !!incompleteProfileData.length;

        const isEditProfileTile =
          navItem?.action === LinkActionTypeCms.EditProfile;

        const isEditProfileTileAndIncompleteProfile =
          isEditProfileTile && hasIncompleteProfile;

        if (isMobile) {
          return (
            <MobileTile
              key={navItem.icon?.id}
              navItem={navItem}
              incompleteProfileText={profileNotCompleteText}
              incompleteProfileData={incompleteProfileData}
              showIcon={isEditProfileTileAndIncompleteProfile}
            />
          );
        }

        return (
          <DesktopTile
            key={navItem.icon?.id}
            navItem={navItem}
            incompleteProfileText={profileNotCompleteText}
            incompleteProfileData={incompleteProfileData}
            showIcon={isEditProfileTileAndIncompleteProfile}
          />
        );
      })}
    </div>
  );
};

export default HomeTiles;
