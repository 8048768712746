import React, { FC } from 'react';

import NormalButton from '@/components/button/normal';
import CardWithSideBlock from '@/components/cards/with-side-block';
import CustomLink from '@/components/custom-link';
import LotteryFormButton from '@/components/lottery-form/button';
import RichTextRenderer from '@/components/rich-text-renderer';
import {
  LotteryFormButton as LotteryFormButtonType,
  SliderItemEntry,
  TextLink,
} from '@/types/views/generic';

import SliderItemTitle from './components/title';

const isLotteryFormButton = (
  link: TextLink | LotteryFormButtonType,
): link is LotteryFormButtonType =>
  (link as LotteryFormButtonType)?.componentType === 'lotteryFormButton';

interface SliderItemProps {
  sliderItem: SliderItemEntry;
}

const SliderItem: FC<SliderItemProps> = ({
  sliderItem: { title, description, background, backgroundMobile, link },
}) => {
  const Slide = () => {
    const button =
      link &&
      (isLotteryFormButton(link) ? (
        <LotteryFormButton {...link} />
      ) : (
        <NormalButton
          className="transition-all"
          text={(link as TextLink).text}
          color="white"
        />
      ));

    return (
      <CardWithSideBlock
        background={background}
        backgroundMobile={backgroundMobile}
        hoverEnabled={Boolean(link)}
      >
        <SliderItemTitle>{title}</SliderItemTitle>

        {description && (
          <div className="mb-4">
            <RichTextRenderer body={description} />
          </div>
        )}

        {link && <div>{button}</div>}
      </CardWithSideBlock>
    );
  };

  return link && !isLotteryFormButton(link) ? (
    <CustomLink {...link}>
      <Slide />
    </CustomLink>
  ) : (
    <Slide />
  );
};

export default SliderItem;
