import cls from 'classnames';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import RichTextRenderer from '@/components/rich-text-renderer';
import Share from '@/components/share';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import getPublishDateWithCategoriesString from '@/lib/get-publish-date-with-categories-string';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useVariables from '@/lib/hooks/use-variables';
import { ArticleTextIntroEntry } from '@/types/views/sections';

import ArticleProfileTags from '../article-profile-tags';
import styles from './styles';

const ArticleTextIntro: FC<ArticleTextIntroEntry> = ({
  title,
  lead,
  publishDate,
  contentCategories,
  link,
  profiles,
}) => {
  const variables = useVariables();
  const { isEventTheme } = usePageTheme();
  const { asPath } = useRouter();
  const isArticlePage = asPath.includes('/artikel');

  const overline = getPublishDateWithCategoriesString(
    publishDate,
    contentCategories,
  );

  return (
    <div className={styles.container}>
      <div
        className={cls(
          styles.contentContainer,
          isArticlePage && styles.articlePaddings,
        )}
      >
        <div className={styles.textContainer}>
          {contentCategories && (
            <div className={styles.contentCategories}>{overline}</div>
          )}

          <h1 className={styles.title}>{title}</h1>

          {lead && <RichTextRenderer className={styles.lead} body={lead} />}

          <div className="flex items-center flex-wrap text-white -mt-2">
            <div className="flex-shrink-0 mt-2 mr-4">
              <Share
                urlToShare={`${getBaseUrlIfBrowser()}${link.href}`}
                theme="button"
                buttonText={
                  getVariablePlainText(variables['share-article-button']) ||
                  'Artikel teilen'
                }
                shareBoxPlacement="bottomRight"
              />
            </div>

            {!isEventTheme && profiles?.length && (
              <ArticleProfileTags profiles={profiles} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTextIntro;
