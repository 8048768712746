interface QuestionMarkProps {
  className?: string;
  fill?: string;
}

const QuestionMarkIcon = ({
  className = '',
  fill = 'currentColor',
}: QuestionMarkProps) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="88"
      viewBox="0 0 55 88"
      fill="none"
      className="w-[36px] h-[58px] lg:w-[55px] lg:h-[88px]"
    >
      <path
        d="M21.12 28.8L22.44 22.2C23.28 18.24 25.8 14.4 29.16 14.4C32.4 14.4 33.12 17.88 32.28 21.6C31.56 25.08 30 28.44 27.24 31.08L17.4 40.08C12 45 9.84 53.04 7.92 61.68H29.04C30.12 57.72 31.08 53.16 33.72 50.64L43.44 41.64C48.84 36.72 52.32 30.36 53.64 23.88C58.56 0.960007 38.76 0 33.12 0C24.36 0 14.64 2.40001 8.28 9.60001C3.24 15.24 1.44 22.32 0 28.8H21.12ZM2.28 87.36H24.12L28.44 67.2H6.6L2.28 87.36Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default QuestionMarkIcon;
