import cls from 'classnames';
import React, { FC } from 'react';

import { TextLink } from '@/types/views/generic';

import CustomLink from '../custom-link';
import styles from './styles';

interface TitleLinkProps {
  link: TextLink;
  className?: string;
}

const TitleLink: FC<TitleLinkProps> = ({ className, link }) => (
  <CustomLink className={cls(styles.link, className)} {...link}>
    {link.text}
  </CustomLink>
);

export default TitleLink;
