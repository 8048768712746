import cls from 'classnames';
import { FC } from 'react';

import Image from '@/components/image';
import { ImageAsset } from '@/types/views/generic';

import styles from './styles';

interface CardWithSideBlockMobileProps {
  background: ImageAsset;
  className?: string;
}

const CardWithSideBlockMobile: FC<CardWithSideBlockMobileProps> = ({
  background,
  children,
  className = '',
}) => (
  <article className={cls(styles.wrapper, className)}>
    <div className={styles.cardImgContainer}>
      <Image
        className={styles.cardImg}
        src={background.src}
        alt={background.description}
      />

      <div className={styles.gradientTop} />
      <div className={styles.gradientBottom} />
    </div>

    <div className={styles.infoWrapper}>{children}</div>
  </article>
);

export default CardWithSideBlockMobile;
