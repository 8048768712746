import cls from 'classnames';
import { FC, ReactNode } from 'react';

import { TextLink } from '@/types/views/generic';

import Title from '../title';
import styles from './styles';

type ContainerProps = {
  hideTitle: boolean;
  link?: TextLink;
  title: string;
  mobile: ReactNode;
  desktop: ReactNode;
  hasInvertedThemeColor?: boolean;
  deactivateAdSlots?: boolean;
};

const Container: FC<ContainerProps> = ({
  mobile,
  desktop,
  hideTitle,
  link,
  title,
  hasInvertedThemeColor = false,
  deactivateAdSlots,
}) => (
  <div className={cls(styles.wrapper, deactivateAdSlots && 'ml-auto')}>
    {(!hideTitle || link) && (
      <Title
        hideTitle={hideTitle}
        link={link}
        title={title}
        hasInvertedThemeColor={hasInvertedThemeColor}
      />
    )}
    <div className="md:hidden">{mobile}</div>

    <div className="hidden md:block">{desktop}</div>
  </div>
);

export default Container;
