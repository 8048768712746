import React, { FC } from 'react';

import Carousel from '@/components/sections/content-feed/components/carousel';
import TitleLink from '@/components/title-link';
import { contentDataByType } from '@/lib/constants';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import { notNull } from '@/lib/utils';
import { ContentFeedMediaOverviewEntry } from '@/types/views/sections';

import renderEntryTeaser from '../content-feed/lib/render-entry-teaser';
import styles from './styles';

const MediaOverview: FC<ContentFeedMediaOverviewEntry> = ({
  id,
  title,
  entryType,
  linkText,
  entries,
}) => {
  const { isMobile } = useBreakpoint();
  const teasers = entries.map(renderEntryTeaser).filter(notNull);
  const isEmpty = !teasers.length;

  const link = {
    isExternal: false,
    href: `${contentDataByType[entryType].href}?tagIdsToInclude=${id}`,
    text: linkText,
    id,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{title}</h2>

        <TitleLink className="md:ml-4" link={link} />
      </div>

      <div className={styles.gridWrapper}>
        <Carousel
          id={`${id}_${entryType}`}
          teaserGroup={contentDataByType[entryType].teaserGroup}
          teasers={teasers}
          isMobile={isMobile}
          isEmpty={isEmpty}
        />
      </div>
    </div>
  );
};

export default MediaOverview;
