import cls from 'classnames';

import Image from '@/components/image';
import { ImageAsset } from '@/types/views/generic';

interface MusicAwardActWinnerProps {
  title: string;
  image: ImageAsset;
  year: string;
  url?: string;
}

const handleClick = (url: string | undefined) => {
  if (url) {
    window.open(url, '_blank');
  }

  return undefined;
};

const MusicAwardActWinner = ({
  title,
  image,
  year,
  url,
}: MusicAwardActWinnerProps) => (
  <div
    onClick={() => handleClick(url)}
    className={cls(
      'relative w-full md:w-[300px] aspect-[4/3] md:h-[300px] rounded-4 select-none overflow-hidden shadow-[0_0_16px_0_rgba(0,0,0,0.25)]',
      url && 'cursor-pointer',
    )}
  >
    <Image
      src={image.src}
      alt={title}
      basicLoader
      objectFit="cover"
      className="h-full w-full"
    />

    <div className="absolute top-0 left-0 inline-flex py-2 px-4 justify-center items-center bg-[rgba(0,0,0,0.75)] rounded-br-4">
      <span className="text-body1 text-center">{year}</span>
    </div>

    <div className="w-full h-[64px] md:h-[76px] absolute bottom-0 left-0 py-2 px-4 flex justify-center items-center bg-[rgba(0,0,0,0.75)]">
      <span className="text-h5 text-center">{title}</span>
    </div>
  </div>
);

export default MusicAwardActWinner;
