import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import PlayIcon from '@/components/icons/play-icon';
import RichTextRenderer from '@/components/rich-text-renderer';
import useVariables from '@/lib/hooks/use-variables';
import { selectPlayerChannelGroups } from '@/store/slices/player';
import { WeitereChannelsSectionEntry } from '@/types/views/sections';

import styles from './styles';

const WeitereChannels: FC<WeitereChannelsSectionEntry> = ({
  title,
  showTitle,
}) => {
  const channelGroups = useSelector(selectPlayerChannelGroups);
  const variables = useVariables();

  return (
    <div className={styles.wrapper}>
      {showTitle && <h2 className={styles.header}>{title}</h2>}

      {!channelGroups.external.length ? (
        <RichTextRenderer
          body={variables['no-weitere-channels-text']}
          className="text-lead"
        />
      ) : (
        <div className={styles.channelsWrapper}>
          {channelGroups.external.map((item) => (
            <div key={item.id}>
              <a
                href={item.streamUrl}
                target="_blank"
                className={styles.channel}
                rel="noreferrer"
              >
                <PlayIcon className="mr-2" />
                {item.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeitereChannels;
