import getPlayerMetadataFromIcyMetadata from '@/lib/get-player-metadata-from-icy-metadata';
import getPlayoutById from '@/lib/get-playout-by-id';
import { Metadata } from '@/store/slices/player';
import { Channel } from '@/types/views/generic';

let localPlayerInstance: IcecastMetadataPlayer | null = null;

const getLocalStationPlayer = (
  libLoaded: boolean,
  channel: Channel | null,
  onMetadataCb: (metadata: Metadata) => void,
) => {
  if (!libLoaded) {
    return null;
  }

  if (typeof window !== 'undefined' && channel) {
    const streamUrl = `${channel.streamUrl}?ua=energy+website+metadata+only`;

    localPlayerInstance = new IcecastMetadataPlayer(streamUrl, {
      onMetadata: async (icyMetadata) => {
        const streamMetadata = getPlayerMetadataFromIcyMetadata(icyMetadata);

        if (streamMetadata) {
          onMetadataCb(streamMetadata);
        } else {
          return;
        }

        const { searchParams } = new URL(icyMetadata.StreamUrl);
        const playoutIdString = searchParams.get('plId');

        if (!playoutIdString) {
          return;
        }

        const playoutId = parseInt(playoutIdString, 10);

        try {
          const playout = await getPlayoutById(playoutId, channel.id);

          if (playout) {
            onMetadataCb({
              title: streamMetadata.title,
              artist: playout.song?.artists_full ?? streamMetadata.artist,

              artists:
                playout.song?.artists.map(({ id, name }) => ({
                  id,
                  name,
                })) ?? null,

              duration: playout.song?.duration,
              coverUrl: playout.resolved_cover_url,
            });
          } else {
            // TODO: ECH-3457
            console.warn('Could not get metadata from BM.');
          }
        } catch (error) {
          // TODO: ECH-3457
          console.error(error);
          console.error('AWS client sent error');
        }
      },
    });
  }

  return localPlayerInstance;
};

export default getLocalStationPlayer;
