import cls from 'classnames';
import { FC } from 'react';

import Image from '@/components/image';
import Share from '@/components/share';
import Skeleton from '@/components/skeleton';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import { Artist } from '@/types/views/generic';

interface StationSongInfoProps {
  stationSlug: string;
  stationLogoUrl?: string | null;
  songTitle: string | null;
  songArtists: Artist[] | string[] | null;
  className?: string;
  isMetaDataLoaded?: boolean;
}

const StationSongInfo: FC<StationSongInfoProps> = ({
  stationSlug,
  stationLogoUrl,
  songTitle,
  songArtists,
  className,
  isMetaDataLoaded,
}) => {
  const renderSongData = () => (
    <div className="relative hidden md:flex justify-center h-16">
      {!isMetaDataLoaded && (
        <div className="absolute md:flex md:flex-col md:justify-center md:items-center w-60">
          <Skeleton className="h-[1.375rem] w-50 mb-1" />

          <Skeleton className="h-[1.375rem] w-full" />
        </div>
      )}

      {(songTitle || songArtists) && (
        <div
          className={cls(
            'absolute md:flex md:flex-col md:justify-center md:items-center',
          )}
        >
          {songTitle && (
            <span className="whitespace-nowrap text-body1 font-semibold text-gray-3">
              {songTitle}
            </span>
          )}

          {!!songArtists?.length && (
            <span className="whitespace-nowrap text-body1 text-gray-3">
              {songArtists.join(' - ')}
            </span>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cls('w-full flex flex-col items-center basis-1/4', className)}
    >
      {stationLogoUrl && (
        <div className="overflow-hidden rounded-full mb-6 p-8 xl:p-12 bg-white h-[208px] w-[208px] 2xl:h-[290px] 2xl:w-[290px]">
          <Image src={stationLogoUrl} alt="Station logo" objectFit="fill" />
        </div>
      )}

      <div className="flex flex-col mt-auto">
        {renderSongData()}

        <div className="hidden md:flex justify-center items-center">
          <Share
            urlToShare={`${getBaseUrlIfBrowser()}/stations/${stationSlug}`}
          />
        </div>
      </div>
    </div>
  );
};

export default StationSongInfo;
