import { format } from 'date-fns';
import { FC } from 'react';

import ArticleProfileTags from '@/components/article/article-profile-tags';
import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import Share from '@/components/share';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import { RelatedArticleEntry } from '@/types/views/generic';

const RelatedArticle: FC<RelatedArticleEntry> = ({
  image,
  updatedAt,
  contentCategories,
  title,
  profiles,
  link,
  theme,
}) => {
  const { href } = link;

  return (
    <div className="mb-18 md:mb-28 last:mb-0">
      <div
        className={`md:w-10/12 mx-auto mb-4 ${
          theme ? 'rounded-4 overflow-hidden' : ''
        }`}
      >
        <CustomLink href={href}>
          <Image
            className="aspect aspect-16-9"
            alt={title}
            useIntrinsicSize={false}
            src={image.src}
            objectFit="cover"
          />
        </CustomLink>
      </div>

      <div className="md:w-8/12 md:mx-auto">
        <div className="flex items-center justify-between mb-2">
          <div className="flex text-overline w-full text-gray-2 teair:text-air-lila tesn:text-esn-secondary-light">
            <span>{format(new Date(updatedAt), 'dd.MM.yyyy')}</span>

            {contentCategories && (
              <span className="hidden md:inline-block truncate ml-1">
                {contentCategories.map((category, i) =>
                  i === 0 ? `/ ${category}` : `, ${category}`,
                )}
              </span>
            )}
          </div>

          <Share urlToShare={`${getBaseUrlIfBrowser()}${href}`} />
        </div>

        <h4 className="text-h4 text-white">
          <CustomLink href={href}>{title}</CustomLink>
        </h4>

        {!theme && profiles && (
          <div className="md:mt-4">
            <ArticleProfileTags profiles={profiles} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatedArticle;
