import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import ChipSelect from '@/components/chip-select';
import RichTextRenderer from '@/components/rich-text-renderer';
import useVariables from '@/lib/hooks/use-variables';
import { JobsSectionEntry } from '@/types/views/sections';

import styles from './styles';
import Tile from './tile';

const ALL = 'Alle';

const Jobs: FC<JobsSectionEntry> = ({ title, showTitle, jobs }) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [currentFilter, setCurrentFilter] = useState<string>(ALL);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const variables = useVariables();

  const numberOfJobs = jobs.length;

  useEffect(() => {
    if (!numberOfJobs) return;

    const filtersMap: { [index: string]: boolean } = {};

    jobs.forEach((job) => {
      if (job.location && job.location !== ALL) {
        filtersMap[job.location] = true;
      }
    });

    const filterNames = Object.keys(filtersMap);

    // It makes sense to show filters only for jobs from at least 2 different cities.
    if (filterNames.length > 1) {
      setFilters([ALL, ...filterNames]);
    }
  }, [jobs, numberOfJobs]);

  const setFilter = (city: string) => {
    if (city !== currentFilter) {
      setCurrentFilter(city);

      if (!shouldAnimate) setShouldAnimate(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      {(showTitle || !!filters.length) && (
        <div className={styles.headerWrapper}>
          {showTitle && <h2 className={styles.header}>{title}</h2>}

          {!!filters.length && (
            <div className={styles.filtersWrapper}>
              <ChipSelect activeIndex={filters.indexOf(currentFilter)}>
                {filters.map((filter) => (
                  <button
                    type="button"
                    key={filter}
                    onClick={() => setFilter(filter)}
                  >
                    {filter}
                  </button>
                ))}
              </ChipSelect>
            </div>
          )}
        </div>
      )}

      {!numberOfJobs ? (
        <RichTextRenderer
          body={variables['no-jobs-text']}
          className="text-lead"
        />
      ) : (
        <div className={styles.tilesWrapper}>
          {jobs
            .filter(
              (job) =>
                [ALL, job.location].includes(currentFilter) ||
                !job.location ||
                job.location === ALL,
            )
            .map((job, index) => {
              // Workaround to set dynamic animation duration.
              const dynamicDuration = css`
                animation-duration: ${800 / (numberOfJobs - index)}ms;
              `;

              return (
                // 'key trick' to force child rerender to restart an animation.
                <Tile
                  key={`${currentFilter}_${index}`}
                  {...job}
                  location={job.location ?? ALL}
                  className={
                    shouldAnimate ? `animate-zoom-in ${dynamicDuration}` : ''
                  }
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Jobs;
