import { FC } from 'react';

import { ImageAsset } from '@/types/views/generic';

import CardWithSideBlockDesktop from './components/card-desktop';
import CardWithSideBlockMobile from './components/card-mobile';

interface CardWithSideBlockProps {
  background: ImageAsset;
  backgroundMobile: ImageAsset;
  className?: string;
  hoverEnabled?: boolean;
}

const CardWithSideBlock: FC<CardWithSideBlockProps> = ({
  children,
  background,
  backgroundMobile,
  className = '',
  hoverEnabled = false,
}) => (
  <>
    <CardWithSideBlockDesktop
      background={background}
      hoverEnabled={hoverEnabled}
      className={className}
    >
      {children}
    </CardWithSideBlockDesktop>

    <CardWithSideBlockMobile
      background={backgroundMobile}
      className={className}
    >
      {children}
    </CardWithSideBlockMobile>
  </>
);

export default CardWithSideBlock;
