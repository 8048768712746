import React, { FC } from 'react';

import { padTimeToTwo } from '@/lib/utils';

interface CountdownDownProps {
  // values in ms
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownDown: FC<CountdownDownProps> = ({ hours, minutes, seconds }) => (
  <span className="text-body2 text-gray-1">
    {hours > 0 && `${hours}:`}
    {padTimeToTwo(minutes)}:{padTimeToTwo(seconds)}
  </span>
);

export default CountdownDown;
