import { css } from '@emotion/css';
import cls from 'classnames';
import React, { FC } from 'react';

import useVariables from '@/lib/hooks/use-variables';
import { Link, ToggleData } from '@/types/views/generic';
import { StationHeaderSectionEntry } from '@/types/views/sections';

import useShowData from '../../lib/hooks/use-show-data';
import LinksChipSelect from '../links-chip-select';
import Skeleton from '../skeleton';
import ActionButtons from './action-buttons';
import Countdowns from './countdowns';
import PopoverChipSelect from './popover-chip-select';
import ShowInfo from './show-info';
import ShowInfoPlaceholder from './show-info-placeholder';
import StationSongInfo from './station-song-info';
import useStationPlayer from './use-station-player';
import VoiceMessage from './voice-message';

const placeholderBlockClass = css`
  ::after {
    content: '';
    flex: 1;
  }
`;

interface StationPlayerProps {
  station: StationHeaderSectionEntry['station'];
  playlistLink: Link;
  whatsappNumber: string;
  toggleLinksData?: ToggleData[];
  className?: string;
}

const StationPlayer: FC<StationPlayerProps> = ({
  station,
  toggleLinksData = [],
  playlistLink,
  className,
  whatsappNumber,
}) => {
  const variables = useVariables();

  const { basicPlayoutMetadata, isDisplayedStationPlaying, isMetaDataLoaded } =
    useStationPlayer(station.id);

  const {
    show,
    // fetchCurrentShow,
    isLoading,
  } = useShowData(station.id);

  const handleCountdownComplete = () => {
    // TODO (https://energych.atlassian.net/browse/ECH-4140): Bring this back in optimized way
    // fetchCurrentShow();
  };

  const activeLinkIndex = toggleLinksData.findIndex((toggleItem) =>
    toggleItem.href.includes(station.id),
  );

  return (
    <div className={cls(className, 'py-6 md:py-12')}>
      <div className="flex justify-between items-center pb-8">
        {toggleLinksData.length > 1 && (
          <>
            <LinksChipSelect
              activeLinkIndex={activeLinkIndex}
              toggleLinksData={toggleLinksData}
              className="hidden md:flex"
            />

            <PopoverChipSelect
              activeLinkIndex={activeLinkIndex}
              toggleLinksData={toggleLinksData}
              variables={variables}
              className="md:hidden"
            />
          </>
        )}

        <div className="ml-auto">
          <VoiceMessage variables={variables} whatsappNumber={whatsappNumber} />
        </div>
      </div>

      <div
        className={cls(
          'flex flex-col-reverse md:flex-row justify-between',
          placeholderBlockClass,
        )}
      >
        <div className="flex-1">
          {isLoading ? (
            <ShowInfoPlaceholder />
          ) : (
            show && (
              <ShowInfo
                title={show.title}
                tags={show.moderatorTags}
                from={show.from}
                to={show.to}
              />
            )
          )}
        </div>

        <StationSongInfo
          stationSlug={station.slug}
          stationLogoUrl={station.logoUrl}
          isMetaDataLoaded={isMetaDataLoaded}
          songTitle={basicPlayoutMetadata?.title || null}
          songArtists={basicPlayoutMetadata?.artists || null}
        />
      </div>

      {station.frequency && (
        <span className="text-overline text-gray-3 font-medium">
          {station.frequency}
        </span>
      )}

      {isLoading ? (
        <>
          <div className="mt-2 mb-1">
            <Skeleton className="h-4 w-full" />
          </div>

          <div className="flex md:justify-between justify-end">
            <Skeleton className="hidden md:flex h-4 w-20" />

            <Skeleton className="h-4 w-20" />
          </div>
        </>
      ) : (
        show?.from &&
        show?.to && (
          <Countdowns
            from={show.from}
            to={show.to}
            onCountdownComplete={handleCountdownComplete}
          />
        )
      )}

      <ActionButtons
        displayedStationId={station.id}
        isDisplayedStationPlaying={isDisplayedStationPlaying}
        playlistLink={playlistLink}
      />
    </div>
  );
};

export default StationPlayer;
