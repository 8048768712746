import cls from 'classnames';
import React, { FC } from 'react';

import Image from '@/components/image';
import { isVideoAsset } from '@/lib/utils';
import { MediaAsset } from '@/types/views/generic';

const renderVideo = (src: string, isMobile: boolean) => (
  <video
    autoPlay
    playsInline
    loop
    muted
    src={src}
    className={cls(
      'absolute z-10 inset-0 object-cover h-full w-full',
      isMobile ? 'block md:hidden' : 'hidden md:block',
    )}
  />
);

interface MediaBackgroundProps {
  background: MediaAsset;
  backgroundMobile: MediaAsset;
}

const MediaBackground: FC<MediaBackgroundProps> = ({
  background,
  backgroundMobile,
}) => {
  const isBackgroundAVideoAsset = isVideoAsset(background);

  const isMobileBackgroundAVideoAsset =
    !!backgroundMobile && isVideoAsset(backgroundMobile);

  const renderBackground = () =>
    isBackgroundAVideoAsset ? (
      renderVideo(background.src, false)
    ) : (
      <Image
        src={background.src}
        objectFit="cover"
        className="hidden md:block"
      />
    );

  const renderMobileBackground = () =>
    isMobileBackgroundAVideoAsset ? (
      renderVideo(backgroundMobile.src, true)
    ) : (
      <Image
        src={backgroundMobile.src}
        objectFit="cover"
        className="block md:hidden"
      />
    );

  return (
    <>
      {renderBackground()}

      {renderMobileBackground()}
    </>
  );
};

export default MediaBackground;
