import { useState } from 'react';

import { MusicAwardActEntry } from '@/types/views/generic';

import MusicAwardActNominee from '../act-nominee';

interface MusicAwardVotingProps {
  votingStart: string;
  votingEnd: string;
  acts: MusicAwardActEntry[];
}

const MusicAwardVoting = ({
  votingStart,
  votingEnd,
  acts,
}: MusicAwardVotingProps) => {
  const [votedOn, setVotedOn] = useState(false);
  const [actVotedOn, setActVotedOn] = useState('');

  return (
    <>
      {acts.map((act) => (
        <MusicAwardActNominee
          key={act.id}
          id={act.id}
          title={act.title}
          lotteryPool={act.lotteryPool}
          image={act.image}
          votingStart={votingStart}
          votingEnd={votingEnd}
          votedOn={votedOn}
          setVotedOn={setVotedOn}
          actVotedOn={actVotedOn}
          setActVotedOn={setActVotedOn}
        />
      ))}
    </>
  );
};

export default MusicAwardVoting;
