import { useState } from 'react';

import CustomLink from '@/components/custom-link';
import { NavItemEntryWithIcon } from '@/types/views/generic';

import IncompleteProfileMobileModal from '../incomplete-profile-mobile-modal';
import MobileBaseTile from '../mobile-base-tile';

interface MobileTileProps {
  navItem: NavItemEntryWithIcon;
  incompleteProfileData: string[];
  incompleteProfileText: string;
  showIcon?: boolean;
}

const MobileTile = ({
  navItem,
  incompleteProfileData,
  incompleteProfileText,
  showIcon,
}: MobileTileProps) => {
  const [incompleteProfileModalOpen, setIncompleteProfileModalOpen] =
    useState<boolean>(false);

  const onIncompleteProfileClick = () => setIncompleteProfileModalOpen(true);
  const hasIncompleteProfile = !!incompleteProfileData.length;

  const isTicketsTile = navItem.link.href === '/account/tickets';

  const onCloseIncompleteProfileModal = () =>
    setIncompleteProfileModalOpen(false);

  if (isTicketsTile && hasIncompleteProfile) {
    return (
      <>
        {incompleteProfileModalOpen && (
          <IncompleteProfileMobileModal
            text={incompleteProfileText}
            incompleteProfileData={incompleteProfileData}
            onCloseIncompleteProfileModal={onCloseIncompleteProfileModal}
          />
        )}

        <MobileBaseTile
          navItem={navItem}
          openModal={onIncompleteProfileClick}
          showIcon={showIcon}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col">
      <CustomLink {...navItem.link} key={navItem.link.id}>
        <MobileBaseTile navItem={navItem} showIcon={showIcon} />
      </CustomLink>
    </div>
  );
};

export default MobileTile;
