import { Children, cloneElement, ReactElement } from 'react';

import LoadingIcon from '@/components/icons/loading-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import useVariables from '@/lib/hooks/use-variables';
import { TextLink } from '@/types/views/generic';

import SoundpiecePlaylistContainer from '../../../../sections/soundpiece-playlist/components/soundpiece-playlist-container';
import Title from '../title';

type SoundpiecePlaylistProps = {
  hideTitle: boolean;
  link?: TextLink;
  teasers: ReactElement[];
  title: string;
  loadMore?: () => void;
  isLoading: boolean;
  isAllDataLoaded: boolean;
  deactivateAdSlots?: boolean;
};

const SoundpiecePlaylist: React.FC<SoundpiecePlaylistProps> = ({
  hideTitle,
  link,
  teasers,
  title,
  loadMore,
  isLoading,
  isAllDataLoaded,
  deactivateAdSlots,
}) => {
  const { onPlay, onPause, changeEvent, setIsAutoPlay, addToAutoPlaylist } =
    useAudioPlayer({});

  const variables = useVariables();

  return (
    <SoundpiecePlaylistContainer
      title={
        (!hideTitle || link) && (
          <Title hideTitle={hideTitle} link={link} title={title} />
        )
      }
      setAudioPlayerIsAutoPlay={setIsAutoPlay}
      deactivateAdSlots={deactivateAdSlots}
    >
      {Children.map(teasers, (teaser, index) =>
        cloneElement(teaser, {
          index,
          onPlay,
          onPause,
          changeEvent,
          addToAutoPlaylist,
        }),
      )}
      {loadMore && !isLoading && !isAllDataLoaded && (
        <div className="flex justify-end">
          <button
            className="bg-red-medium py-3 px-6 rounded-2"
            onClick={loadMore}
          >
            {getVariablePlainText(variables['audio-detail-load-more'])}
          </button>
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center mb-4">
          <LoadingIcon id={'loading-soundpices-icon'} className="h-12 w-12" />
        </div>
      )}
    </SoundpiecePlaylistContainer>
  );
};

export default SoundpiecePlaylist;
