import React, { FC } from 'react';

import { padTimeToTwo } from '@/lib/utils';

import { getTimeUnitsRemainders } from '../utils';

interface CountdownUpProps {
  // values in ms
  duration: number;
  timeLeft: number;
}

const CountdownUp: FC<CountdownUpProps> = ({ duration, timeLeft }) => {
  const timePassed = duration - timeLeft;
  const { seconds, minutes, hours } = getTimeUnitsRemainders(timePassed);

  return (
    <span className="text-body2 text-gray-1">
      {hours > 0 && `${hours}:`}
      {padTimeToTwo(minutes)}:{padTimeToTwo(seconds)}
    </span>
  );
};

export default CountdownUp;
