import { Document } from '@contentful/rich-text-types';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import RichTextRenderer from '@/components/rich-text-renderer';
import { GridEntryTypeCms } from '@/types/cms';
import { ImageAsset, Link } from '@/types/views/generic';

import styles from './styles';

interface ImageWithTextProps {
  title?: string;
  link?: Link;
  image: ImageAsset;
  text?: Document;
  type?: GridEntryTypeCms;
}

const ImageWithText: FC<ImageWithTextProps> = ({
  image,
  link,
  title,
  text,
  type = GridEntryTypeCms.Info,
}) => {
  const infoImageWidth = 70;
  const isInfoType = type === GridEntryTypeCms.Info;
  const isCentered = !title && !text;
  const imageElement = (
    <Image
      src={image.src}
      alt={image.description}
      useIntrinsicSize={true}
      width={isInfoType ? infoImageWidth : image.width}
      height={
        isInfoType
          ? (infoImageWidth * image.height) / image.width
          : image.height
      }
      className={styles.image(isInfoType, isCentered)}
    />
  );

  return (
    <div className={styles.container(isCentered)}>
      {link ? <CustomLink {...link}>{imageElement}</CustomLink> : imageElement}

      <div className="tesn:p-4">
        {title && <h5 className={styles.title(isInfoType)}>{title}</h5>}

        {text && <RichTextRenderer body={text} className={styles.text} />}
      </div>
    </div>
  );
};

export default ImageWithText;
