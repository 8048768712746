import { css } from '@emotion/css';
import cls from 'classnames';
import { isBefore } from 'date-fns';
import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import QuizBackground from '@/components/sections/quiz/components/quiz-background';
import { goToProfile, login } from '@/lib/auth';
import fetchWithCatch from '@/lib/fetch-with-catch';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import getIncompleteProfileFields, {
  requiredFieldsVariableNames,
} from '@/middleware/lib/get-incomplete-profile-fields';
import { selectGlobalPageTheme } from '@/store/slices/global';
import {
  selectIsUserLoading,
  selectIsUserLoggedIn,
  selectUserProfile,
} from '@/store/slices/user';
import { QuizApi } from '@/types/apis';
import { QuizSectionEntry } from '@/types/views/sections';

import Consent from './components/consent';
import QuizMessage from './components/quiz-message';
import QuizTimerMessage from './components/quiz-timer-message';
import QuizState = QuizApi.QuizState;

const customAnimationTransform = css`
  &:checked + label {
    transform: translate(calc(0.25rem - 3px), 0.25rem);
  }
`;

type QuizRequestData = {
  questionId?: number;
  answerId?: number;
  appName?: string;
  prizeID?: string;
  phoneNumber?: string;
  marketingOptIn?: boolean;
};

const Quiz: FC<QuizSectionEntry> = ({
  title,
  displayTitle = false,
  deactivateAdSlots = false,
  correctTitle = '',
  correctMessage = '',
  invalidAnswerTitle = '',
  invalidAnswerMessage = '',
  messageAlreadyAttended = '',
  messageWithoutQuiz = '',
  sweepstakeDataToken = '',
  userNotWonTitle = '',
  validProfileWonTitle = '',
  loginForResultsMessage = '',
  marketingOptInText = '',
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const quizWrapperRef = useRef<HTMLDivElement>(null);
  const variables = useVariables();

  const [consentState, setConsentState] = useState(true);
  const [quizState, setQuizState] = useState<QuizState>(QuizState.LOADING);
  const [quizMessage, setQuizMessage] = useState<string>();
  const [quizData, setQuizData] = useState<QuizApi.QuizEntry | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const isLoading = quizState === QuizState.LOADING;
  const userProfile = useSelector(selectUserProfile);
  const isUserLoading = useSelector(selectIsUserLoading);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);
  const incompleteProfileData = getIncompleteProfileFields(userProfile);

  const handleFetchInitialData = async () => {
    if (isUserLoading) {
      setQuizState(QuizState.LOADING);

      return;
    }

    if (!userProfile) {
      setQuizState(QuizState.USER_NOT_LOGGED);

      return;
    }

    if (incompleteProfileData.length) {
      setQuizState(QuizState.INCOMPLETE_PROFILE);

      return;
    }

    const { data, error } = await fetchWithCatch<QuizApi.QuizEntry>(() =>
      fetch('/api/quiz/question'),
    );

    if (error || !data) {
      setQuizState(QuizState.ERROR);
      console.error(error);

      return;
    }

    if (data?.question === null) {
      setQuizData(data);
      setQuizState(QuizState.NO_QUESTION);
    } else {
      setQuizData(data);
      setQuizState(
        data.didUserAlreadyAnswer
          ? QuizState.ALREADY_ANSWERED
          : QuizState.DEFAULT,
      );
    }
  };

  const handleSubmitRequest = async (requestData?: QuizRequestData) => {
    const body = {
      ...(requestData && { ...requestData }),
      ...(sweepstakeDataToken && { sweepstakeDataToken }),
    };

    const { data, error } = await fetchWithCatch<QuizApi.QuizAnswerProxyData>(
      () =>
        fetch('/api/quiz/answer', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        }),
    );

    if (data?.consent) {
      setConsentState(true);
    }

    if (data?.questionTimedOut) {
      await handleFetchInitialData();

      setQuizState(QuizState.DEFAULT);

      return;
    }

    if (data?.alreadyAnswered) {
      if (!quizData) {
        await handleFetchInitialData();
      }
      setQuizState(QuizState.ALREADY_ANSWERED);

      return;
    }

    if (error || !data) {
      setQuizState(QuizState.ERROR);
      console.error(error);

      return;
    }

    if (data.wasCorrect) {
      setQuizState(QuizState.CORRECT);

      if (sweepstakeDataToken) {
        if (data.hasWon) {
          setTimeout(() => {
            setQuizState(QuizState.SWEEPSTAKE_WIN);
            setQuizMessage(data.text);
          }, 3250);
        } else {
          setTimeout(() => {
            setQuizState(QuizState.SWEEPSTAKE_NOT_WIN);
            setQuizMessage(data.text);
          }, 3250);
        }
      }
    } else {
      setQuizState(QuizState.WRONG_ANSWER);
    }
  };

  const buttonText =
    getVariablePlainText(variables['quiz-send-button-text']) || 'Send';
  const buttonLoginText =
    getVariablePlainText(variables['quiz-login-button']) || 'Login';

  const profileRequiredText =
    getVariablePlainText(variables['quiz-complete-profile-required-text']) ||
    'Gib diese Informationen in deinem Profil an, um am Quiz teilnehmen zu können:';

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!quizData?.question || isButtonDisabled) {
      return;
    }

    setIsButtonDisabled(true);

    const formData = new FormData(event.target as HTMLFormElement);
    const currentOption = formData.get('quizQuestionOption');
    const marketingOptIn = formData.get('marketingOptIn');

    const requestData = {
      questionId: quizData.question.questionId,
      answerId: Number(currentOption),
      marketingOptIn: Boolean(marketingOptIn),
    };

    await handleSubmitRequest(requestData);

    setIsButtonDisabled(false);
  };

  const handleCountdownFinish = async () => {
    setQuizState(QuizState.LOADING);

    await handleFetchInitialData();
  };

  const commonQuizMessageProps = {
    switchState: true,
    onSwitchState: async () => {
      if (!quizData) {
        await handleFetchInitialData();
      }

      // Cover case when new question is already available after win in previous one
      const isCaseFromWinToNewQuestion =
        quizState === QuizState.SWEEPSTAKE_WIN &&
        typeof quizData?.nextQuestionStart === 'string' &&
        isBefore(new Date(quizData.nextQuestionStart), new Date());

      if (isCaseFromWinToNewQuestion) {
        await handleFetchInitialData();
        setQuizState(QuizState.DEFAULT);

        return;
      }

      setQuizState(QuizState.ALREADY_ANSWERED);
    },
  };

  useEffect(() => {
    if (!window) return;

    if (quizState !== QuizState.ALREADY_ANSWERED) {
      handleFetchInitialData();
    }

    if (isUserLoading) return;

    if (!isUserLoggedIn) {
      setQuizState(QuizState.USER_NOT_LOGGED);
    }
  }, [isUserLoading, quizWrapperRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function handleConsent() {
      const { data, error } = await fetchWithCatch(() =>
        fetch(`/api/quiz/consent?sweepstakeDataToken=${sweepstakeDataToken}`),
      );

      const { consent } = data as { consent: boolean };

      if (error || consent === undefined) {
        console.error(error);
        setConsentState(true);

        return;
      }

      setConsentState(consent);
    }
    handleConsent();
  }, [sweepstakeDataToken]);

  useEffect(() => {
    if (formRef.current?.checkValidity()) {
      setIsButtonDisabled(false);
    }
  }, [formRef.current?.checkValidity]);

  return (
    <>
      <QuizBackground hasWon={quizState === QuizState.CORRECT} />

      <div
        className="content-box relative z-1 min-h-[568px] grid place-items-center"
        ref={quizWrapperRef}
      >
        {displayTitle && <h2>{title}</h2>}

        {isLoading ? (
          <LoadingIcon id="loading-icon" className="w-24 h-24" />
        ) : (
          <>
            {quizState === QuizState.DEFAULT && !!quizData?.question && (
              <form
                onSubmit={handleSubmit}
                ref={formRef}
                className="pt-8 pb-4 px-6"
              >
                <h3 className="text-body1 text-white text-center font-semibold">
                  <QuizTimerMessage
                    hasPlainDate
                    nextQuestionDate={quizData.question.questionEnd}
                    onCountdownFinish={handleCountdownFinish}
                  />
                </h3>

                <h4 className="text-h4 text-white text-center text-border drop-shadow-small mt-4">
                  {quizData.question.questionText}
                </h4>

                <ul className="my-4 flex flex-col items-center">
                  {quizData.question.options.map((option) => (
                    <li
                      key={option.id}
                      className="my-4 max-w-[508px] w-full group"
                    >
                      <input
                        type="radio"
                        name="quizQuestionOption"
                        id={`${option.id}`}
                        value={option.id}
                        onChange={() => setIsButtonDisabled(false)}
                        required
                        className={cls('hidden peer', customAnimationTransform)}
                      />

                      <label
                        htmlFor={`${option.id}`}
                        className={cls(
                          'group-hover:bg-game-orange-light cursor-pointer bg-white p-6 block shadow-quizOption rounded-2 border-[3px] text-body1 text-black font-semibold',
                          'peer-checked:bg-game-orange peer-checked:shadow-none',
                          'transition-quizOption duration-200 ease-in-out',
                        )}
                      >
                        {option.text}
                      </label>
                    </li>
                  ))}
                </ul>

                {marketingOptInText && !consentState && (
                  <Consent text={marketingOptInText} />
                )}

                <div className="flex justify-center mt-8">
                  <NormalButton
                    type="submit"
                    {...buttonProps}
                    text={buttonText}
                    disabled={isButtonDisabled || isLoading}
                    className="text-body2 font-semibold"
                  />
                </div>
              </form>
            )}

            {quizState === QuizState.ERROR && (
              <QuizMessage title="Hoppala..." message="Da ging was schief." />
            )}

            {quizState === QuizState.INCOMPLETE_PROFILE && (
              <QuizMessage title="Hoppala...">
                <div className="flex flex-col justify-center items-center w-full px-10">
                  <h4
                    className={cls(
                      'text-h4 text-white text-border drop-shadow-small my-8 font-semibold',
                      'animate-fade-in opacity-0',
                    )}
                  >
                    {profileRequiredText}
                  </h4>
                  <ul className="mb-8 w-full px-10">
                    {incompleteProfileData?.map((field) => (
                      <li
                        className={cls(
                          'text-h4 text-white text-border drop-shadow-small font-semibold list-disc',
                          'animate-fade-in opacity-0',
                        )}
                      >
                        {getVariablePlainText(
                          variables[requiredFieldsVariableNames[field]],
                        ) || field}
                      </li>
                    ))}
                  </ul>
                  <NormalButton
                    {...buttonProps}
                    size="L"
                    type="submit"
                    text="Profil bearbeiten"
                    onClick={() => goToProfile()}
                  />
                </div>
              </QuizMessage>
            )}

            {quizState === QuizState.ALREADY_ANSWERED && quizData && (
              <QuizTimerMessage
                message={messageAlreadyAttended}
                nextQuestionDate={quizData.nextQuestionStart}
                onCountdownFinish={handleCountdownFinish}
              />
            )}

            {quizState === QuizState.CORRECT && (
              <QuizMessage
                message={correctMessage}
                title={correctTitle}
                hasWon
                {...commonQuizMessageProps}
              />
            )}

            {quizState === QuizState.WRONG_ANSWER && (
              <QuizMessage
                title={invalidAnswerTitle}
                message={invalidAnswerMessage}
                {...commonQuizMessageProps}
              />
            )}

            {quizState === QuizState.SWEEPSTAKE_NOT_WIN && (
              <QuizMessage
                title={userNotWonTitle}
                message={quizMessage}
                {...commonQuizMessageProps}
              />
            )}

            {quizState === QuizState.SWEEPSTAKE_WIN && (
              <QuizMessage
                message={quizMessage}
                title={validProfileWonTitle}
                hasWon
                {...commonQuizMessageProps}
              />
            )}

            {quizState === QuizState.NO_QUESTION && quizData && (
              <QuizTimerMessage
                message={messageWithoutQuiz}
                nextQuestionDate={quizData.nextQuestionStart}
                onCountdownFinish={handleCountdownFinish}
              />
            )}

            {quizState === QuizState.USER_NOT_LOGGED && (
              <QuizMessage message={loginForResultsMessage}>
                <div className="flex justify-center items-center">
                  <NormalButton
                    {...buttonProps}
                    size="L"
                    type="submit"
                    text={buttonLoginText}
                    onClick={login}
                  />
                </div>
              </QuizMessage>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Quiz;
