import * as R from 'ramda';

import { getRandomElement } from './utils';

const CONTENT_SECTIONS_BETWEEN_ADS = [6, 7, 8];

const getWatchExperienceAdPositionMap = (
  sectionLength: number,
  currentAdString = '',
): string => {
  if (sectionLength <= 0) return '';

  if (currentAdString) {
    const currentAdStringLength = currentAdString.length;
    const componentsBetweenAds = currentAdString.split(/[12]/g);
    const lastComponentsCount = componentsBetweenAds.slice(-1)[0].length;
    const shouldGeneratePostfix =
      !CONTENT_SECTIONS_BETWEEN_ADS.includes(lastComponentsCount);

    let postfix = '';

    if (shouldGeneratePostfix) {
      const elementsToRender =
        getRandomElement(CONTENT_SECTIONS_BETWEEN_ADS) - lastComponentsCount;

      postfix = R.range(0, elementsToRender)
        .map(() => 'C')
        .join('');
    }

    const generatedNextAdString = getWatchExperienceAdPositionMap(
      sectionLength - currentAdStringLength - 1,
      '',
    ).replace('1', '2');

    return `${currentAdString}${postfix}2${generatedNextAdString}`.slice(
      0,
      sectionLength,
    );
  }

  let previousAdIndex = -1;
  let contentSectionsBeforeNextAd = getRandomElement(
    CONTENT_SECTIONS_BETWEEN_ADS,
  );

  return Array(sectionLength)
    .fill(null)
    .map((_, sectionIndex) => {
      if (sectionIndex === contentSectionsBeforeNextAd + previousAdIndex) {
        const stringWithAd = `C${previousAdIndex === -1 ? '1' : '2'}`;

        previousAdIndex = sectionIndex;
        contentSectionsBeforeNextAd = getRandomElement(
          CONTENT_SECTIONS_BETWEEN_ADS,
        );

        return stringWithAd;
      }

      return 'C';
    })
    .join('');
};

export default getWatchExperienceAdPositionMap;
