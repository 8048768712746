import cls from 'classnames';
import { FC, FormHTMLAttributes, useEffect, useRef } from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';

import formImage from '@/assets/form-images/form-image.svg';
import NormalButton from '@/components/button/normal';
import Image from '@/components/image';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';

import { ContactFormData } from '..';
import Email from './inputs/Email';
import Message from './inputs/Message';
import Station from './inputs/Station';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  register: UseFormRegister<ContactFormData>;
  errors: DeepMap<ContactFormData, FieldError>;
  submitFailed?: boolean;
  missingSubmitData?: DeepMap<ContactFormData, FieldError> | undefined;
  contactImageUrl?: string;
  overlineText: string;
  isSubmitting?: boolean;
  onSubmit: () => void;
  onClose?: () => void;
}

const Form: FC<FormProps> = ({
  register,
  errors,
  missingSubmitData,
  contactImageUrl,
  overlineText,
  isSubmitting,
  onSubmit,
  onClose,
}) => {
  const variables = useVariables();

  const title = getVariablePlainText(variables['contactform-title']);

  const ctaButtonText = getVariablePlainText(variables['contactform-cta-text']);
  const cancelButtonText = getVariablePlainText(
    variables['contactform-cancel'],
  );

  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);

  const stationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (stationRef.current) {
      stationRef.current.focus();
    }
  }, []);

  return (
    <form
      className={cls(
        'bg-black-light md:rounded-2 px-5 py-12 md:p-6 lg:p-12',
        'relative z-50 transition-zoomIn duration-200 transform',
        'box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.35)',
        'scale-100 opacity-100',
      )}
      onSubmit={onSubmit}
      noValidate
    >
      <div className="md:grid md:grid-cols-[0.65fr_0.35fr] place-items-left md:gap-10">
        <figure className="w-full h-[166px] mb-12 md:h-full md:order-2 md:mb-0">
          <Image
            className="w-full overflow-hidden"
            src={contactImageUrl ?? formImage.src}
            basicLoader
            objectFit="contain"
          />
        </figure>

        <div className="md:order-1 text-left">
          <p className="text-overline text-gray-3 mb-1.5">{overlineText}</p>
          <h3 className="text-h4 text-pink">{title}</h3>

          <Station register={register} errors={errors} ref={stationRef} />

          <Email
            register={register}
            errors={errors}
            submitFailed={!!missingSubmitData?.email}
          />

          <Message
            register={register}
            errors={errors}
            submitFailed={!!missingSubmitData?.message}
          />

          <div className="flex flex-wrap mt-6 items-start">
            <div className="flex flex-col md:flex-row md:items-center gap-6 w-full">
              <NormalButton
                type="submit"
                {...buttonProps}
                text={ctaButtonText}
                size="L"
                className="w-full md:w-auto"
              />
              <button
                type="button"
                onClick={onClose}
                className="w-full md:w-auto text-body2 text-gray-2 underline"
                disabled={isSubmitting}
              >
                {cancelButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
