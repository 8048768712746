import React, { FC, Fragment, useEffect, useState } from 'react';

export type AccordionItemClick = (slug: string) => void;

interface AccordionItem {
  slug: string;
  renderItem: (
    isActive: boolean,
    onItemClick: AccordionItemClick,
  ) => JSX.Element;
}

interface AccordionProps {
  hasHashNavigation?: boolean;
  itemsToRender: AccordionItem[];
}

const Accordion: FC<AccordionProps> = ({
  hasHashNavigation = true,
  itemsToRender,
}) => {
  const [activeItemSlug, setActiveItemSlug] = useState<string>('');

  useEffect(() => {
    if (hasHashNavigation) {
      setActiveItemSlug(window.location.hash.replace('#', ''));
    }
  }, [hasHashNavigation]);

  // We need to use window and history because router has side effect - jump to anchor.
  const onItemClick = (itemSlug: string) => {
    const { pathname } = window.location;

    if (activeItemSlug === itemSlug) {
      setActiveItemSlug('');

      if (hasHashNavigation) {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, '', pathname);
      }

      return;
    }

    if (hasHashNavigation) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', `${pathname}#${itemSlug}`);
    }

    setActiveItemSlug(itemSlug);
  };

  return (
    <>
      {itemsToRender.map((accordionItem) => (
        <Fragment key={accordionItem.slug}>
          {accordionItem.renderItem(
            accordionItem.slug === activeItemSlug,
            onItemClick,
          )}
        </Fragment>
      ))}
    </>
  );
};

export default Accordion;
