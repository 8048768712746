import cls from 'classnames';
import { FC, useEffect } from 'react';

import { PodcastEmbedSectionEntry } from '@/types/views/sections';

const PLAYER_SOURCE =
  'https://cdn.podigee.com/podcast-player/javascripts/podigee-podcast-player.js';

// TODO: Temporary solution with iframe because we need api access token to get data from podigee api
// https://energych.atlassian.net/browse/ECH-3426
const PodcastEmbed: FC<PodcastEmbedSectionEntry> = ({
  title,
  podcastSubdomain,
  deactivateAdSlots,
}) => {
  // We need to use this initialization, because updating state doesn't work (Podigee functionality)
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = PLAYER_SOURCE;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className={cls('content-box', deactivateAdSlots && 'ml-auto')}>
        {title && <h2 className="text-h2 text-pink mb-4 md:mb-6">{title}</h2>}

        <div
          className="podigee-podcast-player"
          data-configuration={`https://${podcastSubdomain}.podigee.io/embed?context=external`}
          // TODO: Src tag is necessary for podigee-podcast-player.js, details in next line
          // (this.elem.src || this.elem.getAttribute("src")).match(/(^.*\/)/)[0].replace(/javascripts\/$/, "").replace(/\/$/, "")
          // @ts-ignore src is required for podigee script
          src={PLAYER_SOURCE}
        />
      </div>
    </>
  );
};

export default PodcastEmbed;
