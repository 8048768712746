import React, { FC, useCallback, useMemo, useState } from 'react';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import RichTextRenderer from '@/components/rich-text-renderer';
import Teaser from '@/components/sections/teaser';
import fetchWithCatch from '@/lib/fetch-with-catch';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useVariables from '@/lib/hooks/use-variables';
import { Sessions as SessionsAPI } from '@/types/apis';
import {
  SessionsSectionEntry,
  TeaserSectionEntry,
} from '@/types/views/sections';

const Sessions: FC<SessionsSectionEntry> = ({
  title,
  teasers,
  paginationData: initialPaginationData,
  isHistory = false,
}) => {
  const initialTeasers = useMemo(() => teasers, [teasers]);
  const variables = useVariables();
  const { pageTheme } = usePageTheme();
  const buttonProps = getThemedButtonProps(pageTheme);
  const [paginationData, setPaginationData] = useState(initialPaginationData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadedTeasers, setLoadedTeasers] = useState<TeaserSectionEntry[]>([]);

  const onLoadMoreClick = useCallback(async () => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({
      page: (paginationData.currentPage + 1).toString(),
      perPage: paginationData.perPage.toString(),
      isHistory: isHistory.toString(),
    });

    const { data, error } = await fetchWithCatch<SessionsAPI>(() =>
      fetch(`/api/sessions?${queryParams}`),
    );

    if (error) {
      console.error(error);
    }

    if (data) {
      setLoadedTeasers((prevState) => [...prevState, ...data.teasers]);
      setPaginationData(data.paginationData);
    }

    setIsLoading(false);
  }, [paginationData.currentPage, paginationData.perPage, isHistory]);

  const renderTeasers = (teaser: TeaserSectionEntry) => (
    <div className="py-8 md:py-14" key={teaser.title}>
      <Teaser {...teaser} />
    </div>
  );

  return (
    <div>
      <h1 className="content-box text-h1">{title}</h1>

      {!teasers.length && (
        <div className="content-box mb-8 mt-4 md:mt-8 md:mb-20">
          <RichTextRenderer
            className="text-lead"
            body={variables['live-sessions-no-live-sessions-text']}
          />
        </div>
      )}

      {!!teasers.length && (
        <div className="md:my-6">
          {initialTeasers.map(renderTeasers)}
          {!!loadedTeasers.length && loadedTeasers.map(renderTeasers)}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center mb-4">
          <LoadingIcon className="h-12 w-12" />
        </div>
      )}

      {!paginationData.areAllItemsLoaded && !isLoading && (
        <div className="flex justify-center">
          <NormalButton
            {...buttonProps}
            text="Load more"
            onClick={onLoadMoreClick}
          />
        </div>
      )}
    </div>
  );
};

export default Sessions;
