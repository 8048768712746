import cls from 'classnames';
import { useRouter } from 'next/router';
import { FC, useReducer, useState } from 'react';
import SwiperCore from 'swiper';

import getCurrentPageShareUrl from '@/lib/get-current-page-share-url';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { EntryComponentProps } from '@/types/views/generic';
import { GallerySectionEntry } from '@/types/views/sections';

import MainSwiper from './main-swiper';
import SocialButtons from './social-buttons';
import ThumbnailsSwiper from './thumbnails-swiper';

const GallerySection: FC<EntryComponentProps<GallerySectionEntry>> = ({
  title,
  media,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [mainSwiper, setMainSwiper] = useState<SwiperCore>();
  const [thumbnailsSwiper, setThumbnailsSwiper] = useState<SwiperCore>();

  const [urlToShare, setUrlToShare] = useState(
    getCurrentPageShareUrl({
      includeHash: true,
    }),
  );

  const router = useRouter();
  const variables = useVariables();
  const isArticlePage = router.asPath.includes('/artikel');

  const updateShareUrl = (): void =>
    setUrlToShare(
      getCurrentPageShareUrl({
        includeHash: true,
      }),
    );

  const renderPagination = () => {
    const currentNumber = mainSwiper ? mainSwiper.activeIndex + 1 : '';
    const itemsAmount = mainSwiper?.slides.length;

    return (
      <span className="whitespace-nowrap text-body2 text-white tesn:text-esn-secondary-light teds:text-white tels:text-els-gold-light teair:text-gray-3">
        {currentNumber}{' '}
        {getVariablePlainText(variables['gallery-pagination-from'])}{' '}
        {itemsAmount}
      </span>
    );
  };

  return (
    <div
      className={cls(
        !isArticlePage && 'pt-8 md:pt-20',
        'flex flex-col gap-4  teair:bg-air-deepBlue',
      )}
    >
      <div className="content-box-centered">
        {!isArticlePage && (
          <h2 className="text-h1 mb-4 text-pink tesn:text-white teair:text-white tels:text-white">
            {title}
          </h2>
        )}

        <div className="flex flex-col gap-4 w-full md:w-5/6 mx-auto">
          {!isArticlePage && (
            <button
              className="ml-auto text-body2 font-semibold underline text-red-medium tesn:text-esn-primary teds:text-eds-pink tels:text-els-gold-light teair:text-air-lila"
              onClick={router.back}
            >
              {getVariablePlainText(variables['gallery-close-cta-text'])}
            </button>
          )}

          <div className="flex flex-col gap-4">
            <MainSwiper
              mainSwiper={mainSwiper}
              thumbnailsSwiper={thumbnailsSwiper}
              media={media}
              urlToShare={urlToShare}
              onSwiper={setMainSwiper}
              onHashSet={updateShareUrl}
              onActiveIndexChange={forceUpdate}
            />
          </div>
        </div>
      </div>

      <div className="w-screen md:content-box-centered">
        <div className="flex justify-between w-full md:w-5/6 mx-auto">
          <ThumbnailsSwiper
            media={media}
            mainSwiper={mainSwiper}
            thumbnailsSwiper={thumbnailsSwiper}
            onSwiper={setThumbnailsSwiper}
          />

          <div className="hidden md:block">{renderPagination()}</div>
        </div>
      </div>

      {!isArticlePage && (
        <div className="content-box-centered">
          <article className="flex md:hidden flex-col gap-2">
            <div className="ml-auto">
              <SocialButtons urlToShare={urlToShare} />
            </div>

            <span className="text-body1">
              {mainSwiper ? media[mainSwiper.activeIndex].description : null}
            </span>
          </article>
        </div>
      )}
    </div>
  );
};

export default GallerySection;
