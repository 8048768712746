import cls from 'classnames';
import { FC, ReactNode } from 'react';

import Chip, { ChipProps } from '@/components/chip';

interface FilterChipProps {
  id?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: (id?: string) => void;
}

const FilterChip: FC<FilterChipProps> = ({
  id,
  isActive = false,
  isDisabled = false,
  isSelected = false,
  children,
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
}) => {
  let variant: ChipProps['variant'] = 'default';

  if (isActive) {
    variant = 'light';
  } else if (isSelected) {
    variant = 'red';
  } else if (isDisabled) {
    variant = 'disabled';
  }

  return (
    <Chip
      id={id}
      disabled={isDisabled}
      className={cls(
        'whitespace-nowrap flex items-center rounded-full px-4 h-10 bg-black-light text-body2',
        className,
      )}
      variant={variant}
      onClick={() => onClick(id)}
    >
      {children}
    </Chip>
  );
};

export default FilterChip;
