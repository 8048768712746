import cls from 'classnames';
import { useEffect, useState } from 'react';

import CheckmarkIcon from '@/components/icons/checkmark-icon';
import Image from '@/components/image';
import useLotteryRegistration from '@/components/sections/lottery-header/box/use-lottery-registration';
import { goToProfile, login } from '@/lib/auth';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { ImageAsset } from '@/types/views/generic';

import ActVotingModal from '../act-voting-modal';

interface MusicAwardActNomineeProps {
  id: string;
  title: string;
  lotteryPool: number;
  image: ImageAsset;
  votingStart: string;
  votingEnd: string;
  votedOn: boolean;
  setVotedOn: (isVoted: boolean) => void;
  actVotedOn: string;
  setActVotedOn: (id: string) => void;
}

const MusicAwardActNominee = ({
  id,
  title,
  lotteryPool,
  image,
  votingStart,
  votingEnd,
  votedOn,
  setVotedOn,
  actVotedOn,
  setActVotedOn,
}: MusicAwardActNomineeProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const variables = useVariables();

  const isActVotedOn = actVotedOn === id;

  const { lotteryBoxState, participate } = useLotteryRegistration({
    webRaffleStart: votingStart,
    webRaffleEnd: votingEnd,
    poolId: lotteryPool,
    contentfulId: id,
  });

  useEffect(() => {
    if (lotteryBoxState === 'already_participated') {
      setVotedOn(true);
    }
  }, [setVotedOn, lotteryBoxState]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const onVoting = () => {
    participate();
    setVotedOn(true);
    setActVotedOn(id);
    closeModal();
  };

  const isClickable =
    (!votedOn && lotteryBoxState === 'ready_for_participation') ||
    lotteryBoxState === 'logged_out' ||
    lotteryBoxState === 'incomplete_profile';

  const handleClick = () => {
    if (!votedOn && lotteryBoxState === 'ready_for_participation') {
      openModal();
    }

    if (lotteryBoxState === 'logged_out') {
      login();
    }

    if (lotteryBoxState === 'incomplete_profile') {
      goToProfile();
    }

    return undefined;
  };

  return (
    <>
      <div
        className={cls(
          'relative w-full md:w-[300px] aspect-[4/3] md:h-[300px] rounded-4 select-none overflow-hidden shadow-[0_0_16px_0_rgba(0,0,0,0.25)] group',
          isClickable && 'cursor-pointer',
        )}
        onClick={handleClick}
      >
        <Image
          src={image.src}
          alt={title}
          basicLoader
          objectFit="cover"
          className="h-full w-full"
        />

        {(lotteryBoxState === 'already_participated' || isActVotedOn) && (
          <div className="absolute top-0 left-0 h-[calc(100%-64px)] md:h-[calc(100%-76px)] w-full flex justify-center items-center">
            <CheckmarkIcon className="w-[128px] md:w-auto h-[128px] md:h-full" />
          </div>
        )}

        {!votedOn && lotteryBoxState === 'ready_for_participation' && (
          <div className="h-full w-full group-hover:bg-[#dfbb7280] absolute top-0 left-0"></div>
        )}

        {lotteryBoxState === 'logged_out' && (
          <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center px-4 bg-[rgba(0,0,0,0.50)]">
            <span className="text-body1 text-center">
              {getVariablePlainText(variables['music-award-login-text']) ||
                'Um abzustimmen zu können, musst du dich einloggen'}
            </span>
          </div>
        )}

        {lotteryBoxState === 'incomplete_profile' && (
          <div className="h-full w-full absolute top-0 left-0">
            <div className="h-full w-full flex justify-center items-center px-4 bg-[rgba(0,0,0,0.50)]">
              <span className="text-body1 text-center">
                {getVariablePlainText(
                  variables['music-award-incomplete-profile-text'],
                ) || 'Bitte vervollständige dein Profil.'}
              </span>
            </div>
          </div>
        )}

        <div className="w-full h-[64px] md:h-[76px] absolute bottom-0 left-0 py-2 px-4 flex justify-center items-center bg-[rgba(0,0,0,0.75)]">
          <span className="text-h5 text-center">{title}</span>
        </div>
      </div>

      {isModalOpen && (
        <ActVotingModal
          act={title}
          closeModal={closeModal}
          onVoting={onVoting}
        />
      )}
    </>
  );
};

export default MusicAwardActNominee;
