import { css } from '@emotion/css';
import cls from 'classnames';
import React, { FC } from 'react';

import { PageTheme } from '@/types/views/generic';

const gradientClasses = 'absolute z-10 inset-y-0 pointer-events-none w-24';

const gradientColor: Partial<Record<PageTheme, string>> = {
  eair: '0, 3, 49',
};

const gradientBackground = (
  side: 'left' | 'right',
  color = '18, 17, 19',
) => css`
  background: linear-gradient(
    ${side === 'left' ? '90deg' : '270deg'},
    rgba(${color}, 0.95),
    20%,
    rgba(${color}, 0) 100%
  );
`;

interface GradientProps {
  side: 'left' | 'right';
  pageTheme?: PageTheme | null;
}

const Gradient: FC<GradientProps> = ({ side = 'left', pageTheme }) => {
  const color = pageTheme ? gradientColor[pageTheme] : '18, 17, 19';

  return side === 'left' ? (
    <div
      className={cls(
        'left-0',
        gradientClasses,
        gradientBackground('left', color),
      )}
    />
  ) : (
    <div
      className={cls(
        'right-0',
        gradientClasses,
        gradientBackground('right', color),
      )}
    />
  );
};

export default Gradient;
