import { FC } from 'react';

interface NaviBackIconProps {
  className?: string;
}

const NaviBackIcon: FC<NaviBackIconProps> = ({ className }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <ellipse
      cx="16"
      cy="16"
      rx="16"
      ry="16"
      transform="rotate(90 16 16)"
      fill="white"
    />
    <path
      d="M22.2218 16.2777C22.2218 16.5539 21.9979 16.7777 21.7218 16.7777L12.7562 16.7777L16.7492 20.7707C16.945 20.9664 16.9444 21.284 16.748 21.4791L16.3531 21.8711C16.1577 22.0652 15.842 22.0646 15.6473 21.8699L10.1309 16.3535C9.93564 16.1582 9.93564 15.8417 10.1309 15.6464L15.646 10.1313C15.8413 9.93601 16.1579 9.93601 16.3531 10.1313L16.743 10.5212C16.9381 10.7163 16.9383 11.0326 16.7433 11.2279L12.7562 15.2222L21.7218 15.2222C21.9979 15.2222 22.2218 15.446 22.2218 15.7222L22.2218 16.2777Z"
      fill="#000331"
    />
  </svg>
);

export default NaviBackIcon;
