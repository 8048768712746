import { isAfter, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EnergyAddress, UserProfileResponse } from '@/api/types';
import { getStatus, participate } from '@/lib/lottery';
import {
  selectIsUserLoading,
  selectIsUserLoggedIn,
  selectUserProfile,
} from '@/store/slices/user';

export type UserLotteryStatus =
  | 'ready_for_participation'
  | 'won'
  | 'took_participation'
  | 'already_participated'
  | 'error'
  | 'loading';

export type LotteryBoxState =
  | UserLotteryStatus
  | 'logged_out'
  | 'incomplete_profile'
  | 'inactive';

const isRaffleActive = (start?: string, end?: string) => {
  if (!start || !end) {
    return false;
  }

  const now = Date.now();

  return isAfter(now, new Date(start)) && isBefore(now, new Date(end));
};

const isProfileComplete = (userProfile: UserProfileResponse | null) => {
  if (!userProfile) {
    return false;
  }

  const profileRequiredFields: (keyof UserProfileResponse)[] = [
    'email',
    'firstName',
    'lastName',
    'mobileNumber',
    'birthdate',
    'gender',
    'energyAddress',
  ];

  if (profileRequiredFields.some((fieldName) => !userProfile[fieldName])) {
    return false;
  }

  const addressRequiredFields: (keyof EnergyAddress)[] = [
    'zipCode',
    'city',
    'addressLine1',
  ];

  return addressRequiredFields.every(
    (fieldName) => !!userProfile.energyAddress![fieldName],
  );
};

const LOTTERY_TYPE = 'event';

interface UseLotteryRegistrationArgs {
  webRaffleStart?: string;
  webRaffleEnd?: string;
  poolId: number;
  contentfulId: string;
}

const useLotteryRegistration = ({
  webRaffleStart,
  webRaffleEnd,
  poolId,
  contentfulId,
}: UseLotteryRegistrationArgs) => {
  const [userLotteryStatus, setUserLotteryStatus] =
    useState<UserLotteryStatus | null>(null);

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const userProfile = useSelector(selectUserProfile);
  const isUserLoading = useSelector(selectIsUserLoading);

  useEffect(() => {
    const fetchData = async () => {
      if (isUserLoggedIn) {
        setUserLotteryStatus('loading');

        const data = await getStatus(LOTTERY_TYPE, poolId, contentfulId);

        if (!data) {
          setUserLotteryStatus('error');
        } else if (data.has_won) {
          setUserLotteryStatus('won');
        } else if (data.has_participated) {
          setUserLotteryStatus('already_participated');
        } else if (!data.has_participated && !data.has_won) {
          setUserLotteryStatus('ready_for_participation');
        } else {
          setUserLotteryStatus('error');
        }
      }
    };

    fetchData();
  }, [isUserLoggedIn, contentfulId, poolId]);

  const handleParticipation = async () => {
    if (userLotteryStatus !== 'ready_for_participation') {
      return;
    }

    setUserLotteryStatus('loading');

    const data = await participate(
      LOTTERY_TYPE,
      poolId,
      userProfile?.sub,
      contentfulId,
    );

    if (data?.message && !data?.errorName) {
      setUserLotteryStatus('already_participated');
    } else {
      setUserLotteryStatus('error');
    }
  };

  const getLotteryBoxState = (): LotteryBoxState | null => {
    if (isUserLoading) {
      return 'loading';
    }

    if (!isRaffleActive(webRaffleStart, webRaffleEnd)) {
      return 'inactive';
    }

    if (!isUserLoggedIn) {
      return 'logged_out';
    }

    if (!isProfileComplete(userProfile)) {
      return 'incomplete_profile';
    }

    return userLotteryStatus;
  };

  return {
    lotteryBoxState: getLotteryBoxState(),
    participate: handleParticipation,
  };
};

export default useLotteryRegistration;
