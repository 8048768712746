import cls from 'classnames';

import { BoxType } from '@/types/cms';

const gridClassesByBoxType: Partial<Record<BoxType, string>> = {
  [BoxType.Small]: 'md:grid-cols-2 xl:grid-cols-3',
  [BoxType.Contact]: 'md:grid-cols-2 xl:grid-cols-3',
  [BoxType.Medium]: 'md:grid-cols-2',
};

const styles = {
  wrapper: (type: BoxType) =>
    cls('grid grid-rows-auto gap-12 md:gap-4', gridClassesByBoxType[type]),
};

export default styles;
