import cls from 'classnames';
import React, { FC } from 'react';

import ImageWithText from '@/components/image-with-text';
import { GridEntry } from '@/types/views/sections';

import styles from './styles';

interface GridProps extends GridEntry {
  dynamicColumnsCountScaling?: boolean; // will automatically change column count for tablets as well
}

const Grid: FC<GridProps> = ({
  title,
  type,
  items = [],
  numberOfColumns,
  dynamicColumnsCountScaling = true,
  deactivateAdSlots,
}) => (
  <div
    className={cls(
      'content-box teair:pt-14 tesn:pt-14 tels:pt-14',
      deactivateAdSlots && 'ml-auto',
    )}
  >
    {title && <h1 className={styles.title}>{title}</h1>}

    <div
      className={styles.container(numberOfColumns, dynamicColumnsCountScaling)}
    >
      {items.map((item, index) => (
        <ImageWithText key={`${item.id}-${index}`} type={type} {...item} />
      ))}
    </div>
  </div>
);

export default Grid;
