import cls from 'classnames';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import { JobEntryCms } from '@/types/cms';

import styles from './styles';

type TileProps = JobEntryCms & { className: string };

const Tile: FC<TileProps> = ({
  title,
  pensum,
  department,
  location,
  url,
  className,
}) => (
  <CustomLink href={url} isExternal className={cls(styles.wrapper, className)}>
    <div className={styles.department}>{department}</div>
    <div className={styles.title}>{title}</div>
    <div className={styles.location}>{`${pensum} ${location}`}</div>
  </CustomLink>
);

export default Tile;
