import React, { FC } from 'react';

import Image from '@/components/image';
import { BackgroundImage } from '@/types/views/generic';

import styles from './styles';

const PageBackgroundImage: FC<BackgroundImage> = ({ desktop, mobile }) => (
  <div className={styles.container}>
    {mobile && (
      <div className={styles.wrapperMobile}>
        <Image
          src={mobile.src}
          alt={mobile.description}
          objectFit="cover"
          className={styles.image}
        />
        <div className={styles.gradient} />
      </div>
    )}
    {desktop && (
      <div className={styles.wrapper}>
        <Image
          src={desktop.src}
          alt={desktop.description}
          objectFit="cover"
          className={styles.image}
        />
        <div className={styles.gradient} />
      </div>
    )}
  </div>
);

export default PageBackgroundImage;
