import { css } from '@emotion/css';
import { screens } from 'tailwind/theme';

import { ScreenSize } from '@/types/views/generic';

const navbarHeightByScreen: Partial<Record<ScreenSize, number>> = {
  xs: 56,
  md: 78,
  xl: 86,
};

const heroHeight = (marginBottomInRem: number) => css`
  height: calc(100vh - ${navbarHeightByScreen.xs}px);

  ${Object.entries(navbarHeightByScreen)
    .filter(([screen]) => screen !== 'xs')
    .map(
      ([screen, navbarHeight]) => `
    @media (min-width: ${screens[screen]}) {
      height: calc(100vh - ${navbarHeight}px - ${marginBottomInRem}rem);
    }
  `,
    )}
`;

export default heroHeight;
