import cls from 'classnames';
import { FC } from 'react';

import TitleLink from '@/components/title-link';
import { ArticleTeaserContainerSectionEntry } from '@/types/views/sections';

import ArticleTeaser from './components/article-teaser';
import styles from './styles';

const ArticleTeaserContainer: FC<ArticleTeaserContainerSectionEntry> = ({
  title,
  articles,
  link,
  deactivateAdSlots,
}) => (
  <div className={cls(styles.contentWrapper, deactivateAdSlots && 'ml-auto')}>
    {title && (
      <div className={styles.header}>
        <h2 className={styles.titleWrapper}>{title}</h2>
        {link && <TitleLink className="md:ml-4" link={link} />}
      </div>
    )}

    <div className={styles.articlesWrapper}>
      {articles.map((article) => (
        <ArticleTeaser key={article.id} {...article} />
      ))}
    </div>
  </div>
);

export default ArticleTeaserContainer;
