import * as R from 'ramda';
import { KeyValuePair } from 'ramda';

import { ScreenSizeNames } from '@/../tailwind/theme';
import {
  ContentFeedEntryCmsEntryType,
  ContentFeedEntryCmsEntryTypeGroup,
} from '@/types/cms';
import { TeaserGroup } from '@/types/views/generic';

export const entryTypesByGroup: Record<
  ContentFeedEntryCmsEntryTypeGroup,
  ContentFeedEntryCmsEntryType[]
> = {
  [ContentFeedEntryCmsEntryTypeGroup.Creators]: ['profile'],
  [ContentFeedEntryCmsEntryTypeGroup.Categories]: ['category'],
  [ContentFeedEntryCmsEntryTypeGroup.Memes]: ['meme'],
  [ContentFeedEntryCmsEntryTypeGroup.Stations]: ['station'],
  [ContentFeedEntryCmsEntryTypeGroup.Videos]: ['video'],
  [ContentFeedEntryCmsEntryTypeGroup.Channels]: ['channel'],
  [ContentFeedEntryCmsEntryTypeGroup.Podcasts]: ['podcast'],
  [ContentFeedEntryCmsEntryTypeGroup.Soundpieces]: ['soundpiece'],
  [ContentFeedEntryCmsEntryTypeGroup.ShowsFormatsProfiles]: [
    'show',
    'format',
    'profile',
  ],
  [ContentFeedEntryCmsEntryTypeGroup.ChannelsPodcasts]: ['channel', 'podcast'],
};

/**
 * For input: [1, 3, 3, 3, 4, 4]
 * Creates object of such form: {
 *  [ScreenSizeNames.Small]: 1,
 *  [ScreenSizeNames.Medium]: 3,
 *  [ScreenSizeNames.Large]: 3,
 *  [ScreenSizeNames.ExtraLarge]: 3,
 *  [ScreenSizeNames.DoubleExtraLarge]: 4,
 *  [ScreenSizeNames.TripleExtraLarge]: 4,
 * }
 */
const getSizesMap = (noOfCols: number[]): Record<string, number> =>
  R.fromPairs(
    R.transpose<string | number>([
      R.values(ScreenSizeNames),
      noOfCols,
    ]) as KeyValuePair<string, number>[],
  );

/**
 * Should match frontend
 * @see @/components/sections/content-feed/components/grid/styles.ts
 * @see @/components/sections/content-feed/components/carousel/styles.ts
 */
export const colsPerTeaserGroup: Record<TeaserGroup, Record<string, number>> = {
  [TeaserGroup.Default]: getSizesMap([1, 3, 3, 3, 4, 4]),
  [TeaserGroup.Categories]: getSizesMap([3, 5, 5, 8, 8, 8]),
  [TeaserGroup.Memes]: getSizesMap([2, 3, 3, 3, 3, 3]),
  [TeaserGroup.PodcastsCreatorsChannels]: getSizesMap([2, 3, 3, 3, 5, 5]),
  [TeaserGroup.Videos]: getSizesMap([2, 3, 3, 3, 4, 4]),
  [TeaserGroup.Stations]: getSizesMap([2, 3, 3, 3, 5, 5]),
  [TeaserGroup.Prizes]: getSizesMap([1, 3, 3, 3, 4, 4]),
  [TeaserGroup.Contact]: getSizesMap([2, 3, 3, 3, 5, 5]),
};

export const entryTypeGroupToTeaserGroup = (
  entryTypeGroup: ContentFeedEntryCmsEntryTypeGroup,
): TeaserGroup => {
  switch (entryTypeGroup) {
    case ContentFeedEntryCmsEntryTypeGroup.Creators:
    case ContentFeedEntryCmsEntryTypeGroup.Channels:
    case ContentFeedEntryCmsEntryTypeGroup.Podcasts:
      return TeaserGroup.PodcastsCreatorsChannels;

    case ContentFeedEntryCmsEntryTypeGroup.Memes:
      return TeaserGroup.Memes;

    case ContentFeedEntryCmsEntryTypeGroup.Categories:
      return TeaserGroup.Categories;

    case ContentFeedEntryCmsEntryTypeGroup.Videos:
      return TeaserGroup.Videos;

    case ContentFeedEntryCmsEntryTypeGroup.Stations:
      return TeaserGroup.Stations;

    default:
      return TeaserGroup.Default;
  }
};

const TAGGING_TOOL_TYPES: ContentFeedEntryCmsEntryType[] = [
  'video',
  'meme',
  'soundpiece',
];

const CMS_TYPES: ContentFeedEntryCmsEntryType[] = [
  'category',
  'station',
  'podcast',
  'show',
  'format',
  'profile',
];

export const isTaggingToolEntryType = (
  entryType: ContentFeedEntryCmsEntryType,
) => TAGGING_TOOL_TYPES.includes(entryType);

export const isCmsEntryType = (entryType: ContentFeedEntryCmsEntryType) =>
  CMS_TYPES.includes(entryType);
