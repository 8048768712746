import { css } from '@emotion/css';
import { screens } from 'tailwind/theme';

// Calculation of proportions according to the design
const nearestSlideDesktopScale = parseFloat((150 / 343).toFixed(2));
const nearestSlideMobileScale = parseFloat((100 / 180).toFixed(2));
const edgeSlideScale = parseFloat((120 / 343).toFixed(2));
const borderRadius = 8;
const scale3dNearestDesktop = `scale3d(${nearestSlideDesktopScale}, ${nearestSlideDesktopScale}, ${nearestSlideDesktopScale})`;
const scale3dNearestMobile = `scale3d(${nearestSlideMobileScale}, ${nearestSlideMobileScale}, ${nearestSlideMobileScale})`;
const scale3dEdge = `scale3d(${edgeSlideScale}, ${edgeSlideScale}, ${edgeSlideScale})`;
const rescaledBorderRadius = `${Math.round(borderRadius / edgeSlideScale)}px`;
const rescaledBorderRadiusMobile = `${Math.round(
  borderRadius / nearestSlideMobileScale,
)}px`;

// Custom styles
export const bgStyles = (bgImageUrl?: string) => css`
  height: 375px;
  background: linear-gradient(180deg, rgba(18, 17, 19, 0.22) 0%, #121113 100%),
    ${bgImageUrl
      ? `url(${bgImageUrl}) center no-repeat`
      : 'linear-gradient(180deg, #EFAAE8 0%, #FFFFFF 100%)'};
  ${bgImageUrl ? 'background-size: cover;' : ''}
`;

const commonPseudoElStyles = css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.8;
`;

export const carouselWrapper = css`
  .swiper-wrapper {
    align-items: center;
  }

  &.swiper-container-initialized .swiper-slide {
    transition: transform 0.2s linear;
  }

  .swiper-slide {
    width: 48% !important;
    transform: ${scale3dNearestMobile};
  }

  .swiper-slide.swiper-slide-prev,
  .swiper-slide.swiper-slide-next {
    &::after {
      width: 90%;
      ${commonPseudoElStyles}
    }
  }

  .swiper-slide.swiper-slide-prev {
    transform: ${scale3dNearestMobile} translate3d(10%, 0, 0);

    &::after {
      background: linear-gradient(90deg, #121113 0%, rgba(18, 17, 19, 0) 100%);
      border-top-left-radius: ${rescaledBorderRadiusMobile};
      border-bottom-left-radius: ${rescaledBorderRadiusMobile};
    }
  }

  .swiper-slide.swiper-slide-next {
    transform: ${scale3dNearestMobile} translate3d(-10%, 0, 0);

    &::after {
      background: linear-gradient(270deg, #121113 0%, rgba(18, 17, 19, 0) 100%);
      border-top-right-radius: ${rescaledBorderRadiusMobile};
      border-bottom-right-radius: ${rescaledBorderRadiusMobile};
      right: 0;
    }
  }

  .swiper-slide.swiper-slide-prev,
  .swiper-slide.swiper-slide-next {
    .channel-wrapper {
      border-radius: ${rescaledBorderRadiusMobile};
    }
  }

  .swiper-slide.swiper-slide-active {
    transform: scale3d(1, 1, 1);
  }

  @media (min-width: ${screens.md}) {
    .swiper-slide {
      width: 30% !important;
      transform: ${scale3dEdge};
      cursor: pointer;
    }

    .swiper-slide:not(.swiper-slide-visible) {
      opacity: 0;
    }

    .swiper-slide:not(.swiper-slide-next) + .swiper-slide-visible,
    .swiper-slide.swiper-slide-next + .swiper-slide-visible {
      &::after {
        width: 58.3%;
        ${commonPseudoElStyles}
      }
    }

    .swiper-slide:not(.swiper-slide-next) + .swiper-slide-visible {
      transform: ${scale3dEdge} translate3d(146%, 0, 0);

      &::after {
        background: linear-gradient(
          90deg,
          #121113 0%,
          rgba(18, 17, 19, 0) 100%
        );
        border-top-left-radius: ${rescaledBorderRadius};
        border-bottom-left-radius: ${rescaledBorderRadius};
      }
    }

    .swiper-slide + .swiper-slide.swiper-slide-prev,
    .swiper-slide.swiper-slide-prev + .swiper-slide.swiper-slide-active,
    .swiper-slide.swiper-slide-active + .swiper-slide.swiper-slide-next {
      &::after {
        content: none;
      }
    }

    .swiper-slide + .swiper-slide.swiper-slide-prev {
      transform: ${scale3dNearestDesktop} translate3d(25%, 0, 0);
    }

    .swiper-slide.swiper-slide-prev + .swiper-slide.swiper-slide-active {
      transform: scale3d(1, 1, 1);

      .channel-wrapper {
        border-radius: ${borderRadius}px;
      }
    }

    .swiper-slide.swiper-slide-active + .swiper-slide.swiper-slide-next {
      transform: ${scale3dNearestDesktop} translate3d(-25%, 0, 0);
    }

    .swiper-slide.swiper-slide-next + .swiper-slide-visible {
      transform: ${scale3dEdge} translate3d(-146%, 0, 0);

      &::after {
        background: linear-gradient(
          270deg,
          #121113 0%,
          rgba(18, 17, 19, 0) 100%
        );
        border-top-right-radius: ${rescaledBorderRadius};
        border-bottom-right-radius: ${rescaledBorderRadius};
        right: 0;
      }
    }

    .swiper-slide:not(.swiper-slide-next) + .swiper-slide-visible,
    .swiper-slide.swiper-slide-next + .swiper-slide-visible {
      .channel-wrapper {
        border-radius: ${rescaledBorderRadius};
      }
    }

    .swiper-slide + .swiper-slide.swiper-slide-prev,
    .swiper-slide.swiper-slide-active + .swiper-slide.swiper-slide-next {
      .channel-wrapper {
        border-radius: ${Math.round(borderRadius / nearestSlideDesktopScale)}px;
      }

      .channel-name {
        font-size: ${(0.875 / nearestSlideDesktopScale).toFixed(2)}rem;
        line-height: ${(1.125 / nearestSlideDesktopScale).toFixed(2)}rem;
        margin-top: ${Math.round(16 / nearestSlideDesktopScale)}px;
      }
    }
  }
`;

export const navButtonClasses =
  'absolute z-20 top-1/2 transform -translate-y-1/2';
