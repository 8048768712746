import { FC, InputHTMLAttributes } from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

import Input from '@/components/form-elements/input';
import { EMAIL_REGEX_PATTERN } from '@/lib/constants';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

import { ContactFormData } from '../..';

interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<ContactFormData>;
  errors: DeepMap<ContactFormData, FieldError>;
  submitFailed?: boolean;
}

const Email: FC<EmailInputProps> = ({ register, errors, submitFailed }) => {
  const variables = useVariables();

  const label = getVariablePlainText(variables['contactform-email-label']);
  const placeholder = getVariablePlainText(
    variables['contactform-email-placeholder'],
  );
  const errorText =
    getVariablePlainText(variables['contactform-email-error']) ||
    'Bitte gib eine E-Mail-Adresse ein';
  const invalidEmail =
    getVariablePlainText(variables['contactform-invalid-email']) ||
    'Bitte gib eine gültige Email-Adresse ein';

  return (
    <div className="flex flex-wrap mt-6 items-start">
      <Input
        {...register('email', {
          required: errorText,
          pattern: {
            value: EMAIL_REGEX_PATTERN,
            message: invalidEmail,
          },
        })}
        id="email"
        label={label || ''}
        showLabel
        type="email"
        placeholder={placeholder || ''}
        containerClassName="flex flex-col md:mr-4"
        error={errors?.email?.message}
        submitFailed={submitFailed}
      />
    </div>
  );
};

export default Email;
