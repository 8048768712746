import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { Filter } from '@/types/views/generic';

import { IsFilterSelectedById } from '.';

const useFilters = (multiselect = false) => {
  const [isFilterSelectedById, setIsFilterSelectedById] =
    useState<IsFilterSelectedById>({});

  const router = useRouter();

  const defaultUrl = router.query.slug
    ? router.route.replace('[slug]', router.query.slug as string)
    : router.route.replace(
        '[[...page-slugs]]',
        (router.query['page-slugs'] as string[]).join('/'),
      );

  const isAllButtonActive = Object.values(isFilterSelectedById).every(
    (isSelected) => !isSelected,
  );

  const selectedFilterIds = useMemo(
    () =>
      Object.entries(isFilterSelectedById)
        .filter(([_, isSelected]) => isSelected)
        .map(([id]) => id),
    [isFilterSelectedById],
  );

  const resetSelectedFilters = () => {
    const resetMap = Object.keys(isFilterSelectedById).reduce((acc, id) => {
      acc[id] = false;

      return acc;
    }, {} as IsFilterSelectedById);

    setIsFilterSelectedById(resetMap);

    router.replace(defaultUrl, undefined, { shallow: true });
  };

  const toggleFilterSelection = (filter: Filter) => {
    const { id } = filter;
    const isSelected = isFilterSelectedById[id];

    const selectedFilters = multiselect
      ? { ...isFilterSelectedById, [id]: !isSelected }
      : { [id]: !isSelected };

    const selectedFiltersForQueryParams = Object.entries(selectedFilters)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .toString();

    const hasSelectedFilters = !!selectedFiltersForQueryParams.length;

    const queryParams = new URLSearchParams({
      filters: selectedFiltersForQueryParams,
    });

    router.replace(
      `${defaultUrl}${hasSelectedFilters ? `?${queryParams}` : ''}`,
      undefined,
      { shallow: true },
    );

    setIsFilterSelectedById(selectedFilters);
  };

  useEffect(() => {
    const queryFilters = router.query.filters as string;

    if (queryFilters) {
      const splittedFilters = queryFilters.split(',');
      const filters: Record<string, boolean> = {};

      splittedFilters.forEach((filter) => {
        filters[filter] = true;
      });

      setIsFilterSelectedById(filters);
    }
  }, [router.query.filters]);

  return {
    isFilterSelectedById,
    isAllButtonActive,
    selectedFilterIds,
    resetSelectedFilters,
    toggleFilterSelection,
  };
};

export default useFilters;
