import cls from 'classnames';
import { useEffect, useRef, useState } from 'react';

import CircleIcon from '@/components/icons/quiz/circle-icon';
import CurvyIcon from '@/components/icons/quiz/curvy-icon';
import GridIcon from '@/components/icons/quiz/grid-icon';
import QuestionMarkIcon from '@/components/icons/quiz/question-mark-icon';
import TriangleIcon from '@/components/icons/quiz/triangle-icon';

const ANIMATION_TIMEOUT = 4000;

const commonSymbolStyles = 'symbol text-white absolute block z-1';
const commonSymbolShadowStyles = 'text-game-orange absolute block';

interface QuizBackgroundProps {
  hasWon?: boolean;
}

const QuizBackground = ({ hasWon = false }: QuizBackgroundProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const leftSymbolStyles = cls(
    'animate-floating-left-element',
    commonSymbolStyles,
    shouldAnimate && 'animate-pulse-left',
  );
  const leftShadowStyles = cls(
    commonSymbolShadowStyles,
    shouldAnimate && 'animate-pulse-left-shadow',
  );
  const rightSymbolStyles = cls(
    'animate-floating-right-element',
    commonSymbolStyles,
    shouldAnimate && 'animate-pulse-right',
  );
  const rightShadowStyles = cls(
    commonSymbolShadowStyles,
    shouldAnimate && 'animate-pulse-right-shadow',
  );

  useEffect(() => {
    if (hasWon) {
      const symbols = wrapperRef.current?.querySelectorAll('.symbol') || [];

      symbols.forEach((symbol) => {
        const matrix = window
          .getComputedStyle(symbol)
          .getPropertyValue('transform');
        symbol.setAttribute('style', `transform: ${matrix}`);
      });

      setShouldAnimate(true);
    }

    const timeout = setTimeout(() => {
      setShouldAnimate(false);
    }, ANIMATION_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [hasWon, wrapperRef]);

  return (
    <div className="absolute z-1 inset-0" ref={wrapperRef}>
      {/* LEFT */}
      <div
        className={cls(
          'hidden md:block absolute h-[152px] w-[152px] transform rotate-[-23deg]',
          'top-[24rem] md:top-[20rem] lg:top-[20rem] xl:top-[24rem] left-[16rem] md:left-[-1rem] lg:left-[-1rem] 2xl:left-[-2rem]',
        )}
      >
        <TriangleIcon className={leftSymbolStyles} />
        <TriangleIcon className={leftShadowStyles} />
      </div>

      <div className="absolute top-[9rem] md:top-[38rem] left-[0.5rem] md:left-[12rem] lg:left-[11rem] 2xl:left-[11rem] transform rotate-[-30deg] w-[36px] h-[58px] 2xl:w-[55px] 2xl:h-[88px]">
        <QuestionMarkIcon className={leftSymbolStyles} />
        <QuestionMarkIcon className={leftShadowStyles} />
      </div>

      <div className="hidden md:block absolute top-[3rem] md:top-[2rem] lg:top-[1rem] left-[12rem] md:left-[-1.5rem] lg:left-[-1rem] xl:left-[1rem] 2xl:left-[-7rem] transform rotate-[15deg] h-[15rem] w-[15rem]">
        <GridIcon className={leftSymbolStyles} />
        <GridIcon className={leftShadowStyles} />
      </div>

      <div
        className={cls(
          'transform rotate-[-74deg] w-[43px] h-[114px] 2xl:w-[58px] 2xl:h-[158px]',
          'block md:hidden lg:block absolute top-[35rem] left-[2rem] md:left-[-10rem]',
        )}
      >
        <CurvyIcon className={leftSymbolStyles} />
        <CurvyIcon className={leftShadowStyles} />
      </div>

      {/* RIGHT */}
      <div className="hidden md:block absolute top-[17rem] md:top-[18rem] lg:top-[15rem] 2xl:top-[15rem] right-[15rem] md:right-[3rem] lg:right-[-5rem] xl:right-[3rem] 2xl:right-[4rem] transform rotate-[20deg] w-[36px] h-[58px] 2xl:w-[55px] 2xl:h-[88px]">
        <QuestionMarkIcon className={rightSymbolStyles} />
        <QuestionMarkIcon className={rightShadowStyles} />
      </div>

      <div className="absolute top-[0.5rem] md:top-[4rem] lg:top-[0rem] 2xl:top-[0rem] right-[-0.5rem] md:right-[8rem] lg:right-[4rem] 2xl:right-[-1rem] transform rotate-[39deg] w-[43px] h-[114px] 2xl:w-[58px] 2xl:h-[158px]">
        <CurvyIcon className={rightSymbolStyles} />
        <CurvyIcon className={rightShadowStyles} />
      </div>

      <div className="absolute top-[34rem] md:top-[28rem] lg:top-[30rem] xl:top-[28rem] right-[-3rem] md:right-[-4rem]  lg:right-[-6rem] 2xl:right-[-8rem] w-[178px] h-[178px] 2xl:w-[314px] 2xl:h-[314px] transform rotate-[45deg]">
        <CircleIcon className={rightSymbolStyles} />
        <CircleIcon className={rightShadowStyles} />
      </div>
    </div>
  );
};

export default QuizBackground;
