import cls from 'classnames';
import React, { FC, useReducer } from 'react';
import { useSelector } from 'react-redux';
import SwiperCore, { A11y, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarouselNavButton from '@/components/carousel-nav-button';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { MediaAsset } from '@/types/views/generic';

import Gradient from './gradient';
import ThumbailsSwiperSlide from './thumbails-swiper-slide';

SwiperCore.use([Navigation, A11y, Controller]);

const THUMBNAILS_CAROUSEL_NAV_PREV_BUTTON_ID =
  'gallery-thumnails-carousel-navigation-prev-button';

const THUMBNAILS_CAROUSEL_NAV_NEXT_BUTTON_ID =
  'gallery-thumnails-carousel-navigation-next-button';

const navButtonClasses = 'absolute z-20 top-1/2 transform -translate-y-1/2';

const renderPrevButton = () => (
  <CarouselNavButton
    id={THUMBNAILS_CAROUSEL_NAV_PREV_BUTTON_ID}
    type="prev"
    mdSize="small"
    className={cls('left-0 -translate-x-1/2', navButtonClasses)}
  />
);

const renderNextButton = () => (
  <CarouselNavButton
    id={THUMBNAILS_CAROUSEL_NAV_NEXT_BUTTON_ID}
    type="next"
    mdSize="small"
    className={cls(
      'right-0 -translate-y-1/2 translate-x-1/2',
      navButtonClasses,
    )}
  />
);

interface ThumbnailsSwiperProps {
  mainSwiper?: SwiperCore;
  thumbnailsSwiper?: SwiperCore;
  media: MediaAsset[];
  className?: string;
  onSwiper: Swiper['onSwiper'];
}

const ThumbnailsSwiper: FC<ThumbnailsSwiperProps> = ({
  media,
  mainSwiper,
  thumbnailsSwiper,
  className,
  onSwiper,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const pageTheme = useSelector(selectGlobalPageTheme);

  const mainSwiperActiveIndex = mainSwiper?.activeIndex;

  const renderMobileAdditions = () => (
    <div className="block md:hidden">
      {!thumbnailsSwiper?.isBeginning && <Gradient side="left" />}
      {!thumbnailsSwiper?.isEnd && <Gradient side="right" />}
    </div>
  );

  const renderTabletAdditions = () => (
    <div className="hidden md:block">
      <div className={!thumbnailsSwiper?.isBeginning ? 'block' : 'hidden'}>
        {renderPrevButton()}

        <Gradient side="left" pageTheme={pageTheme} />
      </div>

      <div className={!thumbnailsSwiper?.isEnd ? 'block' : 'hidden'}>
        {renderNextButton()}

        <Gradient side="right" pageTheme={pageTheme} />
      </div>
    </div>
  );

  return (
    <div className={cls('relative w-full md:w-4/5', className)}>
      <div className="relative">
        <Swiper
          wrapperTag="ul"
          slidesPerView="auto"
          spaceBetween={8}
          freeMode
          className="px-5 md:px-0"
          navigation={{
            prevEl: `#${THUMBNAILS_CAROUSEL_NAV_PREV_BUTTON_ID}`,
            nextEl: `#${THUMBNAILS_CAROUSEL_NAV_NEXT_BUTTON_ID}`,
          }}
          onSwiper={onSwiper}
          onSlideChange={forceUpdate}
        >
          {media.map((mediaItem, index) => (
            <SwiperSlide
              tag="li"
              className="w-28 md:w-52"
              onClick={() => mainSwiper?.slideTo(index)}
              key={index}
            >
              <ThumbailsSwiperSlide
                mediaItem={mediaItem}
                isActive={mainSwiperActiveIndex === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {renderTabletAdditions()}
      </div>

      {renderMobileAdditions()}
    </div>
  );
};

export default ThumbnailsSwiper;
