const styles = {
  wrapper: 'content-box',
  headerWrapper:
    'flex flex-col md:flex-row items-start md:items-center md:mb-5',
  header: 'text-h2 text-pink mb-4 md:mb-0',
  filtersWrapper: 'mb-4 md:mb-0 md:ml-auto',
  tilesWrapper: 'grid grid-cols-1 md:grid-cols-3 gap-4',
};

export default styles;
