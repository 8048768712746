import { FC, MouseEventHandler, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import LoadingIcon from '@/components/icons/loading-icon';
import Share from '@/components/share';
import { login } from '@/lib/auth';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { PageTheme } from '@/types/views/generic';
import { LotteryHeaderSectionEntry } from '@/types/views/sections';

import LotteryBoxFooter from './footer';
import LotteryBoxMainContent from './main-content';
import useLotteryRegistration from './use-lottery-registration';

type LotteryRegistrationBoxProps = Pick<
  LotteryHeaderSectionEntry,
  | 'id'
  | 'poolId'
  | 'webRaffleStart'
  | 'webRaffleEnd'
  | 'raffleEndUrl'
  | 'raffleEndLink'
  | 'textData'
  | 'profileEndpointLink'
>;

const renderNormalButton = (
  text: ReactNode,
  pageTheme: PageTheme | null,
  handleClick?: MouseEventHandler,
) => {
  const buttonProps = getThemedButtonProps(pageTheme, {
    eair: 'coral',
  });

  return <NormalButton {...buttonProps} text={text} onClick={handleClick} />;
};

const renderShareButton = (text: string) => (
  <Share
    renderButton={(togglePopover) => (
      <button
        className="text-body1 underline font-semibold leading-none text-black tesn:text-esn-secondary-dark teair:text-air-deepBlue"
        onClick={togglePopover}
      >
        {text}
      </button>
    )}
    urlToShare={typeof window !== 'undefined' ? window.location.href : ''}
  />
);

const LotteryRegistrationBox: FC<LotteryRegistrationBoxProps> = ({
  id,
  poolId,
  webRaffleStart,
  webRaffleEnd,
  raffleEndUrl,
  raffleEndLink,
  textData,
  profileEndpointLink,
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const { lotteryBoxState, participate } = useLotteryRegistration({
    webRaffleStart,
    webRaffleEnd,
    poolId,
    contentfulId: id,
  });

  if (!lotteryBoxState) {
    return null;
  }

  const renderBoxContent = () => {
    if (lotteryBoxState === 'error') {
      return (
        <LotteryBoxMainContent
          smallHeadline={textData.smallHeadline}
          title={textData.titleError}
          description={textData.descriptionError}
        />
      );
    }

    if (lotteryBoxState === 'loading') {
      return <LoadingIcon className="w-12 h-12" />;
    }

    if (lotteryBoxState === 'inactive') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleInactive}
            description={textData.descriptionInactive}
          />

          {(raffleEndLink || raffleEndUrl) && (
            <LotteryBoxFooter>
              {raffleEndLink && (
                <CustomLink {...raffleEndLink}>
                  {renderNormalButton(textData.ctaInactiveText, pageTheme)}
                </CustomLink>
              )}

              {raffleEndUrl && (
                <CustomLink href={raffleEndUrl}>
                  {renderNormalButton(textData.ctaInactiveText, pageTheme)}
                </CustomLink>
              )}
            </LotteryBoxFooter>
          )}
        </>
      );
    }

    if (lotteryBoxState === 'incomplete_profile') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleIncompleteProfile}
            description={textData.descriptionIncompleteProfile}
          />

          <LotteryBoxFooter>
            <CustomLink {...profileEndpointLink} isExternal>
              {renderNormalButton(textData.ctaIncompleteProfileText, pageTheme)}
            </CustomLink>
          </LotteryBoxFooter>
        </>
      );
    }

    if (lotteryBoxState === 'ready_for_participation') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.title}
            description={textData.description}
          />

          <LotteryBoxFooter>
            {renderNormalButton(textData.ctaButtonText, pageTheme, participate)}

            <span className="text-black tesn:text-esn-secondary-dark">
              oder
            </span>

            {renderShareButton(textData.ctaShareText)}
          </LotteryBoxFooter>
        </>
      );
    }

    if (lotteryBoxState === 'took_participation') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleParticipationSuccess}
            description={textData.descriptionParticipationSuccess}
          />

          <LotteryBoxFooter>
            {renderShareButton(textData.ctaShareText)}
          </LotteryBoxFooter>
        </>
      );
    }

    if (lotteryBoxState === 'already_participated') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleAlreadyParticipated}
            description={textData.descriptionAlreadyParticipated}
          />

          <LotteryBoxFooter>
            {renderShareButton(textData.ctaShareText)}
          </LotteryBoxFooter>
        </>
      );
    }

    if (lotteryBoxState === 'won') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleAlreadyWon}
            description={textData.descriptionAlreadyWon}
          />

          <LotteryBoxFooter>
            {renderShareButton(textData.ctaShareText)}
          </LotteryBoxFooter>
        </>
      );
    }

    if (lotteryBoxState === 'logged_out') {
      return (
        <>
          <LotteryBoxMainContent
            smallHeadline={textData.smallHeadline}
            title={textData.titleLogin}
            description={textData.descriptionLogin}
          />

          <LotteryBoxFooter>
            {renderNormalButton(textData.ctaLoginText, pageTheme, login)}

            <span className="text-black tesn:text-esn-secondary-dark">
              oder
            </span>

            {renderShareButton(textData.ctaShareText)}
          </LotteryBoxFooter>
        </>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col items-center rounded-4 w-[600px] mx-6 px-6 py-8 md:px-20 teair:bg-white tesn:bg-esn-secondary-superLight tels:bg-white">
      {renderBoxContent()}
    </div>
  );
};

export default LotteryRegistrationBox;
