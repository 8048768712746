const styles = {
  wrapper: 'hidden md:block relative',
  infoWrapper:
    'absolute top-0 z-50 flex flex-col items-start w-3/5 mt-12 xl:mt-16',
  cardImgContainer: 'relative md:w-4/5 2xl:w-3/4 md:ml-auto',
  gradient:
    'absolute inset-y-0 right-0 -left-px aspect aspect-1:1 w-2/3 md:pb-0 bg-gradient-to-t md:bg-gradient-to-r from-black',
  gradientHover: 'group-hover:w-full transition-all',
};

export default styles;
