import { FC, useState } from 'react';
import {
  DeepMap,
  FieldError,
  SubmitErrorHandler,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import formErrorImage from '@/assets/form-images/form-error.svg';
import formSuccessImage from '@/assets/form-images/form-success.svg';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import { selectIsUserLoggedIn, selectUserProfile } from '@/store/slices/user';
import { ImageAsset } from '@/types/views/generic';

import Form from './form';
import Submitted from './submitted';

interface ContactFormProps {
  overlineText: string;
  recipient: string;
  contactMobileImage?: ImageAsset;
  contactDesktopImage?: ImageAsset;
  contactSuccessImage?: ImageAsset;
  contactErrorImage?: ImageAsset;
  onClose?: () => void;
}

export interface ContactFormData {
  station: string;
  email: string;
  message: string;
  recipient: string;
  targetText: string;
}

const ContactForm: FC<ContactFormProps> = ({
  overlineText,
  recipient,
  contactMobileImage,
  contactDesktopImage,
  contactSuccessImage,
  contactErrorImage,
  onClose,
}) => {
  const { isMobile } = useBreakpoint();

  const contactImageUrl =
    isMobile && contactMobileImage
      ? contactMobileImage.src
      : contactDesktopImage?.src;

  const successImage = contactSuccessImage ?? formSuccessImage;
  const errorImage = contactErrorImage ?? formErrorImage;

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const profile = useSelector(selectUserProfile);

  const [missingSubmitData, setMissingSubmitData] = useState<
    DeepMap<ContactFormData, FieldError> | undefined
  >(undefined);
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ContactFormData>({
    mode: 'all',
    defaultValues: {
      email: isUserLoggedIn ? profile?.email : '',
      recipient,
      targetText: overlineText,
    },
  });

  const onError: SubmitErrorHandler<ContactFormData> = (dataErrors) => {
    setMissingSubmitData(dataErrors);
  };

  const onSubmit = async (data: ContactFormData) => {
    const response = await fetch('/api/contact-form-send', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const res = await response?.json();

      if (res?.submitError) setIsSubmitError(true);
    } else {
      setIsSuccess(true);
    }
  };

  return (
    <>
      {isSuccess || isSubmitError ? (
        <Submitted
          image={isSuccess ? successImage : errorImage}
          isSuccess={isSuccess}
          onClose={onClose}
          onBack={() => {
            reset();
            setIsSubmitError(false);
          }}
        />
      ) : (
        <Form
          register={register}
          errors={errors}
          missingSubmitData={missingSubmitData}
          onSubmit={handleSubmit(onSubmit, onError)}
          onClose={onClose}
          overlineText={overlineText}
          isSubmitting={isSubmitting}
          contactImageUrl={contactImageUrl}
        />
      )}
    </>
  );
};

export default ContactForm;
