import React, { FC } from 'react';

import HeaderWithBackgroundImage from '@/components/header-with-background-image';
import StationPlayer from '@/components/station-player';
import { EntryComponentProps } from '@/types/views/generic';
import { StationHeaderSectionEntry } from '@/types/views/sections';

const StationHeaderSection: FC<EntryComponentProps<StationHeaderSectionEntry>> =
  ({
    station,
    background,
    backgroundMobile,
    toggleLinksData,
    playlistLink,
    whatsappNumber,
  }) => (
    <HeaderWithBackgroundImage
      background={background}
      backgroundMobile={backgroundMobile}
      backgroundImageHeight="60%"
      alt="Radio station header image"
    >
      <StationPlayer
        station={station}
        toggleLinksData={toggleLinksData}
        playlistLink={playlistLink}
        className="content-box"
        whatsappNumber={whatsappNumber}
      />
    </HeaderWithBackgroundImage>
  );

export default StationHeaderSection;
