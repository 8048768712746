import cls from 'classnames';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import ExternalLinkIcon from '@/components/icons/external-link-icon';
import Image from '@/components/image';
import { IconLinkEntry } from '@/types/views/generic';

const IconLink: FC<IconLinkEntry> = ({ title, icon, link }) => (
  <CustomLink
    {...link}
    className={cls(
      'inline-flex items-center',
      'text-body2 text-red-medium underline hover:no-underline',
    )}
  >
    <span className="w-8 h-8">
      <Image src={icon.src} />
    </span>

    <span className="inline-block ml-2">{title}</span>

    {link.isExternal && <ExternalLinkIcon className="w-6 h-6 ml-2" />}
  </CustomLink>
);

export default IconLink;
