import cls from 'classnames';
import { FC } from 'react';

type IntrinsticButton = JSX.IntrinsicElements['button'];

export interface ChipProps extends IntrinsticButton {
  size?: 'small' | 'normal';
  variant?: 'default' | 'red' | 'light' | 'disabled';
  className?: string;
  onClick?: () => void;
}

const sizeMap: Record<NonNullable<ChipProps['size']>, string> = {
  small: 'h-8',
  normal: 'h-10',
};

const variantMap: Record<NonNullable<ChipProps['variant']>, string> = {
  default: 'bg-black-light hover:bg-gray-1',
  red: 'bg-red-medium hover:bg-red',
  light: 'bg-red-light-darker hover:bg-white text-black',
  disabled: 'opacity-50',
};

const Chip: FC<ChipProps> = ({
  size = 'normal',
  children,
  className = '',
  variant = 'default',
  onClick,
  ...rest
}) => (
  <button
    onClick={onClick}
    className={cls(
      className,
      'whitespace-nowrap flex items-center px-4 rounded-full text-body2',
      sizeMap[size],
      variantMap[variant],
    )}
    {...rest}
  >
    {children}
  </button>
);

export default Chip;
