import cls from 'classnames';
import React, { FC } from 'react';

import OversizeC2AButton from '@/components/button/oversize-c2a';
import TextButton from '@/components/button/text';
import CustomLink from '@/components/custom-link';
import { LinkSectionEntry } from '@/types/views/sections';

const Link: FC<LinkSectionEntry> = (props) => (
  <>
    {props.layout === 'page' ? (
      <div className={cls('content-box', props.deactivateAdSlots && 'ml-auto')}>
        <CustomLink {...props}>
          <OversizeC2AButton text={props.text} />
        </CustomLink>
      </div>
    ) : (
      <CustomLink {...props}>
        <TextButton theme="light" text={props.text} />
      </CustomLink>
    )}
  </>
);

export default Link;
