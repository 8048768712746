import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { TeaserGroup } from '@/types/views/generic';

import styles from './styles';

interface GridProps {
  teaserGroup: TeaserGroup;
  teasers: JSX.Element[];
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  showLoadMore?: boolean;
  onLoadMore?: () => void;
  loadMoreText?: string;
}

const Grid: FC<GridProps> = ({
  teaserGroup,
  teasers,
  isLoading = false,
  isError = false,
  isEmpty = false,
  showLoadMore = false,
  onLoadMore = () => null,
  loadMoreText = '',
}) => {
  const variables = useVariables();
  const buttonText =
    loadMoreText || getVariablePlainText(variables['load-more-cta']) || '';
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);

  if (isError) {
    return <div>{getVariablePlainText(variables['content-not-loaded'])}</div>;
  }

  if (isEmpty && !isLoading) {
    return <div>{getVariablePlainText(variables['content-not-found'])}</div>;
  }

  return (
    <>
      <div className={styles.wrapper(teaserGroup)}>{teasers}</div>

      {showLoadMore && !!teasers.length && (
        <div className={styles.buttonContainer}>
          <NormalButton
            {...buttonProps}
            text={buttonText}
            onClick={() => !isLoading && onLoadMore()}
          />
        </div>
      )}

      {isLoading && <LoadingIcon className={styles.loadingIcon(isEmpty)} />}
    </>
  );
};

export default Grid;
