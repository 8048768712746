import cls from 'classnames';

const styles = {
  container: (isCentered: boolean) =>
    cls(
      'flex flex-col',
      isCentered
        ? 'items-center'
        : 'rounded-2 overflow-hidden tesn:bg-esn-secondary-dark',
    ),
  title: (isInfo: boolean) =>
    cls(
      'text-white teair:text-air-deepBlue text-h5 pb-2 text-center md:text-left',
      isInfo ? 'tesn:text-white ' : 'tesn:text-esn-primary',
    ),
  text: 'text-white teair:text-air-deepBlue tesn:text-white text-body2 text-center md:text-left',
  image: (isInfo: boolean, isCentered: boolean) =>
    cls(
      'flex pb-6 justify-center',
      isInfo && 'md:justify-start',
      !isCentered && 'tesn:bg-white tesn:pt-4 tesn:px-4 tesn:h-[135px]',
    ),
};

export default styles;
