import React, { FC } from 'react';

interface IconProps {
  className?: string;
  fill?: string;
}

const ExternalLinkIcon: FC<IconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path d="M17.001 20H6.00101C4.89644 20 4.00101 19.1046 4.00101 18V7C4.00101 5.89543 4.89644 5 6.00101 5H10.001V7H6.00101V18H17.001V14H19.001V18C19.001 19.1046 18.1056 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z" />
  </svg>
);

export default ExternalLinkIcon;
