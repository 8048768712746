interface ConsentProps {
  text: string;
}

const Consent = ({ text }: ConsentProps) => (
  <div className="flex flex-col items-center">
    <div className="flex items-start max-w-[508px]">
      <input
        type="checkbox"
        name="marketingOptIn"
        id="marketingOptIn"
        className="mt-1 mr-3 w-4 h-4 shadow-quizOption rounded-1 border-[1px] border-black bg-white hover:bg-game-orange-light cursor-pointer"
      />
      <label
        htmlFor="marketingOptIn"
        className="text-body text-white font-semibold cursor-pointer"
      >
        {text}
      </label>
    </div>
  </div>
);

export default Consent;
