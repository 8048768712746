import Skeleton from '@/components/skeleton';

const ShowInfoPlaceholder = () => (
  <div className="flex flex-col">
    <Skeleton className="md:hidden mb-2 h-[1.125rem] w-full" />

    <Skeleton className="mb-4 md:mb-8 mr-4 h-[1.75rem] md:h-[2.625rem]" />
  </div>
);

export default ShowInfoPlaceholder;
