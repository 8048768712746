import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';
import SwiperCore, {
  A11y,
  Controller,
  HashNavigation,
  Navigation,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { screens } from 'tailwind/theme';

import CarouselNavButton from '@/components/carousel-nav-button';
import { MediaAsset } from '@/types/views/generic';

import MainSwiperSlide from './main-swiper-slide';

SwiperCore.use([Navigation, A11y, Controller, HashNavigation]);

const MAIN_CAROUSEL_NAV_PREV_BUTTON_ID =
  'gallery-main-carousel-navigation-prev-button';

const MAIN_CAROUSEL_NAV_NEXT_BUTTON_ID =
  'gallery-main-carousel-navigation-next-button';

const swiperClass = css`
  @media (min-width: ${screens.md}) {
    .swiper-slide {
      &.swiper-slide-active {
        transform: scale(1);
      }

      &.swiper-slide-prev {
        transform: translateX(10%) scale(0.8);
      }

      &.swiper-slide-next {
        transform: translateX(-10%) scale(0.8);
      }
    }
  }
`;

const navButtonClasses = 'absolute z-20 top-1/2 transform -translate-y-1/2';

const gradientClases =
  'absolute z-10 inset-y-0 w-20 from-black teair:from-air-deepBlue';

const renderLeftGradient = () => (
  <div className={cls('left-0 bg-gradient-to-r', gradientClases)} />
);

const renderRightGradient = () => (
  <div className={cls('right-0 bg-gradient-to-l', gradientClases)} />
);

interface MainSwiperProps {
  mainSwiper?: SwiperCore;
  thumbnailsSwiper?: SwiperCore;
  media: MediaAsset[];
  urlToShare: string;
  className?: string;
  onSwiper: Swiper['onSwiper'];
  onHashSet?: Swiper['onHashSet'];
  onActiveIndexChange: Swiper['onActiveIndexChange'];
}

const MainSwiper: FC<MainSwiperProps> = ({
  mainSwiper,
  thumbnailsSwiper,
  media,
  urlToShare,
  className,
  onSwiper,
  onHashSet,
  onActiveIndexChange,
}) => (
  <div className={cls('relative', className)}>
    <div className="relative w-screen transform ml-1/2 -translate-x-1/2">
      <Swiper
        controller={{ control: thumbnailsSwiper }}
        wrapperTag="ul"
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        navigation={{
          prevEl: `#${MAIN_CAROUSEL_NAV_PREV_BUTTON_ID}`,
          nextEl: `#${MAIN_CAROUSEL_NAV_NEXT_BUTTON_ID}`,
        }}
        hashNavigation={{
          replaceState: true,
        }}
        className={swiperClass}
        onActiveIndexChange={onActiveIndexChange}
        onSwiper={onSwiper}
        onHashSet={onHashSet}
      >
        {media.map((mediaItem, index) => (
          <SwiperSlide
            tag="li"
            data-hash={mediaItem.id}
            key={index}
            className="relative content-box-centered"
          >
            {({ isActive }) => (
              <div className="w-full md:w-5/6 mx-auto">
                <MainSwiperSlide
                  isActive={isActive}
                  mediaItem={mediaItem}
                  urlToShare={urlToShare}
                />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="hidden lg:block">
        {!mainSwiper?.isBeginning && renderLeftGradient()}

        {!mainSwiper?.isEnd && renderRightGradient()}
      </div>
    </div>

    <CarouselNavButton
      id={MAIN_CAROUSEL_NAV_PREV_BUTTON_ID}
      type="prev"
      shouldDisplay={!mainSwiper?.isBeginning}
      smSize="small"
      mdSize="medium"
      className={cls(
        'left-0 -translate-x-1/4 lg:-translate-x-1/2',
        navButtonClasses,
      )}
    />

    <CarouselNavButton
      id={MAIN_CAROUSEL_NAV_NEXT_BUTTON_ID}
      type="next"
      shouldDisplay={!mainSwiper?.isEnd}
      smSize="small"
      mdSize="medium"
      className={cls(
        'right-0 translate-x-1/4 lg:translate-x-1/2',
        navButtonClasses,
      )}
    />
  </div>
);

export default MainSwiper;
