import { Document } from '@contentful/rich-text-types';
import { FC } from 'react';

import RichTextRenderer from '@/components/rich-text-renderer';
import usePageTheme from '@/lib/hooks/use-page-theme';

import styles from './styles';

interface RichTextBlockProps {
  body: Document;
  hasInvertedThemeColor?: boolean;
}

const RichTextBlock: FC<RichTextBlockProps> = ({
  body,
  hasInvertedThemeColor,
}) => {
  const { isGameTheme } = usePageTheme();
  const hasCustomText = hasInvertedThemeColor;

  return (
    <div className={styles.container(hasCustomText)}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer(isGameTheme, hasCustomText)}>
          <RichTextRenderer body={body} />
        </div>
      </div>
    </div>
  );
};

export default RichTextBlock;
