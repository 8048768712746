interface CurvyProps {
  className?: string;
  stroke?: string;
}

const CurvyIcon = ({ className = '', stroke = 'currentColor' }: CurvyProps) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 158"
      fill="none"
      className="w-[43px] h-[114px] lg:w-[58px] lg:h-[158px]"
    >
      <path
        d="M29 154C15.19 154 4 142.81 4 129C4 115.19 15.19 104 29 104C42.81 104 54 92.81 54 79C54 65.19 42.81 54 29 54C15.19 54 4 42.81 4 29C4 15.19 15.19 4 29 4"
        stroke={stroke}
        strokeWidth="8"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);

export default CurvyIcon;
