import cls from 'classnames';
import { FC, memo } from 'react';

import NormalButton from '@/components/button/normal';
import TextButton from '@/components/button/text';
import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import LotteryFormButton from '@/components/lottery-form/button';
import RichTextRenderer from '@/components/rich-text-renderer';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useVariables from '@/lib/hooks/use-variables';
import { formatDate } from '@/lib/utils';
import {
  LotteryFormButton as LotteryFormButtonType,
  TextLink,
} from '@/types/views/generic';
import { TeaserSectionEntry } from '@/types/views/sections';

import styles from './styles';

const mapToOverlineText = (location = '', date = '') => {
  const textArray = [];

  if (date) textArray.push(formatDate(date, 'dd.MM.yyyy'));

  if (location) textArray.push(location);

  return textArray.join(', ');
};

const Teaser: FC<TeaserSectionEntry> = memo(
  ({
    title,
    description,
    overlineText,
    imageDesktop,
    imageMobile,
    link,
    layout,
    formatOverlineText = false,
    sessionDate = '',
    sessionLocation = '',
    sessionNumber = '',
    hasInvertedThemeColor,
    componentType,
    deactivateAdSlots,
  }) => {
    const variables = useVariables();
    const { isEventTheme, pageTheme } = usePageTheme();
    const isTeaserSection = componentType === 'teaser';
    const isActSection = componentType === 'act';
    const buttonProps = getThemedButtonProps(pageTheme);
    const isLinkToForm =
      (link as LotteryFormButtonType)?.componentType === 'lotteryFormButton';

    const renderLink = () => {
      if (!link) return null;

      return link && isLinkToForm ? (
        <div className="mt-4">
          <LotteryFormButton {...(link as LotteryFormButtonType)} />
        </div>
      ) : (
        <div className={styles.buttonContainer}>
          {!isEventTheme ? (
            <TextButton
              className="transition-all"
              text={(link as TextLink).text}
            />
          ) : (
            <NormalButton
              {...buttonProps}
              text={(link as TextLink).text}
              className="font-semibold"
            />
          )}
        </div>
      );
    };

    const formattedOverlineText =
      ((formatOverlineText && sessionDate) || sessionLocation) &&
      mapToOverlineText(sessionLocation, sessionDate);

    const Main = () => (
      <div className="group relative">
        <div className={cls(styles.imageContainer, styles.imageMobile)}>
          <Image
            className={styles.image}
            src={imageMobile.src}
            alt={imageMobile.description}
            objectFit="cover"
          />
        </div>

        <div className={cls(styles.imageContainer, styles.imageDesktop)}>
          <Image
            className={styles.image}
            src={imageDesktop.src}
            alt={imageDesktop.description}
            objectFit="cover"
          />
        </div>

        <div className={cls(styles.gradient, link && styles.gradientHover)} />

        {sessionNumber && (
          <div
            className={cls(
              'absolute right-5 top-6 w-20 h-20 tels:bg-els-blue rounded-full flex items-center justify-center',
              'md:w-32 md:h-32 lg:right-10 lg:top-10',
            )}
          >
            <div className="text-center">
              <div className={cls('text-h4', styles.circleContent)}>
                {getVariablePlainText(
                  variables['live-session-circle-number-text'],
                ) || 'Nr.'}
              </div>

              <div className={cls('text-h1 font-syne', styles.circleContent)}>
                {sessionNumber}
              </div>
            </div>
          </div>
        )}

        <div className={styles.content(isEventTheme)}>
          <div
            className={cls(
              'content-box md:content-box-none',
              !hasInvertedThemeColor && 'teair:content-box',
              deactivateAdSlots && 'ml-auto',
            )}
          >
            {(!!overlineText || formattedOverlineText) && (
              <span className={styles.overlineText}>
                {formatOverlineText ? formattedOverlineText : overlineText}
              </span>
            )}

            <h2 className="text-h2 teair:text-white break-normal">{title}</h2>

            {description && (
              <RichTextRenderer
                className={styles.description}
                body={description}
              />
            )}

            {renderLink()}
          </div>
        </div>
      </div>
    );

    return (
      <div
        className={cls(
          layout === 'rich-text' && 'bg-black',
          isTeaserSection && 'teair:bg-air-deepBlue',
          isActSection && 'py-0',
        )}
      >
        <div
          className={cls('md:content-box', deactivateAdSlots && 'md:ml-auto')}
        >
          {link && !isLinkToForm ? (
            <CustomLink {...(link as TextLink)}>
              <Main />
            </CustomLink>
          ) : (
            <Main />
          )}
        </div>
      </div>
    );
  },
);

export default Teaser;
