import { FC } from 'react';

import { EntryComponentProps } from '@/types/views/generic';
import { GalleriesContainerSectionEntry } from '@/types/views/sections';

import SectionSection from '../sections';

const GalleriesContainerSection: FC<
  EntryComponentProps<GalleriesContainerSectionEntry>
> = ({
  title,
  showTitle,
  link,
  galleryTeasers,
  hasInvertedThemeColor,
  deactivateAdSlots,
}) => (
  <SectionSection
    showTitle={showTitle}
    title={title}
    link={link}
    elements={galleryTeasers}
    textClassNames="teair:text-air-deepBlue"
    hasInvertedThemeColor={hasInvertedThemeColor}
    deactivateAdSlots={deactivateAdSlots}
  />
);

export default GalleriesContainerSection;
