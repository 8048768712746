import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import MediaBackground from '@/components/media-background';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import heroHeight from '@/lib/set-hero-height';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { EntryComponentProps } from '@/types/views/generic';
import { DualHeroSectionEntry } from '@/types/views/sections';

import ConditionalWrapper from '../../conditional-wrapper';
import SplitForeground from './split-foreground';

const MARGIN_BOTTOM = 14;

const DualHeroSection: FC<EntryComponentProps<DualHeroSectionEntry>> = ({
  primaryBackgroundDesktop,
  primaryBackgroundMobile,
  secondaryBackgroundDesktop,
  secondaryBackgroundMobile,
  backgroundColor,
  textColor,
  link,
  showButton,
  image,
  text,
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);

  const renderButton = () =>
    showButton &&
    link &&
    renderWithLinkIfExists(
      <NormalButton
        {...buttonProps}
        text={link.text}
        className="whitespace-nowrap"
      />,
    );

  const renderWithLinkIfExists = (element: ReactNode, wrapperClass?: string) =>
    link ? (
      <CustomLink {...link} className={wrapperClass}>
        {element}
      </CustomLink>
    ) : (
      <div className={wrapperClass}>{element}</div>
    );

  return (
    <ConditionalWrapper
      condition={!showButton}
      wrapper={(children) =>
        link ? <CustomLink {...link}>{children}</CustomLink> : children
      }
    >
      <div
        className={`${heroHeight(
          MARGIN_BOTTOM,
        )} flex flex-col md:flex-row items-start p-0 w-full md:flex-nowrap relative`}
      >
        <div className="h-1/2 w-full md:h-full md:w-1/2 flex-none relative">
          <MediaBackground
            background={primaryBackgroundDesktop}
            backgroundMobile={
              primaryBackgroundMobile || primaryBackgroundDesktop
            }
          />
        </div>

        <SplitForeground
          textColor={textColor}
          backgroundColor={backgroundColor}
          showButton={showButton}
          image={image}
          backgroundDesktop={secondaryBackgroundDesktop}
          backgroundMobile={secondaryBackgroundMobile}
          text={text}
          renderButton={renderButton}
        />

        {showButton && (
          <div className=" absolute h-full w-full flex top-0 md:hidden">
            <div className="relative m-auto z-20">{renderButton()}</div>
          </div>
        )}
      </div>
    </ConditionalWrapper>
  );
};

export default DualHeroSection;
