import { useState } from 'react';

const useRefs = <T>(): [T[], (node: T) => void] => {
  const [entryRefs, setEntryRefs] = useState<T[]>([]);

  const setRefs = (node: T) => {
    if (node) {
      setEntryRefs((prevState) => {
        if (prevState.includes(node)) {
          return prevState;
        }

        return [...prevState, node];
      });
    }
  };

  return [entryRefs, setRefs];
};

export default useRefs;
