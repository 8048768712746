import { FC } from 'react';

import { ToggleData } from '@/types/views/generic';

import ChipSelect from '../chip-select';
import CustomLink from '../custom-link';

interface LinksChipSelectProps {
  activeLinkIndex?: number;
  toggleLinksData: ToggleData[];
  className?: string;
}

const LinksChipSelect: FC<LinksChipSelectProps> = ({
  activeLinkIndex,
  toggleLinksData,
  className = '',
}) => (
  <ChipSelect activeIndex={activeLinkIndex} className={className}>
    {toggleLinksData.map((toggleItem) => (
      <CustomLink href={toggleItem.href} key={toggleItem.href}>
        {toggleItem.text}
      </CustomLink>
    ))}
  </ChipSelect>
);

export default LinksChipSelect;
