import cls from 'classnames';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ImageSectionEntry } from '@/types/views/sections';

import styles from './styles';

const ImageSection: FC<ImageSectionEntry> = ({
  image,
  link,
  layout,
  imageMobile,
  deactivateAdSlots,
}) => {
  const ImageElement = () => (
    <>
      {imageMobile && (
        <Image
          className="md:hidden"
          src={imageMobile.src}
          useIntrinsicSize
          width={imageMobile.width}
          height={imageMobile.height}
        />
      )}

      <Image
        className={imageMobile && 'hidden md:block'}
        src={image.src}
        useIntrinsicSize
        width={image.width}
        height={image.height}
      />
    </>
  );

  const ImageComponent = () => (
    <>
      {link ? (
        <CustomLink className="inline-block" {...link}>
          <ImageElement />
        </CustomLink>
      ) : (
        <ImageElement />
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={cls(
          'content-box text-center leading-none',
          deactivateAdSlots && 'ml-auto',
        )}
      >
        {layout === 'rich-text' ? (
          <div className="text-box">
            <ImageComponent />
          </div>
        ) : (
          <ImageComponent />
        )}
      </div>
    </div>
  );
};

export default ImageSection;
