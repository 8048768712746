import cls from 'classnames';

const styles = {
  itemWrapper: (isActive: boolean) =>
    cls(
      'flex w-full justify-between border-b border-black-light h-20',
      isActive && 'bg-gray-1 bg-opacity-30',
    ),
  imageWrapper: 'h-20 w-20 flex-grow flex-shrink-0',
  artistTitleWrapper: 'p-4 overflow-hidden',
  trackDurationArtist: 'flex',
  artist: 'text-overline text-gray-2 line-clamp-1',
  title: 'text-lead line-clamp-1',
  leftSide: 'flex flex-shrink-1 overflow-hidden',
  duration: (isMobile: boolean) =>
    cls(
      'text-overline text-pink',
      isMobile ? 'hidden md:block p-4 pr-6 w-20' : 'md:hidden mr-2',
    ),
  rightSide: 'flex items-center pr-2 md:pr-4',
  live: 'bg-red rounded-full py-0.5 px-2 items-center hidden md:flex mr-4',
  liveText: 'text-overline ml-2',
  audioProgressBar: 'w-10 h-10 md:w-14 md:h-14',
};

export default styles;
