import cls from 'classnames';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarouselNavButton from '@/components/carousel-nav-button';
import useChannelPlayer from '@/components/channel-carousel/use-channel-player';
import Share from '@/components/share';
import getCurrentPageShareUrl from '@/lib/get-current-page-share-url';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useHasMounted from '@/lib/hooks/use-has-mounted';
import {
  selectPlayerCurrentChannel,
  selectPlayerIsPlaying,
  selectPlayerMetadata,
} from '@/store/slices/player';
import { EntryComponentProps } from '@/types/views/generic';
import { ChannelCarouselSectionEntry } from '@/types/views/sections';

import ImageFlipper from '../image-flipper/image-flipper';
import PlayPauseButtonTransparent from '../play-pause-button-transparent';
import { bgStyles, carouselWrapper, navButtonClasses } from './styles';

SwiperCore.use([A11y, Navigation]);

// Navigation buttons
const CHANNEL_CAROUSEL_PREV = 'channel-carousel-navigation-prev-button';
const CHANNEL_CAROUSEL_NEXT = 'channel-carousel-navigation-next-button';

let routeChangeTimeout: ReturnType<typeof setTimeout>;

const ChannelCarousel: FC<EntryComponentProps<ChannelCarouselSectionEntry>> = ({
  currentChannel,
  channels,
}) => {
  const router = useRouter();
  const [channelSwiper, setChannelSwiper] = useState<SwiperCore | null>(null);

  const { isMobile } = useBreakpoint();

  const { id, bgMobile, bgDesktop, name } = currentChannel;
  const startIndex = channels.findIndex((el) => el.id === id);
  const stationBg = isMobile ? bgMobile : bgDesktop;

  const isPlaying = useSelector(selectPlayerIsPlaying);
  const currentPlayerChannel = useSelector(selectPlayerCurrentChannel);

  const { songMetadata, isLoading } = useChannelPlayer(currentChannel);
  // Added as a fallback because there were cases when hidden player hadn't some data while playing one had it
  const playerMetadata = useSelector(selectPlayerMetadata);

  // Force rerender of a proper slide after route change using browser's back/forward buttons
  useEffect(() => {
    if (channelSwiper && channelSwiper.realIndex !== startIndex) {
      channelSwiper.slideToLoop(startIndex);
    }
  }, [channelSwiper, startIndex]);

  // Prevents rehydration issues
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  const isCurrentChannel = currentPlayerChannel?.id === id;
  const isPlayingCurrentChannel = isPlaying && isCurrentChannel;

  const changeRouteFromSlider = (slideIndex: number) => {
    if (routeChangeTimeout) {
      clearTimeout(routeChangeTimeout);
    }

    if (slideIndex !== startIndex) {
      routeChangeTimeout = setTimeout(
        () => router.push(`/channels/${channels[slideIndex].id}`),
        1000,
      );
    }
  };

  return (
    <>
      <div
        className={cls(
          'absolute top-0 left-0 2xl:left-1/2 2xl:transform 2xl:-translate-x-1/2 w-screen -z-10',
          bgStyles(stationBg),
        )}
      />

      <div className="md:content-box">
        <div className="relative">
          <Swiper
            slidesPerView="auto"
            initialSlide={startIndex}
            navigation={{
              prevEl: `#${CHANNEL_CAROUSEL_PREV}`,
              nextEl: `#${CHANNEL_CAROUSEL_NEXT}`,
            }}
            className={carouselWrapper}
            centeredSlides
            loop
            slideToClickedSlide
            watchSlidesVisibility={!isMobile}
            runCallbacksOnInit={false}
            preventInteractionOnTransition
            threshold={2}
            onTouchEnd={(swiper) => changeRouteFromSlider(swiper.realIndex)}
            onSlideChangeTransitionEnd={(swiper) =>
              changeRouteFromSlider(swiper.realIndex)
            }
            onSwiper={setChannelSwiper}
          >
            {channels.map((channel) => (
              <SwiperSlide key={channel.id}>
                {({ isActive, isPrev, isNext }) => {
                  const isStartSlideActive =
                    channelSwiper?.realIndex === startIndex;

                  return (
                    <div className="channel-wrapper relative rounded-2 overflow-hidden">
                      <ImageFlipper
                        isLoading={isLoading}
                        coverUrl={songMetadata?.coverUrl}
                        isActive={isActive && isStartSlideActive}
                        logoUrl={channel.logoUrl}
                      />

                      {(isPrev || isNext) && (
                        <div className="channel-name text-center hidden md:flex absolute w-full text-overline text-gray-2 mt-4 md:line-clamp-2">
                          {channel.name}
                        </div>
                      )}

                      <div
                        className={cls(
                          'channel-overlay absolute inset-0 z-20',
                          !isActive &&
                            'bg-black bg-opacity-30 opacity-0 hover:opacity-100 transition-opacity duration-200',
                        )}
                      >
                        {isActive && isStartSlideActive && (
                          <PlayPauseButtonTransparent channelId={id} />
                        )}
                      </div>
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>

          <CarouselNavButton
            id={CHANNEL_CAROUSEL_PREV}
            type="prev"
            shouldDisplay={!isMobile}
            className={cls('left-0 -translate-x-1/2', navButtonClasses)}
          />

          <CarouselNavButton
            id={CHANNEL_CAROUSEL_NEXT}
            type="next"
            shouldDisplay={!isMobile}
            className={cls('right-0 translate-x-1/2', navButtonClasses)}
          />
        </div>
      </div>

      <div className="content-box">
        <div className="w-7/12 md:w-4/12 text-h4 text-center text-red-light mx-auto mt-4 md:mt-6">
          {name}
        </div>

        <div
          className={cls(
            'md:w-5/12 text-center text-body1 text-gray-3 mx-auto mt-1 md:mt-4',
            isLoading && 'min-h-12',
          )}
        >
          {songMetadata && !isLoading && (
            <>
              <div className="font-semibold line-clamp-1">
                {songMetadata.title
                  ? songMetadata.title
                  : isPlayingCurrentChannel && playerMetadata?.title}
              </div>

              <div className="line-clamp-1">
                {songMetadata.artist
                  ? songMetadata.artist
                  : isPlayingCurrentChannel && playerMetadata?.artist}
              </div>
            </>
          )}
        </div>

        <div className="flex justify-center mt-2">
          <Share urlToShare={getCurrentPageShareUrl()} />
        </div>
      </div>
    </>
  );
};

export default ChannelCarousel;
