import cls from 'classnames';

const styles = {
  header: 'flex flex-col md:flex-row items-baseline mb-4 md:mb-6',
  headerTitle: (hasInvertedThemeColor: boolean) =>
    cls(
      'text-h1 text-pink mb-2 md:mb-0 md:mr-4',
      'teds:text-white',
      hasInvertedThemeColor ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
};

export default styles;
