import cls from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

import { ContactFormData } from '../..';

interface StationInputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<ContactFormData>;
  errors: DeepMap<ContactFormData, FieldError>;
}

const Station = forwardRef<HTMLInputElement, StationInputProps>(
  ({ register, errors }, ref) => {
    const variables = useVariables();

    const stations = getVariablePlainText(
      variables['contactform-stations'],
    )?.split(';');

    const stationErrorText =
      getVariablePlainText(variables['contactform-station-error']) ||
      'Bitte wähle eine Station aus.';

    return (
      <div className="flex flex-wrap mt-6 items-start relative pb-6">
        {stations &&
          stations.map((station) => (
            <label className="mr-8 whitespace-nowrap flex flex-row gap-3 items-center text-body2 mb-2">
              <input
                {...register('station', {
                  required: stationErrorText,
                })}
                ref={ref}
                type="radio"
                value={station}
                className="hidden peer"
              />
              <div
                className={cls(
                  'w-6 h-6 rounded-full',
                  'bg-black bg-opacity-50 border border-gray-2 box-border',
                  'flex items-center justify-center',
                  'peer-checked:after:w-4 peer-checked:after:h-4',
                  'peer-checked:after:bg-gray-4 peer-checked:after:rounded-full',
                )}
              ></div>
              {station}
            </label>
          ))}
        {errors?.station?.message && (
          <p className="text-red-medium h-5 text-small mt-1 w-full absolute bottom-0">
            {errors.station.message}
          </p>
        )}
      </div>
    );
  },
);

export default Station;
