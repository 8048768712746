import React, { FC } from 'react';

interface LiveIconProps {
  className?: string;
  fill?: string;
}

const LiveIcon: FC<LiveIconProps> = ({ className, fill = 'currentColor' }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    className={className}
  >
    <path
      d="M15.9997 5.93542C15.9584 8.12362 15.1997 10.0143 13.6584 11.5802C13.3028 11.9418 12.7054 11.9559 12.3521 11.6181C11.967 11.2503 11.9353 10.6626 12.3028 10.2818C12.9891 9.57051 13.5276 8.768 13.8107 7.81701C14.4913 5.52907 14.0264 3.49276 12.4308 1.71773C12.3487 1.62648 12.2637 1.53863 12.1975 1.43379C11.9489 1.03877 12.0288 0.496962 12.3776 0.214724C12.7513 -0.0873496 13.3147 -0.0703473 13.647 0.263464C14.8565 1.47799 15.5988 2.93509 15.8865 4.62455C15.9601 5.05754 15.9963 5.4945 15.9997 5.93542Z"
      fill={fill}
    />
    <path
      d="M-0.00047957 5.54885C0.0397228 3.73357 0.78828 1.84802 2.32277 0.288911C2.68629 -0.0806055 3.30688 -0.0970411 3.65228 0.247538C4.03562 0.629523 4.04411 1.21837 3.6619 1.60829C2.76669 2.52074 2.18064 3.59869 1.97567 4.86196C1.65914 6.81495 2.15573 8.54522 3.46372 10.0357C3.57584 10.1633 3.7055 10.2743 3.7995 10.4183C4.06336 10.823 3.99202 11.3393 3.62567 11.6464C3.26611 11.9479 2.70611 11.9304 2.37146 11.5988C1.39471 10.6331 0.717502 9.48714 0.326802 8.1706C0.107671 7.43327 -0.00161203 6.6795 -0.00047957 5.54885Z"
      fill={fill}
    />
    <path
      d="M12.7203 5.94019C12.6966 7.23406 12.243 8.34941 11.3268 9.27093C10.9973 9.60248 10.4571 9.63081 10.0845 9.34121C9.70515 9.04593 9.60889 8.5166 9.85803 8.10798C9.93731 7.97763 10.0477 7.87334 10.1445 7.75773C11.0947 6.61744 11.0437 5.06344 10.02 3.95545C9.62531 3.5287 9.61852 2.96535 10.003 2.58677C10.3552 2.23936 10.9616 2.23539 11.3093 2.5828C12.2362 3.50773 12.6999 4.62874 12.7203 5.94019Z"
      fill={fill}
    />
    <path
      d="M3.28066 5.92603C3.30161 4.6435 3.74157 3.52871 4.64131 2.60889C4.91933 2.32495 5.27096 2.24788 5.64581 2.37709C6.03254 2.51028 6.23809 2.81008 6.27376 3.20794C6.29867 3.48847 6.1826 3.73104 5.98272 3.93393C5.58126 4.34086 5.31456 4.82315 5.21151 5.38763C5.0439 6.30519 5.27153 7.11619 5.88532 7.81896C5.9629 7.90737 6.04274 7.99578 6.10729 8.09382C6.37058 8.49508 6.28622 9.04425 5.92269 9.33386C5.53992 9.6382 4.98785 9.60306 4.64698 9.25508C3.8837 8.47638 3.43807 7.54465 3.30444 6.46331C3.29368 6.37433 3.28576 6.28478 3.28236 6.19524C3.27783 6.10569 3.28066 6.01615 3.28066 5.92603Z"
      fill={fill}
    />
    <path
      d="M7.94025 4.50359C8.68258 4.44749 9.35866 5.0556 9.42321 5.836C9.48663 6.60507 8.87906 7.29196 8.08011 7.35601C7.34967 7.41438 6.63282 6.80853 6.58016 6.08877C6.51618 5.22165 7.07618 4.56877 7.94025 4.50359Z"
      fill={fill}
    />
  </svg>
);

export default LiveIcon;
