import cls from 'classnames';
import { FC, useRef } from 'react';

import Portal from '@/components/portal';
import useLockBodyScroll from '@/lib/hooks/use-lock-body-scroll';
import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';

import CloseIcon from './CloseIcon';

interface ContactFormModalProps {
  onClose?: () => void;
}

const ContactFormModal: FC<ContactFormModalProps> = ({ children, onClose }) => {
  useLockBodyScroll();
  const contactModalRef = useRef<HTMLDivElement>(null);
  const modalBackdropRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: MouseEvent | undefined) => {
    const clickedOnBackdrop = modalBackdropRef.current?.contains(
      e?.target as HTMLElement,
    );

    if (onClose && clickedOnBackdrop) onClose();
  };

  useOutsideClickNotifier([contactModalRef], handleOutsideClick);

  return (
    <Portal>
      <div
        className={cls(
          'fixed inset-x-0 bottom-0 top-16 z-60',
          'bg-black bg-opacity-70 overflow-hidden',
        )}
        ref={modalBackdropRef}
      >
        <div className="md:content-box-centered h-full flex justify-center items-center">
          <div
            ref={contactModalRef}
            className="relative w-screen md:w-full mx-auto h-full md:h-auto md:max-h-full overflow-auto"
          >
            <div
              className={cls(
                'md:rounded-4 bg-black-light h-full md:h-auto md:max-h-full',
              )}
            >
              <div className="absolute block w-full z-70">
                <CloseIcon onClose={onClose} />
              </div>

              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ContactFormModal;
