import { BLOCKS } from '@contentful/rich-text-types';
import React, { FC } from 'react';

import RichTextRenderer from '@/components/rich-text-renderer';
import { InfoPageContentEntry } from '@/types/views/sections';

import InfoPageFileDropdown from '../info-page-file-dropdown/info-page-file-dropdown';
import styles from './styles';

const InfoPageContent: FC<InfoPageContentEntry> = ({
  content,
  mediaFiles,
  mediaDownloadText,
}) => (
  <div className={styles.contentContainer}>
    <div className={styles.textContainer}>
      {mediaFiles && (
        <InfoPageFileDropdown
          files={mediaFiles}
          downloadText={mediaDownloadText}
        />
      )}

      {content && (
        <RichTextRenderer
          body={content}
          options={{
            renderNode: {
              [BLOCKS.HEADING_1]: (_, children) => (
                <h2 className={styles.heading1}>{children}</h2>
              ),
              [BLOCKS.HEADING_2]: (_, children) => (
                <h3 className={styles.heading2}>{children}</h3>
              ),
              [BLOCKS.PARAGRAPH]: (_, children) => (
                <p className={styles.paragraph}>{children}</p>
              ),
            },
          }}
        />
      )}
    </div>
  </div>
);

export default InfoPageContent;
