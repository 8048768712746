import cls from 'classnames';

import AttentionIcon from '@/components/icons/attention-icon';
import Image from '@/components/image';
import { NavItemEntryWithIcon } from '@/types/views/generic';

interface MobileBaseTileProps {
  navItem: NavItemEntryWithIcon;
  openModal?: (event: React.MouseEvent) => void;
  showIcon?: boolean;
}

const MobileBaseTile = ({
  navItem,
  openModal,
  showIcon,
}: MobileBaseTileProps) => (
  <div
    onClick={openModal}
    className={cls('flex items-center my-4', openModal && 'cursor-pointer')}
  >
    <div
      className={cls(
        'w-16 h-16 flex-shrink-0 relative',
        'flex items-center lg: justify-center',
      )}
    >
      {showIcon && (
        <AttentionIcon className="absolute z-10 top-0 right-0 w-6 h-6 text-white fill-red" />
      )}
      {navItem?.icon && (
        <Image
          src={navItem.icon.src}
          alt={navItem.link.text}
          useIntrinsicSize
          width={80}
          height={80}
        />
      )}
    </div>

    <div className={cls('text-h1 uppercase ml-3')}>{navItem.link.text}</div>
  </div>
);

export default MobileBaseTile;
