import { FC } from 'react';

import VideoContainer, {
  VideoContainerProps,
} from '@/components/video-container';
import { EntryComponentProps } from '@/types/views/generic';
import { VideoEntry } from '@/types/views/sections';

type VideoSectionProps = EntryComponentProps<VideoEntry> & VideoContainerProps;

const VideoSection: FC<VideoSectionProps> = (props) => (
  <VideoContainer {...props} />
);

export default VideoSection;
