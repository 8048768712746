import { FC, useEffect, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { updateAds } from '@/components/advertisement';
import { MINIMUM_NUMBER_OF_VISIBLE_VIDEOS } from '@/lib/constants';
import {
  AdComponentProps,
  enrichMappedWatchExperienceWithAds,
} from '@/lib/enrich-page-with-ads';
import getWatchExperienceAdPositionMap from '@/lib/get-watch-experience-ad-position-map';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useLoadContentInfinitely from '@/lib/hooks/use-load-content-infinitely';
import { isNotNil, notNull } from '@/lib/utils';
import { ComponentEntry, TeaserGroup } from '@/types/views/generic';
import { WatchOverviewItemsType } from '@/types/views/sections';

import Grid from '../../content-feed/components/grid';
import renderEntryTeaser from '../../content-feed/lib/render-entry-teaser';

interface InfiniteScrollTeasersGridProps {
  itemsType: WatchOverviewItemsType;
  selectedFilterTags?: string[];
}

interface ContentData {
  href: string;
  teaserGroup: TeaserGroup;
  text: string;
}

export interface WatchComponentEntry extends ComponentEntry {
  appearInWatchExperience: boolean;
}

export const contentDataByType: Record<WatchOverviewItemsType, ContentData> = {
  video: {
    href: '/watch/videos',
    teaserGroup: TeaserGroup.Videos,
    text: 'Videos',
  },
  meme: {
    href: '/watch/memes',
    teaserGroup: TeaserGroup.Memes,
    text: 'Memes',
  },
};

const PAGE_SIZE = '12';

const isAdComponent = (
  component: WatchComponentEntry | AdComponentProps,
): boolean => !!component?.componentType.toLocaleLowerCase().endsWith('ad');

const InfiniteScrollTeasersGrid: FC<InfiniteScrollTeasersGridProps> = ({
  itemsType,
  selectedFilterTags,
}) => {
  const [adStringMap, setAdStringMap] = useState<string>('');
  const { currentBreakpoint, isMobile } = useBreakpoint();
  const { isLoading, error, data, loadMore } = useLoadContentInfinitely({
    itemsType,
    pageSize: PAGE_SIZE,
    tagIdsToInclude: selectedFilterTags,
    publishDateOrderType: 'desc',
    configuration: {
      initialSize: 0,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateFirstPage: false,
    },
  });

  const initialData = useMemo(
    () =>
      data?.flatMap((pageEntries) =>
        (pageEntries as WatchComponentEntry[]).filter(
          (e) => e.appearInWatchExperience,
        ),
      ) || [],
    [data],
  );

  const enrichDataWithAds = enrichMappedWatchExperienceWithAds(
    initialData,
    adStringMap,
  ).filter(isNotNil);

  const mappedTeasersWithAds = useMemo(() => {
    // Do not render ads only
    if (enrichDataWithAds.every(isAdComponent)) return [];

    return enrichDataWithAds.map(renderEntryTeaser).filter(notNull);
  }, [enrichDataWithAds]);

  useEffect(() => {
    const adPositionMap = getWatchExperienceAdPositionMap(
      initialData.length,
      adStringMap,
    );

    if (adPositionMap) {
      setAdStringMap(adPositionMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData.length]);

  useEffect(() => {
    if (mappedTeasersWithAds.length) {
      updateAds('Watch', isMobile, currentBreakpoint);
    }
  }, [mappedTeasersWithAds.length, isMobile, currentBreakpoint]);

  useEffect(() => {
    if (
      !isLoading &&
      (!initialData.length ||
        initialData.length <= MINIMUM_NUMBER_OF_VISIBLE_VIDEOS)
    ) {
      loadMore();
    }
  }, [isLoading, initialData.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid
        teaserGroup={contentDataByType[itemsType].teaserGroup}
        teasers={mappedTeasersWithAds}
        isEmpty={mappedTeasersWithAds.length === 0}
        isLoading={isLoading}
        isError={!!error}
      />

      <InView
        as="div"
        rootMargin="0px 0px 50% 0px"
        onChange={(isInView) => {
          if (isInView && !isLoading) loadMore();
        }}
      >
        <div />
      </InView>
    </>
  );
};

export default InfiniteScrollTeasersGrid;
