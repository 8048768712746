import { FC } from 'react';

import AudioProgressBar from '@/components/audio-progress-bar';
import LiveIcon from '@/components/icons/live-icon';
import Image from '@/components/image';
import Skeleton from '@/components/skeleton';
import { AudioTimeUpdateEvent } from '@/lib/hooks/use-audio-player';
import { formatDate } from '@/lib/utils';
import { PlaylistItem as PlaylistItemData } from '@/types/views/generic';

import styles from './styles';

interface PlaylistItemProps extends PlaylistItemData {
  isLive: boolean;
  isActive: boolean;
  onPlay: () => void;
  onPause: () => void;
  audioTimeUpdateEvent?: AudioTimeUpdateEvent;
}

const PlaylistItem: FC<PlaylistItemProps> = ({
  artist,
  title,
  imageUrl,
  audioUrl,
  playFrom,
  isLive,
  isActive,
  onPlay,
  onPause,
  audioTimeUpdateEvent,
}) => {
  const formattedTime = formatDate(playFrom, 'HH:mm');

  return (
    <li className={styles.itemWrapper(isActive)}>
      <div className={styles.leftSide}>
        <div className={styles.duration(true)}>{formattedTime}</div>

        <div className={styles.imageWrapper}>
          {imageUrl ? <Image src={imageUrl} alt={title} /> : <Skeleton />}
        </div>

        <div className={styles.artistTitleWrapper}>
          <div className={styles.trackDurationArtist}>
            <span className={styles.duration(false)}>{formattedTime}</span>
            {artist ? <span className={styles.artist}>{artist}</span> : null}
          </div>

          <div className={styles.title}>{title}</div>
        </div>
      </div>

      <div className={styles.rightSide}>
        {isLive && (
          <div className={styles.live}>
            <LiveIcon />

            <span className={styles.liveText}>Live</span>
          </div>
        )}

        {audioUrl && (
          <AudioProgressBar
            className={styles.audioProgressBar}
            onPlay={onPlay}
            onPause={onPause}
            audioTimeUpdateEvent={audioTimeUpdateEvent}
            isActive={isActive}
            borderWidth={2}
          />
        )}
      </div>
    </li>
  );
};

export default PlaylistItem;
