import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import ChipSelect from '@/components/chip-select';
import LoadingIcon from '@/components/icons/loading-icon';
import PlaylistItem from '@/components/playlist/components/playlist-item';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import useLoadPlayouts from '@/lib/hooks/use-load-playouts';
import useVariables from '@/lib/hooks/use-variables';
import { formatDate } from '@/lib/utils';
import {
  selectAudioPlayerIsPlaying,
  selectPlayerCurrentAudio,
} from '@/store/slices/player';
import { PlaylistItem as PlaylistItemProps } from '@/types/views/generic';

import styles from './styles';

enum PlaylistType {
  Last,
  Next,
}

const filterFuturePlaylist = (playlist?: PlaylistItemProps[] | null) => {
  if (!playlist) {
    return [];
  }

  const currentDate = new Date();
  const formattedCurrentDate = formatDate(currentDate.toString(), 'HH:mm');

  return playlist.filter((playlistItem) => {
    const formattedPlaylistItemDate = formatDate(
      playlistItem.playFrom,
      'HH:mm',
    );

    return formattedPlaylistItemDate > formattedCurrentDate;
  });
};

interface PlaylistProps {
  playlistId: string;
}

// todo also needs to be changed
const Playlist: FC<PlaylistProps> = ({ playlistId }) => {
  const variables = useVariables();

  const [activePlaylist, setActivePlaylist] = useState<number>(
    PlaylistType.Last,
  );

  const currentAudio = useSelector(selectPlayerCurrentAudio);
  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);

  // useMemo used for loading more playlist items after entering the site
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = useMemo(() => new Date(), [activePlaylist]);

  const isFuturePlaylist = activePlaylist === PlaylistType.Next;

  const {
    isLoading,
    data: playoutsData,
    loadMore,
    isAllDataLoaded,
  } = useLoadPlayouts(playlistId, currentDate, isFuturePlaylist);

  const onCurrentPlaylistClick = (playlistType: PlaylistType) => {
    if (!isLoading) {
      setActivePlaylist(playlistType);
    }
  };

  const currentPlaylistList = isFuturePlaylist
    ? filterFuturePlaylist(playoutsData)
    : playoutsData;

  const hasPlaylistList = !!currentPlaylistList?.length;

  const { onPlay, onPause, changeEvent } = useAudioPlayer({
    autoPlaylist: currentPlaylistList,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.heading}>
          {getVariablePlainText(variables['playlist-title']) || 'Playlist'}
        </h2>

        <ChipSelect activeIndex={activePlaylist}>
          <button
            type="button"
            onClick={() => onCurrentPlaylistClick(PlaylistType.Last)}
          >
            {getVariablePlainText(variables.last) || 'Zuletzt'}
          </button>

          <button
            type="button"
            onClick={() => onCurrentPlaylistClick(PlaylistType.Next)}
          >
            {getVariablePlainText(variables.next) || 'Nächste'}
          </button>
        </ChipSelect>
      </div>

      {!isLoading && !hasPlaylistList && (
        <div className="flex w-full justify-center items-center border-b-1 border-black-light h-20">
          {getVariablePlainText(variables['content-not-loaded']) ||
            'Inhalt konnte nicht geladen werden.'}
        </div>
      )}

      {hasPlaylistList && (
        <ul className={styles.listWrapper}>
          {currentPlaylistList.map((singlePlaylist, index) => (
            <PlaylistItem
              {...singlePlaylist}
              key={index}
              isLive={!isFuturePlaylist && index === 0}
              isActive={
                currentAudio?.audioUrl === singlePlaylist?.audioUrl &&
                isAudioPlaying
              }
              onPlay={() => onPlay(index, singlePlaylist)}
              onPause={() => onPause()}
              audioTimeUpdateEvent={changeEvent}
            />
          ))}
        </ul>
      )}

      {isLoading && (
        <div className="flex w-full justify-center items-center mt-6">
          <LoadingIcon className="w-20 h-20" />
        </div>
      )}

      {!isFuturePlaylist && hasPlaylistList && !isAllDataLoaded && !isLoading && (
        <div className="flex justify-end mt-10">
          <NormalButton
            text={
              getVariablePlainText(variables['playlist-load-more']) ||
              'Mehr laden'
            }
            size="XL"
            onClick={loadMore}
          />
        </div>
      )}
    </div>
  );
};

export default Playlist;
