import cls from 'classnames';
import { FC, useEffect, useState } from 'react';

import { getTime } from '@/lib/utils';

const commonTimerStyles =
  'font-extraBold text-white text-border drop-shadow-big';

interface CountdownProps {
  endDate: Date;
  ticksPerSecond?: number;
  onFinish?: () => void;
  hasStyledDate?: boolean;
}

const Countdown: FC<CountdownProps> = ({
  endDate,
  ticksPerSecond = 1,
  onFinish = () => undefined,
  hasStyledDate = false,
}) => {
  const [timeUntilEnd, setTimeUntilEnd] = useState<number>(
    (new Date(endDate).getTime() - Date.now()) / 1000,
  ); // [s]

  const renderCharactersUniformlyWide = (text: string) =>
    text.split('').map((char, index) => (
      <div
        key={`${char}-${index}`}
        className={cls(hasStyledDate && 'w-10 md:w-16')}
      >
        {char}
      </div>
    ));

  const renderTime = (time: number, isMobile = false) => {
    const timeLeft = getTime(time);

    const [hours, minutes, seconds] = timeLeft.split(':');

    return (
      <>
        <div className="flex justify-center">
          {renderCharactersUniformlyWide(hours)}
          <div className={cls(hasStyledDate && 'w-10 md:w-8')}>
            {isMobile ? 'h' : ':'}
          </div>
        </div>
        <div className="flex justify-center">
          {renderCharactersUniformlyWide(minutes)}
          <div className={cls(hasStyledDate && 'w-10 md:w-8')}>
            {isMobile ? 'm' : ':'}
          </div>
        </div>
        <div className="flex justify-center">
          {renderCharactersUniformlyWide(seconds)}
          {isMobile && <div className={cls(hasStyledDate && 'w-10')}>s</div>}
        </div>
      </>
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (timeUntilEnd > 0) {
        const newTime = timeUntilEnd - 1 / ticksPerSecond;

        setTimeUntilEnd(newTime);

        if (newTime <= 0) {
          onFinish();
        }
      }
    }, 1000 / ticksPerSecond);

    return () => clearTimeout(timeoutId);
  }, [timeUntilEnd, onFinish, ticksPerSecond]);

  return (
    <div className={cls(hasStyledDate && 'mt-4 text-center')}>
      <span
        className={cls(
          hasStyledDate
            ? `hidden md:flex justify-center text-[95px] ${commonTimerStyles}`
            : 'flex items-center',
        )}
      >
        {renderTime(timeUntilEnd)}
      </span>

      <div
        className={cls(
          hasStyledDate
            ? `flex flex-col md:hidden text-[56px] leading-[70px] ${commonTimerStyles}`
            : 'hidden',
        )}
      >
        {renderTime(timeUntilEnd, true)}
      </div>
    </div>
  );
};

export default Countdown;
