import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';
import {
  BasicMetadata,
  Metadata,
  selectPlayerBasicMetadata,
  selectPlayerCurrentChannelId,
  selectPlayerIsPlaying,
  selectPlayerLibHasLoaded,
  selectPlayerStationById,
} from '@/store/slices/player';
import { Channel } from '@/types/views/generic';

import getLocalStationPlayer from './get-local-station-player';

const useStationPlayer = (displayedStationId: string) => {
  const isPlayerLibLoaded = useSelector(selectPlayerLibHasLoaded);
  const isPlayerPlaying = useSelector(selectPlayerIsPlaying);
  const playoutBasicMetadata = useSelector(selectPlayerBasicMetadata);
  const currentChannelId = useSelector(selectPlayerCurrentChannelId);
  const displayedStation = useSelector<RootState, Channel | undefined>(
    (state) => selectPlayerStationById(state, displayedStationId),
  );

  const isDisplayedStationPlaying = currentChannelId === displayedStationId;

  const [playoutMetadata, setPlayoutMetadata] = useState<BasicMetadata>({
    title: null,
    artists: [],
  });

  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState(false);

  const onMetadata = useCallback((metadata: Metadata) => {
    const artistNames = (() => {
      if (metadata.artists) {
        return metadata.artists?.map((a) => a.name);
      }

      return metadata.artist ? [metadata.artist] : null;
    })();

    setIsMetaDataLoaded(true);
    setPlayoutMetadata({
      title: metadata.title ?? null,
      artists: artistNames,
    });
  }, []);

  const localPlayer = useMemo(
    () =>
      getLocalStationPlayer(
        isPlayerLibLoaded,
        displayedStation || null,
        onMetadata,
      ),
    [isPlayerLibLoaded, displayedStation, onMetadata],
  );

  useEffect(() => {
    if (localPlayer) {
      localPlayer.audioElement.volume = 0;
      localPlayer.audioElement.muted = true;
      localPlayer.play();
    }

    return () => {
      localPlayer?.stop();
      localPlayer?.detachAudioElement();
    };
  }, [localPlayer]);

  const currentMetadata =
    isDisplayedStationPlaying && isPlayerPlaying
      ? playoutBasicMetadata
      : playoutMetadata;

  return {
    isDisplayedStationPlaying,
    basicPlayoutMetadata: currentMetadata,
    isMetaDataLoaded,
  };
};

export default useStationPlayer;
