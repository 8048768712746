import cls from 'classnames';
import { FC } from 'react';
import SwiperCore, { A11y, Autoplay, Lazy, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EntryComponentProps } from '@/types/views/generic';
import { SliderSectionEntry } from '@/types/views/sections';

import SliderItem from './components/slider-item';
import styles from './styles';

SwiperCore.use([Pagination, A11y, Autoplay, Lazy]);

const SLIDE_DURATION = 5000;
const TRANSITION_SPEED = 400;

const Slider: FC<EntryComponentProps<SliderSectionEntry>> = ({
  sliderItems,
  deactivateAdSlots,
}) => (
  <div className={cls(styles.wrapper, deactivateAdSlots && 'md:ml-auto')}>
    <Swiper
      tag="section"
      wrapperTag="ul"
      slidesPerView={1}
      spaceBetween={0}
      autoplay={{ delay: SLIDE_DURATION }}
      loop
      speed={TRANSITION_SPEED}
      pagination={{
        clickable: true,
      }}
      preloadImages={false}
      lazy={{
        loadOnTransitionStart: true,
      }}
    >
      {sliderItems.map((sliderItem, i) => (
        <SwiperSlide tag="li" key={i}>
          <SliderItem sliderItem={sliderItem} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default Slider;
