import cls from 'classnames';
import { Asset } from 'contentful';
import React, { FC, useRef, useState } from 'react';

import { ChevronDownIcon } from '@/components/icons/chevron-icon';
import PdfIcon from '@/components/icons/pdf-icon';
import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';

export type InfoPageFileDropdownProps = {
  files: Asset[];
  downloadText?: string;
};

const InfoPageFileDropdown: FC<InfoPageFileDropdownProps> = ({
  files,
  downloadText,
}) => {
  const fileDropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event?: MouseEvent) => {
    if (
      isOpen &&
      !fileDropdownRef?.current?.contains(event?.target as HTMLElement)
    ) {
      setIsOpen(false);
    }
  };

  useOutsideClickNotifier([fileDropdownRef], handleClickOutside);

  return (
    <div className="max-w-[335px] relative mb-10">
      <button className="flex items-center" onClick={onToggleOpen}>
        <div className="bg-black w-8 h-8 rounded-4 flex basis-8 grow-0 shrink-0">
          <ChevronDownIcon
            className={cls(
              'transform transition-transform m-auto',
              isOpen ? 'rotate-180' : 'rotate-0',
            )}
            fill="white"
          />
        </div>
        {downloadText && (
          <div className="text-xs ml-3 text-left">{downloadText}</div>
        )}
      </button>

      {isOpen && (
        <div
          className={cls(
            'w-60  py-2 rounded-2 bg-red-light text-black',
            'shadow-[5px_5px_20px_rgba(0,0,0,0.4)]',
            'absolute left-0 top-[calc(100%+1rem)]',
          )}
          ref={fileDropdownRef}
        >
          <div
            className={cls(
              'absolute bottom-full left-4 w-0 h-0 box-content',
              'border-[7px] border-transparent border-b-[#FAF3F8]',
            )}
          />
          {files.map((file) => (
            <a
              download
              href={file.fields.file.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex w-full p-2 hover:bg-red-light-darker">
                <div className="mr-2">
                  <PdfIcon />
                </div>
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                  {file.fields.title}
                </div>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default InfoPageFileDropdown;
