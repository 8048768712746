import { Document } from '@contentful/rich-text-types';
import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';
import QRCode from 'react-qr-code';
import theme from 'tailwind/theme';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import Icon from '@/components/icon';
import ContactRadioIcon from '@/components/icons/contact-radio';
import Popover, { Placement } from '@/components/popover';
import getVariablePlainText from '@/lib/get-variable-plain-text';

interface VoiceMessageProps {
  variables: Record<string, Document>;
  whatsappNumber: string;
}

const contentStyles = css`
  min-width: 470px;
`;

const roundedStyles = css`
  border-radius: 4px;
`;

const VoiceMessage: FC<VoiceMessageProps> = ({ variables, whatsappNumber }) => {
  const whatsAppUrl = `https://wa.me/${whatsappNumber}`;

  const onClick = () => window.open(whatsAppUrl, '_blank');

  const renderContent = (togglePopover: () => void) => (
    <div className="py-12 px-8 text-center">
      <div className="mb-6">
        <div
          className={cls(
            roundedStyles,
            'bg-white w-50 h-50 flex justify-center items-center mx-auto',
          )}
        >
          <QRCode value={whatsAppUrl} size={182} level="L" />
        </div>
      </div>

      <h2 className="text-h4 mb-4">
        {getVariablePlainText(variables.whatsapp_overlay_title) ||
          'Persönliche Nachricht'}
      </h2>

      <p className="text-body1 text-gray-3 mb-6">
        {getVariablePlainText(variables.whatsapp_overlay_description) ||
          'Scanne den QR Code um uns deine persönliche Sprachnachricht, von maximal 30 Sekunden, zu senden.'}
      </p>

      <CustomLink
        href="/kontakt"
        className="text-body2 text-red-medium underline font-semibold"
      >
        {getVariablePlainText(variables.whatsapp_overlay_cta) ||
          'Alle Kontaktmöglichkeiten'}
      </CustomLink>

      <div
        className="text-body2 underline mt-2 cursor-pointer font-semibold"
        onClick={togglePopover}
      >
        {getVariablePlainText(variables.whatsapp_overlay_close) || 'Schliessen'}
      </div>
    </div>
  );

  const renderPopover = (
    placement: Placement,
    className = 'hidden lg:block',
  ) => (
    <Popover
      renderContent={renderContent}
      className={className}
      contentClassName={cls(contentStyles, 'lg:mt-2')}
      triangleClassName="text-black-light teair:text-air-deepBlue tesn:text-esn-secondary-dark -top-2"
      placement={placement}
    >
      <NormalButton
        color="white"
        text={
          <div className="flex gap-3">
            <ContactRadioIcon />

            <span>
              {getVariablePlainText(variables['voice-message-button-text']) ||
                'Sprachnachricht'}
            </span>
          </div>
        }
        className="text-body2"
      />
    </Popover>
  );

  return (
    <div>
      {renderPopover('bottomLeft', 'hidden md:block lg:hidden')}
      {renderPopover('bottomCenter')}

      <Icon
        isCircle
        isButton
        element={<ContactRadioIcon fill={theme.colors.black.DEFAULT} />}
        className="md:hidden bg-white p-3"
        onClick={onClick}
      />
    </div>
  );
};

export default VoiceMessage;
