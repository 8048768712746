import { FC, useState } from 'react';

import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useLoadContentInfinitely from '@/lib/hooks/use-load-content-infinitely';
import {
  notNull,
  useIsomorphicLayoutEffect as useLayoutEffect,
} from '@/lib/utils';
import { colsPerTeaserGroup } from '@/middleware/mappers/sections/content-feed/lib/helpers';
import {
  ContentFeedEntryCmsDisplayStyle,
  ContentFeedEntryCmsEntryTypeGroup,
} from '@/types/cms';
import { ContentFeedSectionEntry } from '@/types/views/sections';

import Carousel from './components/carousel';
import Container from './components/container';
import Grid from './components/grid';
import SoundpiecePlaylist from './components/soundpieceplaylist';
import renderEntryTeaser from './lib/render-entry-teaser';

const ContentFeed: FC<ContentFeedSectionEntry> = ({
  id,
  title,
  hideTitle,
  link,
  teaserGroup,
  entries,
  totalCount,
  contentFeedLayout,
  numberOfRows = 1,
  tagsToInclude,
  entryTypeGroup,
  showLoadMore = false,
  hasInvertedThemeColor,
  deactivateAdSlots,
}) => {
  const [gridItemsToRender, setGridItemsToRender] =
    useState<number>(numberOfRows);
  const { currentBreakpoint } = useBreakpoint();
  const teasers = entries
    .map((entry) => renderEntryTeaser({ hasInvertedThemeColor, ...entry }))
    .filter(notNull);

  const arrayOfTagsToInclude = tagsToInclude?.map(
    (idObject) => idObject.contentfulEntityId,
  );

  const fetchedEntriesIds = entries.map((entry) => entry.id);

  const itemsType =
    entryTypeGroup === ContentFeedEntryCmsEntryTypeGroup.Soundpieces
      ? 'soundpiece'
      : 'video';

  const {
    isLoading,
    data,
    size: currentPage,
    loadMore,
  } = useLoadContentInfinitely({
    itemsType,
    pageSize: String(gridItemsToRender),
    publishDateOrderType: 'desc' as const,
    tagIdsToInclude: arrayOfTagsToInclude,
    idsToExclude: fetchedEntriesIds,
    configuration: {
      initialSize: 0,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  // Prevents hydration issues
  useLayoutEffect(
    () =>
      setGridItemsToRender(
        colsPerTeaserGroup[teaserGroup][currentBreakpoint] * numberOfRows,
      ),
    [currentBreakpoint, teaserGroup, numberOfRows],
  );

  const loadedData = data?.flat()?.map(renderEntryTeaser) || [];

  const allTeasers = [...teasers, ...loadedData].filter(notNull);
  const isAllDataLoaded =
    allTeasers.length >= totalCount ||
    (currentPage + 1) * gridItemsToRender >= totalCount;

  if (!teasers.length) {
    return null;
  }

  if (entryTypeGroup === ContentFeedEntryCmsEntryTypeGroup.Soundpieces) {
    return (
      <SoundpiecePlaylist
        hideTitle={hideTitle}
        link={link}
        teasers={allTeasers}
        title={title}
        isLoading={isLoading}
        loadMore={loadMore}
        isAllDataLoaded={isAllDataLoaded}
        deactivateAdSlots={deactivateAdSlots}
      />
    );
  }

  const isChannelEntry =
    entryTypeGroup === ContentFeedEntryCmsEntryTypeGroup.Channels;

  if (contentFeedLayout.mobile === ContentFeedEntryCmsDisplayStyle.Grid) {
    return (
      <Container
        hideTitle={hideTitle}
        link={link}
        title={title}
        mobile={
          <Grid
            teaserGroup={teaserGroup}
            teasers={allTeasers.slice(0, gridItemsToRender)}
          />
        }
        desktop={
          <Grid
            teaserGroup={teaserGroup}
            teasers={allTeasers.slice(0, gridItemsToRender)}
          />
        }
        hasInvertedThemeColor={hasInvertedThemeColor}
        deactivateAdSlots={deactivateAdSlots}
      />
    );
  }

  return (
    <Container
      hideTitle={hideTitle}
      link={link}
      title={title}
      hasInvertedThemeColor={hasInvertedThemeColor}
      mobile={
        <Carousel
          id={id}
          teaserGroup={teaserGroup}
          teasers={allTeasers}
          isMobile
          showLoadMore={
            showLoadMore &&
            (isChannelEntry ? false : isLoading || !isAllDataLoaded)
          }
          isLoading={isLoading}
          onLoadMore={loadMore}
          hasInvertedThemeColor={hasInvertedThemeColor}
        />
      }
      desktop={
        <Carousel
          id={id}
          teaserGroup={teaserGroup}
          teasers={allTeasers}
          isMobile={false}
          isLoading={isLoading}
          showLoadMore={
            showLoadMore &&
            (isChannelEntry ? false : isLoading || !isAllDataLoaded)
          }
          onLoadMore={loadMore}
          hasInvertedThemeColor={hasInvertedThemeColor}
        />
      }
      deactivateAdSlots={deactivateAdSlots}
    />
  );
};

export default ContentFeed;
