import cls from 'classnames';
import React, { FC } from 'react';

import { notNull } from '@/lib/utils';

import styles from './styles';

interface ChipSelectProps {
  activeIndex?: number;
  className?: string;
}

const ChipSelect: FC<ChipSelectProps> = ({
  activeIndex,
  children,
  className,
}) => (
  <ul className={cls(styles.wrapper, className)}>
    {React.Children.map(children, (child, index) => {
      const itemClasses = styles.item(activeIndex === index);

      return React.isValidElement(child) ? (
        <li key={index}>
          {React.cloneElement(child, {
            className: itemClasses,
          })}
        </li>
      ) : (
        <li className={itemClasses} key={index}>
          {child}
        </li>
      );
    })?.filter(notNull)}
  </ul>
);

export default ChipSelect;
