import { css } from '@emotion/css';
import cls from 'classnames';
import { format } from 'date-fns';
import { FC } from 'react';
import { screens } from 'tailwind/theme';

// import MenuDotsIcon from '@/components/icons/menu-dots-icon';
import Image from '@/components/image';
import Share from '@/components/share';
import TagChip from '@/components/tag-chip';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import { isNotNil } from '@/lib/utils';
import { Meme as MemeEntry } from '@/types/views/generic';

const getOverline = (...elements: (string | undefined | null)[]) =>
  elements.filter(isNotNil).join(' / ');

interface MemeProps extends Omit<MemeEntry, 'componentType'> {
  className?: string;
}

const memeWrapper = css`
  max-width: 335px;

  @media (min-width: ${screens.md}) {
    max-width: 570px;
  }
`;

const Meme: FC<MemeProps> = ({
  className = '',
  id,
  description,
  image,
  tags,
  publishDate,
  contentCategories,
}) => {
  const formattedPublishDate = publishDate
    ? format(new Date(publishDate), 'dd.MM.yyyy')
    : null;

  const overline = getOverline(formattedPublishDate, contentCategories?.[0]);

  return (
    <div className={cls(memeWrapper, className, 'mx-auto')}>
      <div className={'bg-black-light'}>
        <Image src={image} alt={description} className="aspect aspect-1-1" />
      </div>

      <div className="">
        <div className="flex justify-between items-center mt-2 md:mt-4">
          <div className="text-overline text-gray-2">{overline}</div>

          <div className="flex">
            <div className="flex md:items-center">
              <Share
                urlToShare={`${getBaseUrlIfBrowser()}/watch/memes/${id}`}
              />
            </div>

            {/* <button> */}
            {/*  <MenuDotsIcon className="block md:hidden" /> */}
            {/* </button> */}
          </div>
        </div>

        <div className="flex flex-col">
          {description && (
            <div className="block mt-2 md:mt-4 text-body2">{description}</div>
          )}

          {!!tags.length && (
            <ul className="flex flex-wrap gap-x-4 gap-y-2 mt-2 md:mt-6">
              {tags.map((tag) => (
                <li key={tag.name}>
                  <TagChip tag={tag} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Meme;
