import { css } from '@emotion/css';
import cls from 'classnames';
import { screens } from 'tailwind/theme';

import { GridEntry } from '@/types/views/sections';

/**
 * Map structure:
 * [<chosen number of columns in CMS (lg+)>]: <column count on md>
 */
const columnsCountScalingMap = new Map([
  [1, 2],
  [2, 2],
  [3, 2],
  [4, 2],
  [5, 3],
  [6, 3],
]);

const columnStyles = (
  numberOfColumns: GridEntry['numberOfColumns'],
  dynamicColumnsCountScaling = false,
) => css`
  @media (min-width: ${screens.md}) {
    grid-template-columns: repeat(
      ${dynamicColumnsCountScaling
        ? columnsCountScalingMap.get(numberOfColumns)
        : numberOfColumns},
      minmax(0, 1fr)
    );
  }

  @media (min-width: ${screens.xl}) {
    grid-template-columns: repeat(${numberOfColumns}, minmax(0, 1fr));
  }
`;

const styles = {
  title: cls(
    'text-white uppercase text-h1 pb-4 md:pb-16',
    'teair:text-air-deepBlue tesn:text-white teds:pb-6',
  ),
  container: (
    numberOfColumns: GridEntry['numberOfColumns'],
    dynamicColumnsCountScaling = false,
  ) =>
    cls(
      `grid gap-x-12 gap-y-10 teds:gap-3 md:teds:gap-x-5`,
      numberOfColumns > 1 &&
        `'grid-cols-2' ${columnStyles(
          numberOfColumns,
          dynamicColumnsCountScaling,
        )}`,
    ),
};

export default styles;
