import { css } from '@emotion/css';
import cls from 'classnames';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import Hero from '@/components/hero';
import Image from '@/components/image';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import heroHeight from '@/lib/set-hero-height';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { HeroLivestreamSectionEntry } from '@/types/views/sections';

const dropShadow = css`
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.3);
`;

const HeroLivestreamSection: FC<HeroLivestreamSectionEntry> = ({
  title,
  background,
  backgroundMobile,
  teaserImage,
  teaserImageMobile,
  streamUrl,
}) => {
  const variables = useVariables();
  const buttonText = getVariablePlainText(variables['hero-livestream-cta']);
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);
  const [isTeaserVisible, setIsTeaserShown] = useState(!!teaserImage);

  const showLivestream = () => setIsTeaserShown(false);

  const StartButton = ({ className }: { className: string }) => (
    <NormalButton
      className={className}
      text={buttonText}
      {...buttonProps}
      onClick={showLivestream}
    />
  );

  return (
    <div className={heroHeight(10)}>
      <Hero background={background} backgroundMobile={backgroundMobile}>
        <div
          className={cls(
            'absolute top-1/3 md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 md:-translate-y-1/2 z-10',
            'w-full md:px-4 md:content-box',
          )}
        >
          <div className="relative mx-auto">
            <div className="flex flex-col items-center justify-center">
              <div
                className={cls(
                  'w-full rounded-2 overflow-hidden aspect aspect-16-9',
                  !isTeaserVisible && dropShadow,
                )}
              >
                {isTeaserVisible && (
                  <div>
                    {teaserImageMobile && (
                      <Image
                        className="md:hidden"
                        src={teaserImageMobile.src}
                        objectFit="fill"
                      />
                    )}

                    {teaserImage && (
                      <Image
                        className="hidden md:block"
                        src={teaserImage.src}
                        objectFit="fill"
                      />
                    )}

                    <div className="absolute inset-0 tesn:bg-esn-secondary-dark tesn:bg-opacity-60" />

                    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center md:pt-32">
                      <StartButton className="hidden md:block mt-6" />
                    </div>
                  </div>
                )}

                {!isTeaserVisible && (
                  <iframe
                    src={streamUrl}
                    frameBorder="0"
                    allowFullScreen
                    title={title}
                  />
                )}
              </div>

              {isTeaserVisible && <StartButton className="md:mt-6 md:hidden" />}
            </div>
          </div>
        </div>
      </Hero>
    </div>
  );
};

export default HeroLivestreamSection;
