import cls from 'classnames';

const styles = {
  wrapper: 'content-box mb-5',
  header: 'flex flex-col md:flex-row items-baseline mb-5',
  headerTitle: 'text-h2 text-pink',
  gridWrapper: 'pt-6 md:pt-12 overflow-x-hidden',
  loadingIcon: (teasersLength: number) =>
    cls(teasersLength > 0 && 'mt-4', 'w-12 h-12 text-center mx-auto'),
};

export default styles;
