import { FC } from 'react';

interface GridIconProps {
  className?: string;
  strokeColor?: string;
}

const GridIcon: FC<GridIconProps> = ({
  className = '',
  strokeColor = 'currentColor',
}) => (
  <div className={className}>
    <svg
      width="233"
      height="233"
      viewBox="0 0 233 233"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.00049"
        y="4"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="78.9998"
        y="4"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="154.001"
        y="4"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="79.0015"
        y="79"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="79.0012"
        y="154"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="154.001"
        y="79"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="154"
        y="154"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="4.00024"
        y="79"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
      <rect
        x="4"
        y="154"
        width="75"
        height="75"
        stroke={strokeColor}
        strokeWidth="8"
      />
    </svg>
  </div>
);

export default GridIcon;
