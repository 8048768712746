import React, { FC } from 'react';

import { Placement } from '@/components/popover';
import Share from '@/components/share';

// TODO: add like button when needed

interface SocialButtonsProps {
  urlToShare: string;
  placement?: Placement;
}

const SocialButtons: FC<SocialButtonsProps> = ({ urlToShare, placement }) => (
  <Share urlToShare={urlToShare} shareBoxPlacement={placement} />
);

export default SocialButtons;
