import cls from 'classnames';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import { ChevronLeftIcon } from '@/components/icons/chevron-icon';
import { SubNavigationEntry } from '@/types/views/generic';

import styles from './styles';

interface subNavigationProps {
  subNav: SubNavigationEntry;
}

const SubNavigation: FC<subNavigationProps> = ({ subNav }) => (
  <>
    <div className="max-w-full bg-black text-white">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {subNav.parentItem && (
            <CustomLink href={subNav.parentItem.link.href}>
              <button className={styles.backButton}>
                <ChevronLeftIcon />
                <span className={styles.backButtonText}>
                  {subNav.parentItem.link.text}
                </span>
              </button>
            </CustomLink>
          )}

          {subNav.subNavItems.length > 0 && (
            <div className="flex items-center">
              <div className="w-px h-8 bg-gray-4 mx-4" />

              {subNav.subNavItems?.map((item, index) => {
                const isCurrent = subNav.currentAsPath === item.link.href;

                return (
                  <CustomLink
                    key={index}
                    href={item.link.href}
                    className={cls('py-2 px-4', isCurrent && styles.current)}
                  >
                    {item.link.text}
                  </CustomLink>
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.shadow} />
      </div>
    </div>
  </>
);

export default SubNavigation;
