import { FC } from 'react';

import Hero from '@/components/hero';
import heroHeight from '@/lib/set-hero-height';
import { EntryComponentProps } from '@/types/views/generic';
import { HeroSectionEntry } from '@/types/views/sections';

import Foreground from './foreground';

const HeroSection: FC<EntryComponentProps<HeroSectionEntry>> = ({
  background,
  backgroundMobile,
  foreground,
  foregroundMobile,
  link,
  showButton,
  isForegroundFullWidth,
}) => (
  <div className={heroHeight(15)}>
    <Hero background={background} backgroundMobile={backgroundMobile}>
      {(foreground || foregroundMobile || (showButton && link)) && (
        <Foreground
          foreground={foreground}
          foregroundMobile={foregroundMobile}
          showButton={showButton}
          link={link}
          isForegroundFullWidth={isForegroundFullWidth}
        />
      )}
    </Hero>
  </div>
);

export default HeroSection;
