import cls from 'classnames';
import { FC } from 'react';

import CrossIcon from '@/components/icons/icon-cross';

interface CloseIconProps {
  onClose?: () => void;
}

const CloseIcon: FC<CloseIconProps> = ({ onClose }) => (
  <div
    className={cls(
      'fixed w-8 h-8 bg-white right-6 top-20 flex justify-center items-center rounded-16 cursor-pointer',
      'md:absolute md:top-4 md:right-4 md:z-10',
      'lg:right-6',
    )}
    onClick={onClose}
  >
    <CrossIcon />
  </div>
);

export default CloseIcon;
