import { Document } from '@contentful/rich-text-types';
import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';

import Chip from '@/components/chip';
import LocationIcon from '@/components/icons/location-icon';
import Popover, { DropdownMenuItem } from '@/components/popover';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import { ToggleData } from '@/types/views/generic';

interface PopoverChipSelectProps {
  activeLinkIndex?: number;
  toggleLinksData: ToggleData[];
  variables: Record<string, Document>;
  className?: string;
}

const popoverContentClass = css`
  min-width: 200px;
`;

const PopoverChipSelect: FC<PopoverChipSelectProps> = ({
  activeLinkIndex,
  toggleLinksData,
  variables,
  className = '',
}) => {
  const renderPopoverContent = (togglePopover: () => void) => (
    <ul className={cls('my-2', popoverContentClass)}>
      {toggleLinksData.map((toggleItem, idx) => (
        <li key={toggleItem.href}>
          <DropdownMenuItem
            isActive={activeLinkIndex === idx}
            toggleItem={toggleItem}
            onClick={togglePopover}
          />
        </li>
      ))}
    </ul>
  );

  return (
    <Popover renderContent={renderPopoverContent} className={className}>
      <Chip size="small">
        <LocationIcon className="text-red-light mr-2" />

        <span className="text-red-light">
          {getVariablePlainText(variables['select-station-button-text'])}
        </span>
      </Chip>
    </Popover>
  );
};

export default PopoverChipSelect;
