import React, { FC } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';

import CountdownDown from '@/components/countdowns/countdown-down';
import CountdownProgressBar from '@/components/countdowns/countdown-progress-bar';
import CountdownUp from '@/components/countdowns/countdown-up';

interface CountdownsProps {
  from: number;
  to: number;
  className?: string;
  onCountdownComplete?: () => void;
}

const Countdowns: FC<CountdownsProps> = ({
  from,
  to,
  className = '',
  onCountdownComplete,
}) => {
  const duration = to - from;

  const renderer: CountdownRendererFn = ({
    seconds,
    minutes,
    hours,
    total,
  }) => (
    <>
      <div className="mt-2 mb-1">
        <CountdownProgressBar duration={duration} timeLeft={total} />
      </div>

      <div className="flex md:justify-between justify-end">
        <div className="hidden md:flex">
          <CountdownUp duration={duration} timeLeft={total} />
        </div>

        <CountdownDown seconds={seconds} minutes={minutes} hours={hours} />
      </div>
    </>
  );

  return (
    <div className={className}>
      <Countdown
        date={to}
        key={to}
        renderer={renderer}
        onComplete={onCountdownComplete}
      />
    </div>
  );
};

export default Countdowns;
