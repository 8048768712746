import { FC } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import Image from '@/components/image';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { ImageAsset } from '@/types/views/generic';

interface SubmittedContactProps {
  image?: ImageAsset;
  isSuccess: boolean;
  onClose?: () => void;
  onBack?: () => void;
}

const Submitted: FC<SubmittedContactProps> = ({
  image,
  isSuccess,
  onClose,
  onBack,
}) => {
  const variables = useVariables();
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);

  const successHeading = getVariablePlainText(
    variables['contactform-sent-title'],
  );
  const successText = getVariablePlainText(variables['contactform-sent-text']);
  const successCloseText = getVariablePlainText(
    variables['contactform-sent-close-button-text'],
  );
  const errorHeading = getVariablePlainText(
    variables['contactform-error-title'],
  );
  const errorText = getVariablePlainText(variables['contactform-error-text']);
  const tryAgainText = getVariablePlainText(
    variables['contactform-error-try-again-text'],
  );
  const cancelButtonText = getVariablePlainText(
    variables['contactform-error-cancel-text'],
  );

  return (
    <div className="py-12 px-5 md:px-12 flex flex-col items-center">
      {image && (
        <figure className="w-full md:w-3/5 aspect-[3/2] mb-8 md:mb-10">
          <Image
            className="w-full overflow-hidden"
            src={image.src}
            basicLoader
            objectFit="contain"
          />
        </figure>
      )}
      <div className="max-w-3xl text-center">
        <h2 className="text-h3 text-white mb-4">
          {isSuccess ? successHeading : errorHeading}
        </h2>
        <p className="text-white text-body1 mb-6">
          {isSuccess ? successText : errorText}
        </p>
        <div className="flex flex-col items-center gap-6">
          <NormalButton
            {...buttonProps}
            text={isSuccess ? successCloseText : tryAgainText}
            onClick={isSuccess ? onClose : onBack}
            size="L"
            className="w-full md:w-auto"
          />
          {!isSuccess && (
            <button
              type="button"
              onClick={onClose}
              className="w-full md:w-auto text-body2 text-gray-2 underline"
            >
              {cancelButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Submitted;
