import React, { FC } from 'react';

import MediaBackground from '@/components/media-background';
import { MediaAsset } from '@/types/views/generic';

interface HeroProps {
  background: MediaAsset;
  backgroundMobile: MediaAsset;
}

const Hero: FC<HeroProps> = ({ background, backgroundMobile, children }) => (
  <div className="h-full">
    <MediaBackground
      background={background}
      backgroundMobile={backgroundMobile}
    />

    {children}
  </div>
);

export default Hero;
