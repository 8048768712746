import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import Modal from '@/components/modal';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface IncompleteProfileMobileModalProps {
  text: string;
  incompleteProfileData: string[];
  onCloseIncompleteProfileModal: () => void;
}

const IncompleteProfileMobileModal = ({
  text,
  incompleteProfileData,
  onCloseIncompleteProfileModal,
}: IncompleteProfileMobileModalProps) => {
  const variables = useVariables();

  return (
    <Modal onClose={onCloseIncompleteProfileModal} showCloseIcon={false}>
      <div className="bg-black-light h-full pt-20 px-5   py-4 flex flex-col">
        <h3 className="text-h3 text-center mb-4">
          {getVariablePlainText(
            variables[`account-missing-information-text`],
          ) || 'Fehlende Informationen'}
        </h3>

        <p className="text-body2">{text}</p>

        <ul className="mt-6 text-body2">
          {incompleteProfileData.map((field) => (
            <li key={field} className="list-disc ml-6">
              {getVariablePlainText(variables[`account-${field}`]) ||
                `account-${field}`}
            </li>
          ))}
        </ul>

        <div className="mt-auto">
          <CustomLink href="#editProfile" as="#editProfile" isExternal>
            <NormalButton
              text={
                getVariablePlainText(
                  variables[`account-edit-profile-cta-text`],
                ) || 'Profil bearbeiten'
              }
              className="w-full"
              size="L"
            />
          </CustomLink>

          <div className="mt-4 text-center">
            <button
              type="button"
              className="text-body2 text-gray-2 underline"
              onClick={onCloseIncompleteProfileModal}
            >
              {getVariablePlainText(variables[`account-cancel-cta-text`]) ||
                'Abbrechen'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IncompleteProfileMobileModal;
