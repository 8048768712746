interface DiscIconProps {
  className?: string;
  fill?: string;
}

const TriangleIcon = ({
  className = '',
  fill = 'currentColor',
}: DiscIconProps) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 179 154"
      fill="none"
      className="w-[179px] h-[154px]"
    >
      <path
        d="M7.6606 149.75L89.5 8L171.339 149.75H7.6606Z"
        stroke={fill}
        strokeWidth="8"
      />
    </svg>
  </div>
);

export default TriangleIcon;
