import sectionBg from '@/components/common-styles';

const styles = {
  container: `bg-black text-white tlight:bg-white tlight:text-black teair:bg-white ${sectionBg}`,
  contentContainer: 'content-box',
  articlePaddings: 'pb-4 md:pb-6',
  textContainer: 'text-box',
  contentCategories:
    'text-red text-overline pb-5 tesn:text-esn-secondary-light teair:text-air-lila',
  title: 'text-h4 pb-4 teair:text-air-deepBlue',
  lead: 'text-lead pb-4 md:pb-6 teair:text-air-deepBlue',
};

export default styles;
