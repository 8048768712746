import cls from 'classnames';

const styles = {
  wrapper: 'flex items-center rounded-full p-2px bg-black-light',
  item: (isActive: boolean) =>
    cls(
      isActive
        ? 'rounded-full text-black-light bg-red-light-darker'
        : 'text-gray-2',
      'text-overline block px-3 py-1',
    ),
};

export default styles;
