import React, { FC } from 'react';

import { EntryComponentProps } from '@/types/views/generic';
import { TextWithHeaderSectionEntry } from '@/types/views/sections';

const TextWithHeader: FC<EntryComponentProps<TextWithHeaderSectionEntry>> = ({
  title,
  text,
}) => (
  <div className="content-box">
    <div className="md:w-10/12 mx-auto text-center">
      <div className="text-body2 text-gray-3">{title}</div>
      {text && <div className="text-lead mt-4 md:mt-6">{text}</div>}
    </div>
  </div>
);

export default TextWithHeader;
