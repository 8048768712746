import { nanoid } from 'nanoid';
import React, { FC } from 'react';

interface MessageIconProps {
  className?: string;
  fill?: string;
}

const MessageIcon: FC<MessageIconProps> = ({
  className,
  fill = 'currentColor',
}) => {
  const idPrefix = nanoid();

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idPrefix}clip0)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.11058 11.068L18.3871 4.81279L15.382 18.2593L13.0278 13.3538L15.1006 10.6814C15.4391 10.2451 15.3597 9.61688 14.9233 9.27839C14.4869 8.9399 13.8588 9.01927 13.5203 9.45567L11.4475 12.128L6.11058 11.068ZM20.7097 3.58992C20.9311 2.59899 19.8985 1.79802 18.9938 2.25899L3.1461 10.3338C2.13144 10.8508 2.34011 12.3582 3.45712 12.58L11.1736 14.1127L14.5775 21.2055C15.0702 22.2322 16.5821 22.0594 16.8304 20.948L20.7097 3.58992Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id={`${idPrefix}clip0`}>
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessageIcon;
