const styles = {
  container: 'content-box relative',
  wrapper:
    'overflow-auto flex items-center py-4 text-overline border-b-2 border-gray-1',
  backButton:
    'flex items-center py-1.5 px-2 text-gray-4 hover:text-pink teair:hover:text-air-coral',
  backButtonText: 'ml-2 text-overline',
  current: 'text-red-medium',
  shadow:
    'absolute top-0 right-0 bottom-0 w-10 bg-gradient-to-r from-transparent to-black',
};

export default styles;
