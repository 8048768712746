import React, { FC } from 'react';

import Image from '@/components/image';
import { ArticleImageHeaderEntry } from '@/types/views/sections';

import styles from './styles';

const ArticleImageHeader: FC<ArticleImageHeaderEntry> = ({ title, image }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          src={image.src}
          useIntrinsicSize={true}
          width={image.width}
          height={image.height}
          alt={title}
          className={styles.image}
        />
      </div>
    </div>
  </div>
);

export default ArticleImageHeader;
