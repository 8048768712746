import { format, subHours } from 'date-fns';
import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { SWRInfiniteKeyLoader } from 'swr/infinite/dist/infinite/types';

import { PlaylistItem as PlaylistItemProps } from '@/types/views/generic';

import fetcher from '../fetcher';

const useLoadPlayouts = (
  playlistId: string,
  currentDate: Date,
  isFuture = false,
) => {
  const getKey: SWRInfiniteKeyLoader = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;

    const maxDate = subHours(currentDate, pageIndex);
    const minDate = subHours(currentDate, pageIndex + 1);

    const queryParams = new URLSearchParams({
      ...(isFuture
        ? { future: 'true' }
        : {
            createdAtMax: format(maxDate, 'yyyy-MM-dd HH:mm'),
            createdAtMin: format(minDate, 'yyyy-MM-dd HH:mm'),
          }),
    });

    return `/api/channels/${playlistId}/playouts?${queryParams.toString()}`;
  };

  const {
    data,
    setSize,
    isValidating: isLoading,
  } = useSWRInfinite<PlaylistItemProps[]>(getKey, fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false,
  });

  const loadMore = useCallback(
    () => setSize((prevState) => prevState + 1),
    [setSize],
  );

  const flattedEntries = useMemo(() => data?.flat(), [data]);
  const filteredEntries = flattedEntries?.filter(
    (value) => value !== undefined && value !== null,
  );
  const lastTeaserArray = data?.[data.length - 1];
  const isAllDataLoaded = lastTeaserArray?.length === 0;

  return {
    isLoading,
    data: filteredEntries,
    loadMore,
    isAllDataLoaded,
  };
};

export default useLoadPlayouts;
