import cls from 'classnames';
import { ReactNode } from 'react';

import { NavItemEntryWithIcon } from '@/types/views/generic';

interface DesktopBaseTileProps {
  className: string;
  children: ReactNode;
  navItem: NavItemEntryWithIcon;
}

const DesktopBaseTile = ({
  className,
  children,
  navItem,
}: DesktopBaseTileProps) => (
  <div className="flex-col mb-12">
    <div
      className={cls(
        'w-16 h-16 flex-shrink-0 relative',
        'flex items-center justify-center rounded-4 w-[229px] h-[229px] lg:w-[276px] lg:h-[276px]',
        className,
      )}
    >
      {children}
    </div>
    <div className={cls('text-body1 text-center mt-4 font-semibold')}>
      {navItem.link.text}
    </div>
  </div>
);

export default DesktopBaseTile;
