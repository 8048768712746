import { FC, TextareaHTMLAttributes } from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

import TextArea from '@/components/form-elements/textarea';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

import { ContactFormData } from '../..';

interface MessageInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  register: UseFormRegister<ContactFormData>;
  errors: DeepMap<ContactFormData, FieldError>;
  submitFailed?: boolean;
}

const Message: FC<MessageInputProps> = ({
  register,
  errors,
  submitFailed,
  ...restProps
}) => {
  const variables = useVariables();

  const placeholder = getVariablePlainText(
    variables['contactform-message-placeholder'],
  );
  const errorText =
    getVariablePlainText(variables['contactform-message-error']) ||
    'Bitte gib eine Nachricht ein';

  return (
    <div className="flex flex-wrap mt-6 items-start">
      <TextArea
        {...register('message', {
          required: errorText,
        })}
        id="message"
        label="Nachricht"
        showLabel={false}
        placeholder={placeholder || ''}
        containerClassName="md:mr-4"
        error={errors?.message?.message}
        submitFailed={submitFailed}
        rows={3}
        {...restProps}
      />
    </div>
  );
};

export default Message;
