import cls from 'classnames';
import { FC, ReactNode } from 'react';

import { ImageAsset } from '@/types/views/generic';
import { PageHeaderEntryTheme } from '@/types/views/sections';

import Image from '../image';

interface HeaderWithBackgroundImageProps {
  background?: ImageAsset;
  backgroundMobile?: ImageAsset;
  backgroundImageHeight?: string | number;
  alt: string;
  children: ReactNode;
  theme?: PageHeaderEntryTheme;
  deactivateAdSlots?: boolean;
  adaptiveBottomMargin?: number;
}

const commonClasses = 'absolute w-full h-full top-0 left-0';

const HeaderWithBackgroundImage: FC<HeaderWithBackgroundImageProps> = ({
  background,
  backgroundMobile,
  backgroundImageHeight,
  alt,
  children,
  theme,
  adaptiveBottomMargin,
}) => {
  const isEventPage = theme === 'event';
  const isActPage = theme === 'act';

  const splitTitle = alt.split(/\s/);
  const numberOfTitleParts = splitTitle.filter(Boolean).length;
  const isTitleOneWord = numberOfTitleParts === 1;

  return (
    <div className="relative">
      <div
        className={cls(
          'relative py-12 md:py-14 tesn:py-8',
          isActPage &&
            'h-[432px] md:h-[464px] bg-gradient-to-t to-1/2 teair:from-white tesn:from-black tels:from-black',
          isTitleOneWord ? 'mb-12 md:mb-14' : 'mb-22 sm:mb-24 md:mb-38',
        )}
        style={
          adaptiveBottomMargin
            ? { marginBottom: `${adaptiveBottomMargin}px` }
            : undefined
        }
      >
        {children}
      </div>

      <div
        className="absolute top-0 w-screen 2xl:left-1/2 2xl:transform 2xl:-translate-x-1/2 -z-10"
        style={{
          height: backgroundImageHeight ?? '100%',
        }}
      >
        {backgroundMobile ? (
          <>
            <Image
              src={backgroundMobile.src}
              alt={alt}
              objectFit="cover"
              objectPosition="top"
              className="md:hidden object-cover object-center"
            />

            <div
              className={`md:hidden bg-gradient-to-t from-black to-transparent teair:from-transparent ${commonClasses}`}
            />
          </>
        ) : (
          isEventPage && (
            <div
              className={`md:hidden bg-gradient-from-tl bg-gradient-to-br from-air-gradientPink to-air-gradientBlue ${commonClasses}`}
            />
          )
        )}

        {background ? (
          <>
            <Image
              src={background.src}
              alt={alt}
              objectFit="cover"
              objectPosition="top"
              className={cls(
                backgroundMobile ? 'hidden md:block' : 'block',
                'object-cover object-center',
              )}
            />

            <div
              className={cls(
                backgroundMobile ? 'hidden md:block' : 'block',
                `bg-gradient-to-t from-black to-transparent teair:from-transparent ${commonClasses}`,
              )}
            />
          </>
        ) : (
          isEventPage && (
            <div
              className={`hidden md:block bg-gradient-from-tl bg-gradient-to-br from-air-gradientPink to-air-gradientBlue ${commonClasses}`}
            />
          )
        )}
      </div>
    </div>
  );
};

export default HeaderWithBackgroundImage;
