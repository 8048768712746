import { FC } from 'react';

interface CheckmarkIconProps {
  fill?: string;
  className?: string;
}

export const CheckmarkIcon: FC<CheckmarkIconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9125 6.1432C18.7182 5.94706 18.403 5.94706 18.2086 6.1432L9.53744 14.8927L5.78411 11.1103C5.5896 10.9143 5.27425 10.9143 5.07974 11.1103L4.14197 12.0553C3.94747 12.2513 3.94747 12.5691 4.14197 12.7652L7.89634 16.5487L7.89342 16.5516L9.18256 17.8524C9.37695 18.0485 9.6921 18.0485 9.88648 17.8524L19.8497 7.79913C20.0441 7.60299 20.0441 7.28499 19.8497 7.08885L18.9125 6.1432Z"
      fill="white"
    />
  </svg>
);

export default CheckmarkIcon;
