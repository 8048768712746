import cls from 'classnames';
import { FC } from 'react';

import Countdown from '@/components/sections/quiz/components/countdown';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface QuizTimerMessageProps {
  message?: string;
  nextQuestionDate?: string | null;
  onCountdownFinish?: () => void;
  hasPlainDate?: boolean;
}

const QuizTimerMessage: FC<QuizTimerMessageProps> = ({
  message = '',
  hasPlainDate = false,
  nextQuestionDate = null,
  onCountdownFinish = () => undefined,
}) => {
  const variables = useVariables();
  const nextQuestionText =
    getVariablePlainText(variables['next-question-in-text']) || 'Nächste Frage';

  return (
    <div>
      {message && (
        <h3 className="text-body2 text-white text-center font-semibold">
          {message}
        </h3>
      )}

      {nextQuestionDate && (
        <div className={cls(hasPlainDate && 'inline-flex')}>
          <p
            className={cls(
              !hasPlainDate &&
                'text-[22px] font-semibold md:text-[2rem] text-white text-center drop-shadow-small my-6 md:mt-12 text-border',
            )}
          >
            {nextQuestionText}&nbsp;
          </p>

          <Countdown
            endDate={new Date(nextQuestionDate)}
            onFinish={onCountdownFinish}
            hasStyledDate={!hasPlainDate}
          />
        </div>
      )}
    </div>
  );
};

export default QuizTimerMessage;
