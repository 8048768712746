import { css } from '@emotion/css';

import { screens } from '@/../tailwind/theme';

const containerHeight = css`
  height: 275px;

  @media (min-width: ${screens.md}) {
    height: 344px;
  }
  @media (min-width: ${screens.lg}) {
    height: 560px;
  }
`;

const styles = {
  container: `absolute w-full top-0 left-0 -z-10 ${containerHeight}`,
  wrapper: 'h-full hidden md:block',
  wrapperMobile: 'h-full md:hidden',
  image: 'object-cover object-center',
  gradient:
    'absolute w-full h-full top-0 left-0  bg-gradient-to-t from-black to-transparent',
};

export default styles;
