import { FC } from 'react';

import Grid from '@/components/sections/content-feed/components/grid';
import renderEntryTeaser from '@/components/sections/content-feed/lib/render-entry-teaser';
import { notNull } from '@/lib/utils';
import { TeaserGroup } from '@/types/views/generic';
import { CategoriesAZSectionEntry } from '@/types/views/sections';

const CategoriesAZ: FC<CategoriesAZSectionEntry> = ({
  title,
  showTitle,
  categories,
}) => {
  const teasers = categories.map(renderEntryTeaser).filter(notNull);

  return (
    <div className="content-box">
      {showTitle && <h4 className="text-h4 text-pink mb-6">{title}</h4>}

      <Grid
        teaserGroup={TeaserGroup.Categories}
        teasers={teasers}
        isEmpty={teasers.length === 0}
      />
    </div>
  );
};

export default CategoriesAZ;
