import { FC } from 'react';

import TitleLink from '@/components/title-link';
import { TextLink } from '@/types/views/generic';

import styles from './styles';

type TitleProps = {
  hideTitle: boolean;
  link?: TextLink;
  title: string;
  hasInvertedThemeColor?: boolean;
};

const Title: FC<TitleProps> = ({
  hideTitle,
  link,
  title,
  hasInvertedThemeColor = false,
}) => (
  <div className={styles.header}>
    {!hideTitle && (
      <h2 className={styles.headerTitle(hasInvertedThemeColor)}>{title}</h2>
    )}
    {link && <TitleLink link={link} />}
  </div>
);

export default Title;
