import { css } from '@emotion/css';

import TailwindTheme from '@/../tailwind/theme';

const { colors, screens } = TailwindTheme;

const swiperPagination = css`
  .swiper-pagination {
    padding-left: 1rem;
    left: 0;
    top: 0;
    bottom: unset;
    text-align: unset;
  }

  .swiper-pagination-bullet {
    background-color: ${colors.red.light};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: ${colors.red.medium};
  }

  @media (min-width: ${screens.md}) {
    .swiper-pagination {
      padding-left: unset;
    }
  }
`;

const styles = {
  wrapper: `md:content-box w-full md:w-auto ${swiperPagination}`,
};

export default styles;
