import cls from 'classnames';
import React from 'react';

interface Channel {
  id: string;
  text: string;
}

interface ChannelFilterProps {
  channels: Channel[];
  changeFilter: (id: string) => void;
  selectedFilter?: string;
}

const ChannelFilter = ({
  channels,
  changeFilter,
  selectedFilter,
}: ChannelFilterProps) => (
  <div className="w-[235px] md:w-fit flex flex-row flex-wrap justify-evenly align-start gap-[10px] p-2 bg-black-light text-gray-2 rounded-[20px] md:rounded-[1000px] cursor-pointer">
    {channels.map((channel) => (
      <div
        className={cls(
          'py-[7px] px-3 rounded-[1000px]',
          selectedFilter === channel.id
            ? 'text-black-light bg-red-light-darker hover:bg-white'
            : 'hover:bg-gray-1',
        )}
        onClick={() => changeFilter(channel.id)}
      >
        {channel.text}
      </div>
    ))}
  </div>
);

export default ChannelFilter;
