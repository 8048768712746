import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FilterChips from '@/components/filter-chips';
import SoundpiecePlaylist from '@/components/sections/soundpiece-playlist';
import TitleWithText from '@/components/sections/title-with-text';
import { selectPlayerCurrentChannelId } from '@/store/slices/player';
import { EntryComponentProps } from '@/types/views/generic';
import {
  SoundpiecesOverviewSectionEntry,
  TitleWithTextEntryCmsTitleTheme,
} from '@/types/views/sections';

import useFilters from '../../filter-chips/use-filters';
import ChannelFilter from './channel-filter';

const channels = [
  { id: 'basel', text: 'Basel', identifier: 'BS' },
  { id: 'bern', text: 'Bern', identifier: 'BE' },
  { id: 'luzern', text: 'Luzern', identifier: 'LU' },
  { id: 'stgallen', text: 'St. Gallen', identifier: 'SG' },
  { id: 'zuerich', text: 'Zürich', identifier: 'ZH' },
];

const SoundpiecesOverview: FC<
  EntryComponentProps<SoundpiecesOverviewSectionEntry>
> = ({ filters, headerTitle }) => {
  const { query } = useRouter();
  const {
    isFilterSelectedById,
    isAllButtonActive,
    selectedFilterIds,
    resetSelectedFilters,
    toggleFilterSelection,
  } = useFilters(true);

  const currentChannel = useSelector(selectPlayerCurrentChannelId);

  const [channelFilter, setChannelFilter] = useState<string>();

  const filteredFilters = () =>
    filters?.filter((filter) => {
      if (filter.bmId) {
        // filter is attached to a channel
        const channel = channels.find((element) =>
          filter.bmId?.startsWith(element.identifier),
        );

        if (channel) {
          return channel.id === channelFilter;
        }
      }

      // filters without a channel are always displayed
      return true;
    });

  useEffect(() => {
    // set to users selected player channel once loaded
    // don't change if users changes player channel while on page
    if (currentChannel && !channelFilter) {
      setChannelFilter(currentChannel);
    }
  }, [channelFilter, currentChannel]);

  const defaultSelectedFilterIds = query?.tagIdsToInclude
    ? ([query.tagIdsToInclude, ...selectedFilterIds] as string[])
    : selectedFilterIds;

  return (
    <div>
      {headerTitle && (
        <TitleWithText
          title={headerTitle}
          theme={TitleWithTextEntryCmsTitleTheme.Bigger}
          showTitle
        />
      )}

      <div className="pt-6 md:pt-6">
        <SoundpiecePlaylist
          channelFilter={channelFilter}
          channelFilters={
            <ChannelFilter
              channels={channels}
              changeFilter={setChannelFilter}
              selectedFilter={channelFilter}
            />
          }
          filters={
            filters?.length ? (
              <FilterChips
                isAllButtonActive={isAllButtonActive}
                filters={filteredFilters() || []}
                isFilterSelectedById={isFilterSelectedById}
                onFilterToggle={toggleFilterSelection}
                onFiltersReset={resetSelectedFilters}
                className="justify-start"
              />
            ) : null
          }
          tagIdsToInclude={defaultSelectedFilterIds}
        />
      </div>
    </div>
  );
};

export default SoundpiecesOverview;
