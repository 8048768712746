import cls from 'classnames';

const styles = {
  breadcrumbs: (isArticlePage = false) =>
    cls(isArticlePage && 'teair:bg-air-deepBlue'),
  container: (hideOnMobile: boolean) =>
    cls('content-box', hideOnMobile && 'hidden md:block'),
  wrapper: (showBorder: boolean) =>
    cls(
      'flex items-center py-4 text-overline',
      showBorder && 'border-b-2 border-gray-1',
    ),
  backButton:
    'flex items-center py-1.5 px-2 text-gray-4 hover:text-pink teair:hover:text-air-coral',
  backButtonText: 'ml-2 text-overline',
};

export default styles;
