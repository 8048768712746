import { useRouter } from 'next/router';
import React, { FC } from 'react';

import ChipSelect from '@/components/chip-select';
import CustomLink from '@/components/custom-link';
import FilterChips from '@/components/filter-chips';
import { EntryComponentProps } from '@/types/views/generic';
import { WatchOverviewSectionEntry } from '@/types/views/sections';

import useFilters from '../../filter-chips/use-filters';
import InfiniteScrollTeasersGrid, {
  contentDataByType,
} from './infinite-scroll-teasers-grid';
import styles from './styles';

const WatchOverview: FC<EntryComponentProps<WatchOverviewSectionEntry>> = ({
  itemsType,
  filters,
}) => {
  const { query } = useRouter();
  const {
    isFilterSelectedById,
    isAllButtonActive,
    selectedFilterIds,
    resetSelectedFilters,
    toggleFilterSelection,
  } = useFilters(true);

  const defaultSelectedFilterTags = query?.tagIdsToInclude
    ? ([query.tagIdsToInclude, ...selectedFilterIds] as string[])
    : selectedFilterIds;

  return (
    <div className="content-box">
      <div className={styles.filtersWrapper}>
        <ChipSelect
          activeIndex={Object.keys(contentDataByType).indexOf(itemsType)}
          className="mb-4 md:mb-0 md:mr-20 xl:mr-40"
        >
          {Object.values(contentDataByType).map((contentData) => (
            <CustomLink href={contentData.href} key={contentData.href}>
              {contentData.text}
            </CustomLink>
          ))}
        </ChipSelect>

        {!!filters?.length && (
          <FilterChips
            isAllButtonActive={isAllButtonActive}
            filters={filters}
            isFilterSelectedById={isFilterSelectedById}
            onFilterToggle={toggleFilterSelection}
            onFiltersReset={resetSelectedFilters}
            className={styles.filterTiles}
          />
        )}
      </div>

      <div className={styles.divider} />

      <div className={styles.gridWrapper}>
        <InfiniteScrollTeasersGrid
          itemsType={itemsType}
          selectedFilterTags={defaultSelectedFilterTags}
        />
      </div>
    </div>
  );
};

export default WatchOverview;
