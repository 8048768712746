import cls from 'classnames';

import sectionBg from '@/components/common-styles';

const styles = {
  container: (hasCustomText = false) =>
    cls(
      `bg-black text-white tlight:bg-white tlight:text-black game:bg-game-blue-midnight ${sectionBg}`,
      hasCustomText ? 'teair:bg-air-deepBlue' : 'teair:bg-white',
    ),
  contentContainer: 'content-box',
  textContainer: (isGameTheme = false, hasCustomText = false) =>
    cls(
      'text-body1 game:text-white game:text-center',
      !isGameTheme && 'text-box',
      hasCustomText ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
};

export default styles;
