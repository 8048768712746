import cls from 'classnames';
import React, { FC } from 'react';

import Image from '@/components/image';
import { isImageAsset } from '@/lib/utils';
import { MediaAsset } from '@/types/views/generic';

interface ThumbailsSwiperSlideProps {
  mediaItem: MediaAsset;
  isActive: boolean;
}

const ThumbailsSwiperSlide: FC<ThumbailsSwiperSlideProps> = ({
  mediaItem,
  isActive,
}) => (
  <div className="cursor-pointer">
    {isImageAsset(mediaItem) && (
      <Image
        src={mediaItem.src}
        objectFit="cover"
        className={cls(
          'aspect aspect-16-9 rounded-2 overflow-hidden',
          isActive && 'border border-solid border-white',
        )}
      />
    )}
  </div>
);

export default ThumbailsSwiperSlide;
