import { useCallback, useEffect, useMemo, useRef } from 'react';
import useSWRInfinite from 'swr/infinite';
import { SWRInfiniteKeyLoader } from 'swr/infinite/dist/infinite/types';

import { watchVideosMemeDateRange } from '@/lib/utils';
import { ComponentEntry, TaggingToolContentType } from '@/types/views/generic';

interface SWRData {
  entries: ComponentEntry[];
  missingEntries: ComponentEntry[];
  itemsCount: number;
}

const useLoadAdditionalEntries = (
  entryType: TaggingToolContentType,
  pageSize: string,
  entryId: string,
  idsToExclude = [] as string[],
  tagIdsToInclude?: string,
) => {
  const areAllFilteredEntriesLoadedRef = useRef<boolean>(false);

  const getKey: SWRInfiniteKeyLoader = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.entries.length) return null;

    const currentPage = pageIndex + 1;
    const { startDate, endDate } = watchVideosMemeDateRange(currentPage);
    const { startDate: startDateSponsored, endDate: endDateSponsored } =
      watchVideosMemeDateRange(currentPage - 1);
    const queryParams = new URLSearchParams({
      entryType,
      page: '1',
      perPage: pageSize,
      pageIndex: String(currentPage) ?? '',
      ...(entryId && { entryId }),
      ...(startDate && endDate && { startDate, endDate }),
      ...(startDateSponsored &&
        endDateSponsored && { startDateSponsored, endDateSponsored }),
      tagIdsToIncludeKey: tagIdsToInclude ?? '',
    });

    return `/api/taggingtool/entries-detail?${queryParams.toString()}`;
  };

  const {
    data,
    size,
    setSize,
    isValidating: isLoading,
    error,
  } = useSWRInfinite<SWRData>(
    getKey,
    async (url: string) => {
      const fetcherQueryParams = new URLSearchParams({
        ...(idsToExclude && {
          idsToExclude: idsToExclude.join(','),
        }),
        ...(!areAllFilteredEntriesLoadedRef.current &&
          tagIdsToInclude && {
            tagIdsToInclude,
            fillMissingEntries: 'true',
          }),
      });
      const response = await fetch(`${url}&${fetcherQueryParams.toString()}`);

      return response.json();
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateFirstPage: false,
    },
  );

  const loadMore = useCallback(
    () => setSize((prevState) => prevState + 1),
    [setSize],
  );

  const flattedEntries = useMemo(
    () => ({
      defaultEntries:
        data?.flatMap(({ entries }) => entries).filter(Boolean) || [],
      entries:
        data
          ?.flatMap(({ entries, missingEntries }) => [
            ...entries,
            ...missingEntries,
          ])
          .filter(Boolean) || [],
      itemsCount:
        data?.flatMap(({ itemsCount }) => itemsCount).filter(Boolean) || [],
    }),
    [data],
  );

  const lastTeaserArray = data?.[data.length - 1];
  const areAllFilteredEntriesLoaded =
    lastTeaserArray && lastTeaserArray.entries.length < +pageSize;
  const isEmpty =
    !flattedEntries.itemsCount?.[flattedEntries.itemsCount.length - 1];
  const isAllDataLoaded =
    isEmpty || (lastTeaserArray && lastTeaserArray.itemsCount < +pageSize);

  useEffect(() => {
    if (areAllFilteredEntriesLoaded) {
      areAllFilteredEntriesLoadedRef.current = true;
    }
  }, [areAllFilteredEntriesLoaded]);

  return {
    isLoading,
    data: flattedEntries.entries,
    error,
    size,
    setSize,
    loadMore,
    isAllDataLoaded,
  };
};

export default useLoadAdditionalEntries;
