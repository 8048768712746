const styles = {
  wrapper: 'md:hidden flex flex-col relative',
  cardImgContainer: 'relative h-2/3 md:ml-auto',
  cardImg: 'aspect aspect-16-9',
  buttons: 'flex mr-4',
  infoWrapper: 'content-box z-20 flex flex-col -mt-12',
  gradientTop:
    'absolute inset-0 opacity-70 h-1/6 mb-auto  bg-gradient-to-b from-black',
  gradientBottom: `absolute inset-0 -bottom-1/6 z-10 h-4/5 mt-auto bg-gradient-to-t from-black from-1/5`,
};

export default styles;
