import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { SeoEntry } from '@/types/views/generic';

interface SeoProps {
  seo: SeoEntry;
}

const Seo: FC<SeoProps> = ({ seo }) => {
  const { asPath } = useRouter();

  return (
    <Head>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />

      <meta property="og:site_name" content={seo.title} key="ogsitename" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={`${seo.url}${asPath}`} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:site" content="@energy_ch" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@energy_ch" />

      {seo.image?.src && (
        <>
          <meta property="og:image" content={seo.image.src} />
          <meta name="twitter:image" content={seo.image.src} />
        </>
      )}
    </Head>
  );
};

export default Seo;
