import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { SWRInfiniteKeyLoader } from 'swr/infinite/dist/infinite/types';

import { audiosDateRange } from '@/lib/utils';
import { ContentFeedEntrySoundpiece } from '@/types/views/generic';

const useLoadAudios = (perPage: string, idsToExclude: string[] = []) => {
  const getKey: SWRInfiniteKeyLoader = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;

    const currentPage = pageIndex + 1;
    const { startDate, endDate } = audiosDateRange(currentPage);

    const queryParams = new URLSearchParams({
      entryType: 'soundpiece',
      page: '1',
      currentPage: currentPage.toString(),
      perPage,
      ...(startDate && endDate && { startDate, endDate }),
    });

    return `/api/taggingtool/soundpieces?${queryParams.toString()}`;
  };

  const {
    data,
    setSize,
    isValidating: isLoading,
  } = useSWRInfinite<ContentFeedEntrySoundpiece[]>(
    getKey,
    async (url: string) => {
      const fetcherQueryParams = new URLSearchParams({
        ...(!!idsToExclude.length && {
          idsToExclude: idsToExclude.join(','),
        }),
      });

      const response = await fetch(`${url}&${fetcherQueryParams.toString()}`);

      return response.json();
    },
    {
      initialSize: 0,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateFirstPage: false,
    },
  );

  const loadMore = useCallback(
    () => setSize((prevState) => prevState + 1),
    [setSize],
  );

  const flattedEntries = useMemo(() => data?.flat(), [data]);
  const lastTeaserArray = data?.[data.length - 1];
  const isEmpty = lastTeaserArray?.length === 0;
  const isAllDataLoaded =
    isEmpty || (lastTeaserArray && lastTeaserArray?.length < +perPage);

  return {
    isLoading,
    data: flattedEntries,
    loadMore,
    isAllDataLoaded,
  };
};

export default useLoadAudios;
