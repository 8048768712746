import { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import RichTextRenderer from '@/components/rich-text-renderer';
import useVariables from '@/lib/hooks/use-variables';
import { ArticleTeaserEntry } from '@/types/views/generic';

import styles from './styles';

const renderCommaSeparatedList = (list: string[]): string => list.join(', ');

const ArticleTeaser: FC<ArticleTeaserEntry> = ({
  link,
  title,
  image,
  creators,
  isSponsoredContent,
  categories,
}) => {
  const variables = useVariables();
  const sponsoredContentBody = variables['sponsored-article-teaser-text'];

  return (
    <article>
      <CustomLink {...link} className={styles.contentWrapper}>
        <figure className={styles.imageContainer}>
          <Image src={image.src} objectFit="cover" />
        </figure>

        <div className={styles.contentContainer}>
          <div className={styles.categories}>
            {categories?.length ? renderCommaSeparatedList(categories) : null}
          </div>

          <div className={styles.title}>{title}</div>

          <div className={styles.creators}>
            {creators?.length ? renderCommaSeparatedList(creators) : null}
          </div>
        </div>
      </CustomLink>

      {isSponsoredContent && (
        <RichTextRenderer
          className={styles.brandedContent}
          body={sponsoredContentBody}
        />
      )}
    </article>
  );
};

export default ArticleTeaser;
