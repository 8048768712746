// eslint-disable-next-line import/prefer-default-export
export const getTimeUnitsRemainders = (timestamp: number) => {
  const timeInSeconds = timestamp / 1000;
  const seconds = Math.floor(timeInSeconds) % 60;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const hours = Math.floor(timeInSeconds / 3600) % 24;

  return {
    seconds,
    minutes,
    hours,
  };
};
