import React, { FC } from 'react';

import TagChip from '@/components/tag-chip';
import { TagChipType } from '@/types/views/generic';

const ArticleProfileTags: FC<{ profiles: TagChipType[] }> = ({ profiles }) => (
  <>
    {profiles.map((tag) => (
      <TagChip key={tag.link.id} tag={tag} className="mt-2 mr-2" />
    ))}
  </>
);

export default ArticleProfileTags;
