import React, { FC } from 'react';

import TagChip from '@/components/tag-chip';
import { padTimeToTwo } from '@/lib/utils';
import { Tag } from '@/types/views/generic';

interface ShowInfoProps {
  title: string;
  tags: Tag[];
  from: number;
  to: number;
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${padTimeToTwo(hours)}:${padTimeToTwo(minutes)}`;
};

const ShowInfo: FC<ShowInfoProps> = ({ title, tags, from, to }) => (
  <div className="flex flex-col">
    <div className="md:hidden mb-2 text-overline text-red-light">
      {formatDate(from)} - {formatDate(to)}
    </div>

    <div className="mb-4 md:mb-8 mr-4 text-h2 text-pink leading-none">
      {title}
    </div>

    {!!tags?.length && (
      <div className="flex md:flex-col items-start gap-2 md:gap-4 mb-10">
        {tags.map((tag) => (
          <TagChip tag={tag} key={tag.link.id} />
        ))}
      </div>
    )}
  </div>
);

export default ShowInfo;
