import { FC } from 'react';

import { IconLinkEntry } from '@/types/views/generic';

import IconLink from './icon-link';

interface IconLinksProps {
  iconLinks: IconLinkEntry[];
}

const IconLinks: FC<IconLinksProps> = ({ iconLinks }) =>
  iconLinks.length ? (
    <div className="flex flex-col items-start">
      {iconLinks.map((iconLink) => (
        <IconLink {...iconLink} />
      ))}
    </div>
  ) : null;

export default IconLinks;
