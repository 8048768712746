import fetchWithCatch from '@/lib/fetch-with-catch';
import { PrizeApi } from '@/types/apis/prize';

const getStatus = async (
  type: string,
  poolId: number,
  contentfulId: string,
): Promise<PrizeApi.CanParticipateResponseData | null> => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      pot_id: poolId,
      contentfulId,
    }),
  };

  const { data } = await fetchWithCatch<PrizeApi.CanParticipateResponse>(() =>
    fetch(`/api/me/lottery/status`, options),
  );

  return data?.data ?? null;
};

const participate = async (
  type: string,
  poolId: number,
  memberId: string | null = null,
  contentfulId: string,
  formData?: Record<string, unknown>,
): Promise<PrizeApi.ParticipationResponse | null> => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      pot_id: poolId,
      member_id: memberId,
      form_data: formData ?? null,
      contentfulId,
    }),
  };

  const { data } = await fetchWithCatch<PrizeApi.ParticipationResponse>(() =>
    fetch(`/api/me/lottery/participate`, options),
  );

  return data ?? null;
};

export { getStatus, participate };
