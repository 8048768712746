import cls from 'classnames';
import React, { FC } from 'react';

import Newsletter from '@/components/newsletter';
import { NewsletterSectionEntry } from '@/types/views/sections';

const NewsletterComponent: FC<NewsletterSectionEntry> = ({
  title,
  showTitle,
  newsletterMobileImage,
  newsletterDesktopImage,
  newsletterSuccessImage,
  newsletterErrorImage,
  deactivateAdSlots,
}) => (
  <div>
    {showTitle && (
      <div className={cls('content-box', deactivateAdSlots && 'ml-auto')}>
        <h2 className="text-h2 text-pink mb-4 md:mb-6 tesn:text-white">
          {title}
        </h2>
      </div>
    )}

    <Newsletter
      isModal={false}
      newsletterMobileImage={newsletterMobileImage}
      newsletterDesktopImage={newsletterDesktopImage}
      newsletterSuccessImage={newsletterSuccessImage}
      newsletterErrorImage={newsletterErrorImage}
      deactivateAdSlots={deactivateAdSlots}
    />
  </div>
);

export default NewsletterComponent;
