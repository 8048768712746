import { css } from '@emotion/css';
import cls from 'classnames';

const noScrollbar = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const slideWidthAuto = css`
  &.swiper-slide {
    width: auto;
  }
`;

const styles = {
  carouselWrapper: 'overflow-hidden relative',
  filterTilesCarousel: cls(noScrollbar, 'overflow-auto flex'),
  swiperSlide: slideWidthAuto,
  filterTilesItem: 'mr-2 last:mr-0',
  allFilterChip: 'whitespace-nowrap text-black',
  carouselLeftShadow:
    'absolute z-10 -left-1.5 inset-y-0 w-12 bg-gradient-to-r from-black pointer-events-none',
  carouselRightShadow:
    'absolute z-10 -right-1.5 inset-y-0 w-12 bg-gradient-to-l from-black pointer-events-none',
  divider: 'min-w-px flex mx-2 bg-black-light',
  previousFilterBtn: 'ml-2 hidden xl:block',
  nextFilterBtn: 'ml-2 hidden xl:block',
};

export default styles;
