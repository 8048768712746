import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import useLoadContentInfinitely from '@/lib/hooks/use-load-content-infinitely';
import useVariables from '@/lib/hooks/use-variables';
import {
  selectAudioPlayerIsPlaying,
  selectPlayerCurrentAudio,
} from '@/store/slices/player';
import { ContentFeedEntrySoundpiece } from '@/types/views/generic';
import { SoundpiecePlaylistEntry } from '@/types/views/sections';

import Soundpiece from './components/soundpiece';
import SoundpiecePlaylistContainer from './components/soundpiece-playlist-container';

const PAGE_SIZE = 11;

// TODO: Verify with PM if content type in CMS even needed
interface SoundpiecePlaylistProps
  extends Omit<
    SoundpiecePlaylistEntry,
    'title' | 'id' | 'description' | 'componentType'
  > {
  filters?: JSX.Element | null;
  channelFilters?: JSX.Element | null;
  channelFilter?: string;
}

const SoundpiecePlaylist: FC<SoundpiecePlaylistProps> = memo(
  ({ tagIdsToInclude, filters, channelFilters, channelFilter }) => {
    const variables = useVariables();

    const currentAudio = useSelector(selectPlayerCurrentAudio);
    const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);

    const { isLoading, error, data, loadMore, isAllDataLoaded } =
      useLoadContentInfinitely({
        itemsType: 'soundpiece',
        pageSize: PAGE_SIZE.toString(),
        tagIdsToInclude,
        publishDateOrderType: 'desc',
        includeChannel: channelFilter,
      });

    const isEmpty = !data?.[0]?.length;
    const soundpieces = (data?.flat() || []) as ContentFeedEntrySoundpiece[];

    const { onPlay, onPause, changeEvent, setIsAutoPlay } = useAudioPlayer({
      autoPlaylist: soundpieces,
    });

    const isReachedEnd = () =>
      isAllDataLoaded ? null : (
        <div className="text-center mb-4">
          <NormalButton text="Load more" onClick={loadMore} />
        </div>
      );

    return (
      <SoundpiecePlaylistContainer
        filters={filters}
        channelFilters={channelFilters}
        setAudioPlayerIsAutoPlay={setIsAutoPlay}
      >
        {error && (
          <div>
            {getVariablePlainText(
              variables['soundpieces-overview-could-not-load'],
            ) || 'Could not load content.'}
          </div>
        )}

        {isEmpty && !isLoading && (
          <div>
            {getVariablePlainText(
              variables['soundpieces-overview-no-content'],
            ) || 'No content available.'}
          </div>
        )}

        <ul>
          {soundpieces.map((entry, index) => (
            <Soundpiece
              key={entry.id}
              {...entry}
              isActive={
                !!currentAudio &&
                'id' in currentAudio &&
                currentAudio.id === entry.id &&
                isAudioPlaying
              }
              onPlay={() => onPlay(index, entry)}
              onPause={onPause}
              audioTimeUpdateEvent={changeEvent}
            />
          ))}
        </ul>

        {isLoading ? (
          <div className="flex justify-center mb-4">
            <LoadingIcon id={'loading-soundpices-icon'} className="h-12 w-12" />
          </div>
        ) : (
          isReachedEnd()
        )}
      </SoundpiecePlaylistContainer>
    );
  },
);

export default SoundpiecePlaylist;
