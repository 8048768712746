import { MusicAwardDisplayType } from '@/types/cms';
import { MusicAwardSectionEntry } from '@/types/views/sections';

import MusicAwardActWinner from './components/act-winner';
import MusicAwardVoting from './components/voting';

const MusicAwardSection = ({
  displayData,
  votingStart,
  votingEnd,
  displayTitle,
  acts,
  id,
}: MusicAwardSectionEntry) => (
  <div key={id} className="content-box">
    {displayTitle && <h1 className="text-h2 text-pink mb-8">{displayTitle}</h1>}

    <div className="flex flex-wrap gap-8 justify-center">
      {displayData === MusicAwardDisplayType.nominees &&
        votingStart &&
        votingEnd && (
          <MusicAwardVoting
            votingStart={votingStart}
            votingEnd={votingEnd}
            acts={acts}
          />
        )}

      {displayData === MusicAwardDisplayType.winners &&
        acts.map((act) => (
          <MusicAwardActWinner
            key={act.id}
            title={act.title}
            image={act.image}
            year={act.year}
            url={act.url}
          />
        ))}
    </div>
  </div>
);

export default MusicAwardSection;
