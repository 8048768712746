import { FC, ReactNode } from 'react';

interface LotteryBoxMainContentProps {
  smallHeadline?: string;
  title?: string;
  description?: string;
}

const renderSmallHeadline = (children: ReactNode) => (
  <span className="text-center text-gray-2 tesn:text-esn-secondary-light mb-4 last:mb-0 tels:text-els-gold-light">
    {children}
  </span>
);

const renderTitle = (children: ReactNode) => (
  <span className="text-h5 text-center font-semibold text-black tesn:text-esn-secondary-dark mb-4 last:mb-0">
    {children}
  </span>
);

const renderDescription = (children: ReactNode) => (
  <span className="body-2 text-center text-black tesn:text-esn-secondary-dark">
    {children}
  </span>
);

const LotteryBoxMainContent: FC<LotteryBoxMainContentProps> = ({
  smallHeadline,
  title,
  description,
}) => (
  <div className="flex flex-col items-center">
    {smallHeadline && renderSmallHeadline(smallHeadline)}

    {title && renderTitle(title)}

    {description && renderDescription(description)}
  </div>
);

export default LotteryBoxMainContent;
