import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import Advertisement, { updateAds } from '@/components/advertisement';
import LoadingIcon from '@/components/icons/loading-icon';
import Meme from '@/components/meme';
import SectionWithBoxes from '@/components/sections/sections/boxes';
import VideoSection from '@/components/sections/video';
import { DEFAULT_AD_PREROLL_RATE } from '@/lib/constants';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useIntersect from '@/lib/hooks/use-intersect';
import useLoadAdditionalEntries from '@/lib/hooks/use-load-additional-entries';
import useQueryParam from '@/lib/hooks/use-query-param';
import useRefs from '@/lib/hooks/use-refs';
import {
  mapToMemeSection,
  mapToVideoSection,
} from '@/middleware/mappers/sections/watch-detail';
import { selectPrerollRateWeb } from '@/store/slices/ads';
import { selectPlayerIsPlaying } from '@/store/slices/player';
import {
  ContentFeedEntryMeme,
  ContentFeedEntryVideo,
  WatchDetailMemesEntry,
  WatchDetailVideosEntry,
} from '@/types/views/generic';
import {
  WatchDetailSectionEntry,
  WatchOverviewItemsType,
} from '@/types/views/sections';

interface WatchDetailProps extends WatchDetailSectionEntry {
  entries: WatchDetailMemesEntry[] | WatchDetailVideosEntry[];
}

const observerCallback =
  (
    setCurrentActiveVideoId: Dispatch<SetStateAction<string>>,
    observedEntries: MutableRefObject<
      Record<string, IntersectionObserverEntry>
    >,
  ) =>
  (entries: IntersectionObserverEntry[]) => {
    entries.forEach((e) => {
      const videoId = e.target.getAttribute('data-id');

      if (videoId) {
        // eslint-disable-next-line no-param-reassign
        observedEntries.current[videoId] = e;
      }
    });

    const intersectingEntries = Object.values(observedEntries.current).filter(
      (e) => e.intersectionRatio,
    );

    const activeEntry = intersectingEntries.reduce((chosenEntry, entry) => {
      if (entry.intersectionRatio > chosenEntry.intersectionRatio) {
        return entry;
      }

      if (
        entry.intersectionRatio === chosenEntry.intersectionRatio &&
        entry.boundingClientRect.top > chosenEntry.boundingClientRect.top
      ) {
        return entry;
      }

      return chosenEntry;
    }, intersectingEntries[0]);

    const videoId = activeEntry?.target.getAttribute('data-id');

    if (videoId) {
      setCurrentActiveVideoId(videoId);
    }
  };

const mapToEntries = (
  showMemeEntries: boolean,
  entries: ContentFeedEntryMeme[] | ContentFeedEntryVideo[],
) =>
  showMemeEntries
    ? mapToMemeSection(entries as ContentFeedEntryMeme[])
    : mapToVideoSection(entries as ContentFeedEntryVideo[]);

const getWatchDetailEntryId = (
  contentItemsType: WatchOverviewItemsType,
  entry: WatchDetailMemesEntry | WatchDetailVideosEntry,
) =>
  contentItemsType === 'meme'
    ? (entry as WatchDetailMemesEntry).meme.id
    : (entry as WatchDetailVideosEntry).video.taggingToolId;

const WatchDetail: FC<WatchDetailProps> = ({
  contentItemsType,
  id: entryId,
  entries,
}) => {
  const [entryRefs, setRefs] = useRefs<HTMLDivElement>();
  const [currentActiveVideoId, setCurrentActiveVideoId] =
    useState<string>(entryId);
  const [isUnMutedSection, setIsUnMutedSection] = useState(false);
  const [idsToExclude, setIdsToExclude] = useState(() =>
    entries.map((entry) => getWatchDetailEntryId(contentItemsType, entry)),
  );

  const isRadioPlaying = useSelector(selectPlayerIsPlaying);
  const { currentBreakpoint, isMobile } = useBreakpoint();
  const showMemeEntries = contentItemsType === 'meme';
  const filters = useQueryParam('filters');

  const observedEntries = useRef<Record<string, IntersectionObserverEntry>>({});
  const intersectionObserver = useIntersect(
    observerCallback(setCurrentActiveVideoId, observedEntries),
    {
      threshold: Array(10)
        .fill(null)
        .map((_, i) => (i + 1) * 0.1),
    },
  );

  // defines amount of preloaded videos
  const additionalEntriesAmount = '10';
  const { isLoading, data, loadMore, isAllDataLoaded } =
    useLoadAdditionalEntries(
      contentItemsType,
      additionalEntriesAmount,
      entryId,
      idsToExclude,
      filters,
    );

  const toggleIsVideoMuted = (isMuted = true) => setIsUnMutedSection(isMuted);

  const fetchedEntries = useMemo(
    () =>
      data?.length
        ? mapToEntries(
            showMemeEntries,
            data as ContentFeedEntryMeme[] | ContentFeedEntryVideo[],
          )
        : [],
    [data, showMemeEntries],
  );

  useEffect(() => {
    if (!showMemeEntries && intersectionObserver) {
      entryRefs.forEach((e) => intersectionObserver.observe(e));

      return () => intersectionObserver.disconnect();
    }

    return () => undefined;
  }, [intersectionObserver, entryRefs, showMemeEntries]);

  const prerollRateWeb =
    useSelector(selectPrerollRateWeb) ?? DEFAULT_AD_PREROLL_RATE;

  const mapWatchDetailEntries =
    (componentsLength = 0) =>
    (entry: WatchDetailMemesEntry | WatchDetailVideosEntry, index: number) => {
      const id = getWatchDetailEntryId(contentItemsType, entry);
      const currentIndex = componentsLength + index + 1;
      const isVideoInView = id === currentActiveVideoId;
      const memeEntry = entry as WatchDetailMemesEntry;
      const videoEntry = entry as WatchDetailVideosEntry;

      if ('video' in entry) {
        if (typeof videoEntry.video.removePreroll === 'undefined') {
          // defines if first ad in list should remove preroll
          videoEntry.video.removePreroll = false;
        }
      }

      return (
        <div key={id} className="py-8 md:py-12">
          {showMemeEntries ? (
            <Meme {...memeEntry.meme} />
          ) : (
            <VideoSection
              videoContainerRef={setRefs}
              video={videoEntry.video}
              containerClassName="px-0 py-8 md:px-8 md:py-12"
              autoPlay
              isVideoInView={isVideoInView}
              hasAd={
                !videoEntry.video.isSponsored && !videoEntry.video.removePreroll
              }
              isUnMutedSection={isUnMutedSection}
              toggleIsVideoMuted={toggleIsVideoMuted}
              isGlobalRadioPlaying={isRadioPlaying}
              internalAutoPlayCheck={false}
              prerollAdIndex={currentIndex}
            />
          )}
          {!!entry?.teaser?.length && (
            <div className="mt-8">
              <SectionWithBoxes elements={entry.teaser} />
            </div>
          )}
          {currentIndex === 1 ? (
            <>
              <div className="pt-24 hidden justify-center md:flex">
                <Advertisement slot="WB_1" />
              </div>

              <div className="pt-16 flex justify-center md:hidden">
                <Advertisement slot="MMR_1" />
              </div>
            </>
          ) : (
            <>
              <div className="pt-24 hidden justify-center md:flex">
                <Advertisement
                  slot="WB_2-MR"
                  slotId={`ad-sba-slot-wb2-mr-${currentIndex - 1}`}
                />
              </div>

              <div className="pt-16 flex justify-center md:hidden">
                <Advertisement
                  slot="MHPA_2"
                  slotId={`ad-mr-slot-mhpa2-${currentIndex - 1}`}
                />
              </div>
            </>
          )}
        </div>
      );
    };

  useEffect(() => {
    if (fetchedEntries.length) {
      const additionalEntriesAmountNum = parseInt(additionalEntriesAmount, 10);
      updateAds(
        'Watch',
        isMobile,
        currentBreakpoint,
        ['WB_2-MR', 'MHPA_2'],
        additionalEntriesAmountNum,
      );
    }
  }, [fetchedEntries.length, isMobile, currentBreakpoint]);

  useEffect(() => {
    if (fetchedEntries.length) {
      const additionalEntriesAmountNum = parseInt(additionalEntriesAmount, 10);

      if (fetchedEntries.length % additionalEntriesAmountNum === 0) {
        const entriesToCheck = fetchedEntries.slice(
          additionalEntriesAmountNum * -1,
        );

        const countNotSponsoredVideos =
          entriesToCheck.length -
          entriesToCheck
            .map(
              (dataEntry) =>
                'video' in dataEntry && dataEntry.video.isSponsored,
            )
            .filter(Boolean).length;

        let videosWithPrerollAd = Math.ceil(
          (countNotSponsoredVideos * prerollRateWeb) / 100,
        );

        let NthVideo: number;

        if (prerollRateWeb >= 50) {
          if (prerollRateWeb >= 70) {
            NthVideo = 1;
          } else {
            NthVideo = Math.ceil(countNotSponsoredVideos / videosWithPrerollAd);
          }
        } else {
          NthVideo = Math.floor(countNotSponsoredVideos / videosWithPrerollAd);
        }

        const keepPrerollArr: string[] = [];
        entriesToCheck
          .reverse()
          .map(
            (
              dataEntry: WatchDetailMemesEntry | WatchDetailVideosEntry,
              index: number,
              array: WatchDetailMemesEntry[] | WatchDetailVideosEntry[],
            ) => {
              if ('video' in dataEntry) {
                if (!dataEntry.video.isSponsored) {
                  if (
                    (index % NthVideo === NthVideo - 1 ||
                      NthVideo === Infinity) &&
                    videosWithPrerollAd > 0
                  ) {
                    keepPrerollArr.push(dataEntry.video.taggingToolId);
                    videosWithPrerollAd--;
                  }
                }
              }

              return dataEntry;
            },
          );

        fetchedEntries.map(
          (
            dataEntry: WatchDetailMemesEntry | WatchDetailVideosEntry,
            index: number,
            array: WatchDetailMemesEntry[] | WatchDetailVideosEntry[],
          ) => {
            if ('video' in dataEntry) {
              // eslint-disable-next-line no-param-reassign
              dataEntry.video.removePreroll = true;

              if (keepPrerollArr.includes(dataEntry.video.taggingToolId)) {
                // eslint-disable-next-line no-param-reassign
                dataEntry.video.removePreroll = false;
              }
            }

            return dataEntry;
          },
        );
      }
    }
  }, [fetchedEntries, prerollRateWeb]);

  useEffect(() => {
    if (data?.length) {
      const initialIdsToExclude = entries.map((entry) =>
        getWatchDetailEntryId(contentItemsType, entry),
      );
      const mappedIdsToExclude = data.map((dataEntry) => dataEntry.id);
      setIdsToExclude([...initialIdsToExclude, ...mappedIdsToExclude]);
    }
  }, [contentItemsType, entries, data]);

  return (
    <div className="content-box">
      {entries.map(mapWatchDetailEntries())}

      {fetchedEntries.map(mapWatchDetailEntries(entries.length))}

      {!isAllDataLoaded && isLoading && (
        <div className="flex justify-center mb-4">
          <LoadingIcon className="h-12 w-12" />
        </div>
      )}

      {!isAllDataLoaded && (
        <InView
          as="div"
          rootMargin="0px 0px 50% 0px"
          onChange={(isInView) => {
            if (isInView && !isLoading) {
              loadMore();
            }
          }}
        >
          <div />
        </InView>
      )}
    </div>
  );
};

export default WatchDetail;
