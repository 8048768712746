import { FC } from 'react';

interface AttentionIconProps {
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

const AttentionIcon: FC<AttentionIconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill={className}
    />
    <path
      d="M10.7445 6.55859H12.9621V14.3034H10.7445V6.55859ZM13.1301 15.7818V17.697H10.5933V15.7818H13.1301Z"
      fill="currentColor"
    />
  </svg>
);

export default AttentionIcon;
